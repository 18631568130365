<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <v-row class="pt-5 pb-8">
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0 pb-0">
                  <v-list-item-title class="text-h6 font-weight-bold"
                    >Monto Suscrito Fuente Cooperante</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(totalDatosFinancierosFC).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ"
                    })
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
              <v-list-item two-line style="min-height: 50px;">
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 font-weight-bold"
                    >Monto Acumulado por enmiendas</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(datosProyecto.totalEnmiendasFuenteCoo).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
              <v-list-item two-line style="min-height: 50px;">
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 font-weight-bold"
                    >Monto Total Fuente Cooperante</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(datosProyecto.totalSuscritoFuenteCoo).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 pb-0 pt-0">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0 d-none">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Disponible Fuente Cooperante</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(totalSubDivisionCooperante).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ"
                    })
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6" sm="12" xs="12">
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0 pb-0">
                  <v-list-item-title class="text-h6 font-weight-bold"
                    >Monto Suscrito Contrapartida / Otros Aportes</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(totalContraPartidaOtros).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ"
                    })
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
              <v-list-item two-line style="min-height: 50px;">
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 text-h6 font-weight-bold"
                    >Monto Acumulado por Enmiendas</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(datosProyecto.totalEnmiendasContrapartida).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12" class="pb-0 pt-0">
              <v-list-item two-line style="min-height: 50px;">
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 font-weight-bold"
                    >Monto Total Contrapartida/Otros</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(totalSubDivisionContraPartida).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="12" class="pt-0 pb-0">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="6" sm="12" xs="12" class="pt-0 pb-0 d-none">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Disponible Contrapartida</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6">{{
                    parseFloat(totalSubDivisionContraPartida).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- inicio:: seccion detalle de los desembolsos recibidos -->
      <v-card elevation-1>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Detalle de desembolsos recibidos de la Fuente Cooperante
                <span class="text-h6 red--text text--darken-1"></span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="red--text mt-3 font-weight-bold text-subtitle-1">
                *Indique si cuenta con desembolsos recibidos durante el periodo
              </span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0 pl-0">
                <v-select
                  v-model="datosCuentaDesembolsos.cuentaConDesembolsosRecibidos"
                  :items="cuentaConDesembolsosItems"
                  :loading="false"
                  dense
                  class="required"
                  filled
                  :rules="[selectRequired('cuenta con desembolsos')]"
                  label="¿Cuenta con desembolsos recibidos durante el periodo?"
                  item-text="text"
                  item-value="id"
                  menu-props="auto"
                  @change="actualizarCuentaConDesembolsos()"
                ></v-select>
              </v-col>
              <v-divider class="mt-0 pt-3 pb-2"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formDatosFinancieros"
            v-on:submit.prevent="registrarDesembolsoFuenteCooperante"
            v-model="validFormDesembolsoFuenteCooperante"
            v-if="activarRegistroDesembolsos"
          >
            <v-row class="mt-4">
              <!--  <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                             <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="fechaDesembolsoFuenteCooperante"
                                label="Fecha de desembolso"
                                :rules="[
                                    required('Fecha de desembolso'),
                                    minLength('Fecha de desembolso', 8),
                                    dateFormat('Fecha de desembolso')
                                ]"
                                maxlength="10"
                                v-mask="'##/##/####'"
                                >
                            </v-text-field>
                        </v-col> -->

              <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                <v-menu
                  ref="menuFechaSuscripcion"
                  v-model="menuFechaDesembolso"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="computedFechaDesembolsoFC"
                      label="Fecha de desembolso"
                      hint="DD/MM/AAAA"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :rules="[required('fecha de desembolso')]"
                      v-mask="'##/##/####'"
                      maxlength="10"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFechaDesembolsoFC"
                    no-title
                    @input="menuFechaDesembolso = false"
                    locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="montoMonedaOriginalDesembolso"
                  label="Monto moneda original"
                  :rules="[
                    required('monto moneda original'),
                    decimals('monto moneda original')
                  ]"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="tipoCambioDesembolso"
                  label="Tipo de cambio"
                  :rules="[
                    required('tipo de cambio'),
                    decimals4('tipo de cambio')
                  ]"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  :disabled="true"
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosDesembolso.totalQuetzales"
                  label="Monto total quetzales"
                  maxlength="30"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormDesembolsoFuenteCooperante"
                  :loading="btnRegistroDesembolsoFuenteCooperanteLoading"
                >
                  Agregar detalle
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4" v-if="activarRegistroDesembolsos">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Desembolsos Recibidos
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row v-if="activarRegistroDesembolsos">
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersDesembolsosRecibidosFC"
                :items="desembolsosRecibidosFc"
                :loading="tableLoadingDesembolsosRecibidosFc"
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-right">{{ item.fecha | formatDate }}</td>
                    <td class="text-right">{{ item.montoMonedaOriginal }}</td>
                    <td class="text-right">{{ item.tipoCambio }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.totalQuetzales).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarDesembolsoFCDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarDesembolsoRecibido(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de desembolsos recibidos
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <!-- inicio: total de la secion -->

            <!--<v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Total desembolsos recibidos del bimestre
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-h6 text-right">{{
                    parseFloat(totalDesembolsosRecibidos).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 text-right"
                    >Total desembolsos acumulados antes del registro
                    actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right">{{
                    parseFloat(totalAcumuladoDesembolsos).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col> -->
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Detalle de desembolsos registrados en fechas anteriores
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersDesembolsosAnterioresRecibidosFC"
                :items="desembolsosAnterioresRecibidosFc"
                :loading="tableLoadingDesembolsosAnterioresRecibidosFc"
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-right">{{ item.fecha | formatDate }}</td>
                    <td class="text-right">{{ item.montoMonedaOriginal }}</td>
                    <td class="text-right">{{ item.tipoCambio }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.totalQuetzales).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de desembolsos recibidos
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-subtitle-1 font-weight-black black--text"
                    >Total ejecutado en el bimestre actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                    parseFloat(totalDesembolsosRecibidos).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-subtitle-1 font-weight-black black--text"
                    >Total ejecución acumulada antes del registro actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                    parseFloat(totalAcumuladoDesembolsos).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-subtitle-1 font-weight-black black--text"
                    >Pendiente de ejecutar</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                    parseFloat(datosProyecto.totalSuscritoFuenteCoo - (totalDesembolsosRecibidos + totalAcumuladoDesembolsos)).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion detalle de los desembolsos recibidos-->

      <!-- inicio:: seccion detalle de ejecuciones cooperante -->
      <v-card elevation-1 class="mt-6">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Detalle de ejecución de la Fuente Cooperante
                <span class="text-h6 red--text text--darken-1"> *</span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formDatosFinancieros"
            v-on:submit.prevent="registrarEjecucionCooperante"
            v-model="validFormEjecucionCooperante"
          >
            <v-row class="mt-4">
              <!-- <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                             <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="fechaEjecucionFC"
                                label="Fecha de ejecución"
                                :rules="[
                                    fechaEjecucionFC.length > 0 ? minLength('Fecha de ejecución', 8) : true,
                                    fechaEjecucionFC.length > 0 ?dateFormat('Fecha de ejecución') : true
                                ]"
                                maxlength="10"
                                v-mask="'##/##/####'"
                                >
                            </v-text-field>
                        </v-col>
 -->
              <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                <v-menu
                  ref="menuFechaSuscripcion"
                  v-model="menuFechaEjecucionFC"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="computedFechaEjecucionFC"
                      label="Fecha de ejecución"
                      hint="DD/MM/AAAA"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :rules="[required('fecha de ejecución')]"
                      v-mask="'##/##/####'"
                      maxlength="10"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFechaEjecucionFC"
                    no-title
                    @input="menuFechaEjecucionFC = false"
                    locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-select
                  v-model="ejecucionPorActividadCns"
                  :items="subDivisiones"
                  :loading="false"
                  dense
                  filled
                  label="Subdivisión"
                  item-text="nombreActividad"
                  item-value="id"
                  :no-data-text="
                    subDivisiones != null
                      ? 'Selecciona una subdivisión'
                      : 'No se han encontrado subdivisiones'
                  "
                  return-object
                  menu-props="auto"
                >
                  <template v-slot:selection="{ item, attrs }" class="pt-2">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      class="pl-0"
                      style="min-height: 40px !important; max-width: 100% !important;"
                    >
                      <v-list-item-content style="padding: 2px 0 !important; margin-top: 10px !important;">
                        <v-list-item-title>
                          {{
                                      item.nombreActividad +
                                        " | " +
                                        item.descripcion
                                    }}
                                    {{
                                      parseFloat(
                                        item.montoPendiente
                                      ).toLocaleString("es-GT", {
                                        style: "currency",
                                        currency: "GTQ"
                                      })
                                    }}  
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!--  {{ item.nombreActividad + ' | ' + item.descripcion  + ' | ' + parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' }) }} -->
                  </template>

                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <v-col cols="8">
                              <v-col cols="12" class="pt-1 pb-1">
                                <span
                                  >{{ item.nombreActividad }} |
                                  {{ item.descripcion }}
                                </span>
                              </v-col>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-chip
                              text-color="white"
                              color="blue-grey darken-1"
                              small
                            >
                              {{
                                parseFloat(
                                  item.montoPendiente
                                ).toLocaleString("es-GT", {
                                  style: "currency",
                                  currency: "GTQ"
                                })
                              }}
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="montoEjecutadoCooperante"
                  label="Monto (GTQ)"
                  :rules="[required('monto'), decimals('monto')]"
                  maxlength="30"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormEjecucionCooperante"
                  :loading="btnRegistroEjecucionFuenteCooperanteLoading"
                >
                  Agregar detalle
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Ejecución fuente cooperante registrada
                <!-- <span class="text-h6 red--text text--darken-1"> *</span> -->
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesCooperante"
                :items="ejecucionesCooperante"
                :loading="tableLoadingEjecucionesFc"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-right">
                      {{ item.fechaEjecucion | formatDate }}
                    </td>
                    <td>{{ item.descripcion }}, {{ item.nombreActividad }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoSuscrito).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{ item.porcentajeAvance.toFixed(2) }}
                    </td>
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEjecucionFCDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEjecucionCooperante(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de ejecuciones de la fuente cooperante
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <!-- inicio: total de la secion -->

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-subtitle-1 font-weight-black black--text"
                    >Total ejecución Fuente Cooperante</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                    parseFloat(totalEjecucionFC).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ"
                    })
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Ejecución fuente cooperante registrada antes del bimestre actual
                <!-- <span class="text-h6 red--text text--darken-1"> *</span> -->
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesCooperanteAnteriores"
                :items="ejecucionesCooperanteAnteriores"
                :loading="tableLoadingEjecucionesFcAnteriores"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.tipoSubdivision }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoSuscrito).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{ item.porcentajeAvance.toFixed(2) }}
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de ejecuciones de la fuente cooperante
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-subtitle-1 font-weight-black black--text text-rights"
                    >Total ejecucuión acumulada antes del registro
                    actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                    parseFloat(totalEjecucionFCAnterior).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion detalle de ejecuciones fuente cooperante-->

      <!-- inicio:: seccion detalle de ejecuciones contrapartida -->
      <v-card elevation-1 class="mt-6" v-if="contraPartidaRegistrada">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Detalle de ejecución de la Fuente Nacional o Contrapartida
                <span class="text-h6 red--text text--darken-1"> *</span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formDatosFinancieros"
            v-on:submit.prevent="registrarEjecucionContrapartida"
            v-model="validFormEjecucionContrapartida"
          >
            <v-row class="mt-4">
              <!-- <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                             <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class=""
                                color="blue-grey lighten-2"
                                v-model="fechaEjecucionCP"
                                label="Fecha de ejecución"
                                :rules="[
                                    fechaEjecucionCP.length > 0 ? minLength('Fecha de ejecución', 8) : true,
                                    fechaEjecucionCP.length > 0 ? dateFormat('Fecha de ejecución') : true
                                ]"
                                maxlength="10"
                                v-mask="'##/##/####'"
                                >
                            </v-text-field>
                        </v-col> -->

              <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                <v-menu
                  ref="menuFechaSuscripcion"
                  v-model="menuFechaEjecucionCP"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      color="blue-grey lighten-2"
                      v-model="computedFechaEjecucionCP"
                      label="Fecha de ejecución"
                      hint="DD/MM/AAAA"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :rules="[required('fecha de ejecución')]"
                      v-mask="'##/##/####'"
                      maxlength="10"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFechaEjecucionCP"
                    no-title
                    @input="menuFechaEjecucionCP = false"
                    locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="8" sm="8" xs="12" class="pt-0 pb-0">
                <v-select
                  v-model="contrapartidasActividades"
                  :items="subDivisionesCP"
                  :loading="false"
                  dense
                  filled
                  label="Subdivisión"
                  item-text="nombreActividad"
                  item-value="id"
                  return-object
                  :no-data-text="
                    subDivisionesCP != null
                      ? 'Selecciona una subdivisión'
                      : 'No se han encontrado subdivisiones'
                  "
                  menu-props="auto"
                >
                  <template v-slot:selection="{ item }" class="pt-2">
                    {{
                      item.nombreActividad +
                        " | " +
                        item.descripcion +
                        " | " +
                        parseFloat(item.montoPendiente).toLocaleString("es-GT", {
                          style: "currency",
                          currency: "GTQ"
                        })
                    }}
                  </template>

                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <v-col cols="8">
                              <v-col cols="12" class="pt-1 pb-1">
                                <span>{{ item.nombreActividad }}</span>
                              </v-col>
                              <v-col cols="12" class="pt-1 pb-1">
                                <span>{{ item.descripcion }}</span>
                              </v-col>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-chip
                              text-color="white"
                              color="blue-grey darken-1"
                              small
                            >
                              {{
                                parseFloat(item.montoPendiente).toLocaleString("es-GT", {
                                  style: "currency",
                                  currency: "GTQ"
                                })
                              }}
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="montoEjecutadoContrapartida"
                  label="Monto (GTQ)"
                  :rules="[required('monto'), decimals('monto')]"
                  maxlength="30"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormEjecucionContrapartida"
                  :loading="btnRegistrEjecucionContrapartidaLoading"
                >
                  Agregar Ejecución
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Ejecución contrapartida registrada
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesContrapartida"
                :items="ejecucionesContrapartida"
                :loading="tableLoadingEjecucionesCp"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-right">
                      {{ item.fechaEjecucion | formatDate }}
                    </td>
                    <td>{{ item.descripcion }}, {{ item.nombreActividad }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoSuscrito).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{ item.porcentajeAvance.toFixed(2) }}
                    </td>
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEjecucionCPDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEjecucionContrapartida(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de ejecuciones contrapartida
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <!-- inicio: total de la secion -->

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Total de ejecución de contrapartida</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right">{{
                    parseFloat(totalEjecucionCP).toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ"
                    })
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="8" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 text-right"
                    >Total ejecución acumulada antes del registro
                    actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right">{{
                    parseFloat(totalAcumuladoEjecucionCP).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion detalle de ejecuciones contrapartida-->

      <!-- inicio:: seccion detalle de ejecuciones fisicas coopeante -->
      <v-card elevation-1 class="mt-6">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Detalle de ejecución física de la Fuente Cooperante
                <span class="text-h6 red--text text--darken-1"> *</span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formDatosFinancieros"
            v-on:submit.prevent="registrarEjecucionFisicaCooperante"
            v-model="validFormEjecucionFisicaCooperante"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-select
                  v-model="subdivisionFisicaCooperanteSeleccionada"
                  :items="subDivisiones"
                  :loading="false"
                  dense
                  filled
                  label="Subdivisión"
                  :item-text="
                    item => item.nombreActividad + ' | ' + item.descripcion
                  "
                  item-value="id"
                  :no-data-text="
                    subDivisiones != null
                      ? 'Selecciona una subdivisión'
                      : 'No se han encontrado subdivisiones'
                  "
                  return-object
                  menu-props="auto"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="avanceFisicoCooperante"
                  label="Porcentaje de avance"
                  suffix="%"
                  :rules="[
                    required('Avance'),
                    minLength('Avance', 1),
                    maxLength('Avance', 6),
                    decimals('Avance'),
                    maxNumber('Avance', 100)
                  ]"
                  maxlength="6"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormEjecucionFisicaCooperante"
                  :loading="btnRegistroEjecucionFisicaCooperanteLoading"
                >
                  Registrar avance
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row
            v-if="
              subdivisionFisicaCooperanteSeleccionada &&
                subdivisionFisicaCooperanteSeleccionada.montoAcumuladoEnmiendas >
                  0
            "
          >
            <v-col cols="12" md="12" sm="12">
              <v-alert outlined type="warning" color="orange darken-1" dense>
                Se detectaron modificaciones al monto asignados para la
                subdivisión sleccionada, realizar los ajustes si es necesario
              </v-alert>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Ejecución Física de la fuente cooperante registrada
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesFisicasCooperante"
                :items="ejecucionesFisicasCooperante"
                :loading="tableLoadingEjecucionesFc"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-right">{{ item.avance }}%</td>
                    <td>{{ item.descripcion }}, {{ item.nombreActividad }}</td>
                    <td class="text-right">{{ item.acumuladoSubdivision }}%</td>
                    <!--<td class="text-right">{{ parseFloat(item.montoEjecutado).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) }}</td>-->
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEjecucionFisicaFCDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEjecucionFisicaCooperante(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de desembolsos recibidos
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <!-- inicio: total de la secion -->

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-subtitle-1 font-weight-black"
                    >Promedio del bimestre</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1"
                    >{{ promedioPorcentajeEjecucionFC.toFixed(2) }}%</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="8" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-subtitle-1 font-weight-black text-right"
                    >Acumulado físico total</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-right"
                    >{{ totalAcumuladoFisicoFC.toFixed(2) }}%</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion detalle de ejecuciones fisicas coopeante-->

      <!-- inicio:: seccion detalle de ejecuciones fisicas contrapartida -->
      <v-card elevation-1 class="mt-6" v-if="contraPartidaRegistrada">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Detalle de ejecución física de la Contrapartida
                <span class="text-h6 red--text text--darken-1"> *</span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formEjecucionFisicaContrapartida"
            v-on:submit.prevent="registrarEjecucionFisicaContrapartida"
            v-model="validFormEjecucionFisicaContrapartida"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-select
                  v-model="
                    datosEjecucionFisicaContrapartida.contraPartidasActividadesId
                  "
                  :items="subDivisionesCP"
                  :loading="false"
                  dense
                  filled
                  label="Subdivisión"
                  :item-text="
                    item => item.nombreActividad + ' | ' + item.descripcion
                  "
                  item-value="id"
                  :no-data-text="
                    subDivisionesCP != null
                      ? 'Selecciona una subdivisión'
                      : 'No se han encontrado subdivisiones'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="avanceFisicoContrapartida"
                  label="Porcentaje de avance"
                  suffix="%"
                  :rules="[
                    required('Avance'),
                    minLength('Avance', 1),
                    maxLength('Avance', 6),
                    decimals('Avance'),
                    maxNumber('Avance', 100)
                  ]"
                  maxlength="6"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormEjecucionFisicaContrapartida"
                  :loading="btnRegistroEjecucionFisicaContrapartidaLoading"
                >
                  Registrar avance
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Ejecución Física de la contrapartida registrada
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesFisicasContrapartida"
                :items="ejecucionesFisicasContrapartida"
                :loading="tableLoadingEjecucionesCp"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-right">{{ item.avance }}%</td>
                    <td>{{ item.descripcion }}, {{ item.nombreActividad }}</td>
                    <td class="text-right">{{ item.acumuladoSubdivision }}%</td>
                    <!-- <td class="text-right">{{ parseFloat(item.montoEjecutado).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) }}</td>-->
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEjecucionFisicaFCDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEjecucionFisicaContrapartida(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de desembolsos recibidos
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <!-- inicio: total de la secion -->

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Promedio del bimestre</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right"
                    >{{ promedioPorcentajeEjecucionCP.toFixed(2) }}%</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="8" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 text-right"
                    >Acumulado físico total</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right"
                    >{{ totalAcumuladoFisicoCP.toFixed(2) }}%</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion detalle de ejecuciones fisicas contrapartida-->

      <!-- inicio:: seccion Ejecución Presupuestaria del Año -->
      <v-card elevation-1 class="mt-6" v-if="datosProyecto.tiposCooperacionId == 1">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Ejecución Presupuestaria del Año
                <!-- <span class="text-h6 red--text text--darken-1"> *</span> -->
              </v-subheader>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title
                        class="text-subtitle-1 font-weight-black black--text"
                        >Monto Asignado</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                        parseFloat(datosProgramacion.montoAsignado)
                        .toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ"
                          })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                  <v-list-item two-line>
                    <v-list-item-content class="pb-0 pt-0">
                      <v-list-item-title
                        class="text-subtitle-1 font-weight-black black--text"
                        >Monto Vigente Actual</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                        parseFloat(totalEjecucionPresupuestariaAnualVigente)
                        .toLocaleString("es-GT", {
                            style: "currency",
                            currency: "GTQ"
                          })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form class="mt-4"
            ref="formEjecucionPresupuestariaAnual"
            v-on:submit.prevent="registrarEjecucionPresupuestariaAnual"
            v-model="validFormEjecucionPresupuestariaAnual"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                <v-select
                  v-model="datosEjecucionPresupuestaria.mesId"
                  :items="meses"
                  :loading="false"
                  dense
                  filled
                  label="Mes"
                  item-text="nombreMes"
                  item-value="id"
                  :no-data-text="
                    meses != null
                      ? 'Selecciona un mes'
                      : 'No se han encontrado meses registrados'
                  "
                  menu-props="auto"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="montoModificadoEjecucionPresupuestaria"
                  label="Monto Modificado"
                  :rules="[
                    montoModificadoEjecucionPresupuestaria.length > 0
                      ? negativeDecimals10('monto modificado')
                      : true
                  ]"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="4" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="montoEjecutadoEjecucionPresupuestaria"
                  label="Monto Ejecutado"
                  :rules="[
                    required('monto ejecutado'),
                    decimals10('monto ejecutado')
                  ]"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class=""
                  color="blue-grey lighten-2"
                  v-model="datosEjecucionPresupuestaria.justificacion"
                  label="Justificación"
                  :rules="[maxLength('Justificación', 100)]"
                  maxlength="100"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormEjecucionPresupuestariaAnual"
                  :loading="btnRegistroEjecucionPresupuestariaAnualLoading"
                >
                  Registrar Ejecución
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Ejecución Presupuestaria Anual Registrada
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesPresupuestariasAnuales"
                :items="ejecucionesPresupuestariasAnuales"
                :loading="tableLoadingEjecucionesPresupuestarias"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.nombreMes }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoModificado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">{{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td>{{ item.justificacion }}</td>
                    <!-- <td class="text-right">{{ parseFloat(item.montoEjecutado).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) }}</td>-->
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEjecucionPresupuestariaDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEjecucionPresupuestariaAnaul(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de desembolsos recibidos
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <!-- inicio: total de la secion -->

            <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Promedio del bimestre</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right"
                    >{{ totalEjecucionPresupuestariaAnual.toFixed(2) }}%</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-col> -->
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title
                    class="text-subtitle-1 font-weight-black black--text"
                    >Total ejecución presupuestaria del bimestre
                    actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-rights">
                    {{
                      parseFloat(totalEjecucionPresupuestariaAnual)
                      .toLocaleString("es-GT", 
                        { style: "currency", currency: "GTQ" }
                      )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Registros anteriores
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesPresupuestariasAnuales"
                :items="ejecucionesPresupuestariasAnualesAnteriores"
                :loading="tableLoadingEjecucionesPresupuestariasAnteriores"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.nombreMes }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoModificado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">{{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td>{{ item.justificacion }}</td>
                    <!-- <td class="text-right">{{ parseFloat(item.montoEjecutado).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) }}</td>-->
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEjecucionPresupuestariaDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEjecucionPresupuestariaAnaul(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existen registros anteriores
                  </p>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title
                    class="text-subtitle-1 font-weight-black black--text"
                    >Total ejecución presupuestaria anterior</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-subtitle-1 text-rights">{{
                    parseFloat(totalEjecucionPresupuestariaAnualAnterior)
                    .toLocaleString("es-GT", {
                      style: "currency",
                      currency: "GTQ"
                    })
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion Ejecución Presupuestaria del Año -->

      <!-- inicio:: seccion ejecucion territorial -->
      <v-card elevation-1 class="mt-6" v-if="montosDepartamento.length > 0">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Ejecución territorial
                <span class="text-h6 red--text text--darken-1"> *</span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formEjecucionTerritorial"
            v-on:submit.prevent="registrarEjecucionTerritorial"
            v-model="validFormEjecucionTerritorial"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-select
                  v-model="ejecucionTerritorialSeleccionada"
                  :items="montosDepartamento"
                  :loading="ddMontosDepartamentoLoading"
                  dense
                  filled
                  label="Municipio, Departamento"
                  :item-text="
                    item =>
                      item.municipiosId
                        ? item.municipio + ', ' + item.departamento
                        : item.departamento
                  "
                  item-value="id"
                  return-object
                  :no-data-text="
                    coberturas != null
                      ? 'Selecciona un departamento y municipio'
                      : 'No se han encontrado departamentos y/o municipios registrados en cobertura'
                  "
                  menu-props="auto"
                >
                  <template v-slot:selection="{ item }" class="pt-2">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      class="pl-0"
                      style="min-height: 40px !important;"
                    >
                      <v-list-item-content style="padding: 2px 0 !important;">
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <v-col cols="8">
                              <v-col cols="12" class="pt-5 pb-1 pl-0">
                                <span>{{
                                  item.municipio
                                    ? item.municipio + ", " + item.departamento
                                    : item.departamento
                                }}</span>
                              </v-col>
                              <!--  <v-col cols="12" class="pt-1 pb-1">
                                                            <span>{{ item.descripcion }}</span>
                                                        </v-col> -->
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-chip
                              text-color="white"
                              color="blue-grey darken-1"
                              small
                            >
                              {{
                                parseFloat(item.monto).toLocaleString("es-GT", {
                                  style: "currency",
                                  currency: "GTQ"
                                })
                              }}
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!--  {{ item.municipio ? item.municipio +', ' + item.departamento   + ' | ' + parseFloat(item.monto).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' }) : item.departamento + ' | ' + parseFloat(item.monto).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' }) }} -->
                  </template>

                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <v-col cols="8">
                              <v-col cols="12" class="pt-1 pb-1">
                                <span>{{
                                  item.municipio
                                    ? item.municipio + ", " + item.departamento
                                    : item.departamento
                                }}</span>
                              </v-col>
                              <!--  <v-col cols="12" class="pt-1 pb-1">
                                                            <span>{{ item.descripcion }}</span>
                                                        </v-col> -->
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-chip
                              text-color="white"
                              color="blue-grey darken-1"
                              small
                            >
                              {{
                                parseFloat(item.monto).toLocaleString("es-GT", {
                                  style: "currency",
                                  currency: "GTQ"
                                })
                              }}
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosEjecucionTerritorial.montoEjecutado"
                  label="Monto (GTQ)"
                  :rules="[
                    required('monto ejecutado'),
                    decimals('monto ejecutado')
                  ]"
                  maxlength="30"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormEjecucionTerritorial"
                  :loading="btnRegistroEjecucionTerritorialLoading"
                >
                  Registrar ejecución
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Ejecución Territorial registrada
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEjecucionesTerritoriales"
                :items="ejecucionesTerritoriales"
                :loading="tableLoadingEjecucionesTerritoriales"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
          }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{
                        item.municipio
                          ? item.municipio + ", " + item.departamento
                          : item.departamento
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEjecucionFisicaFCDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEjecucionTerritorial(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de ejecución territorial
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
            <!-- inicio: total de la secion -->

            <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6"
                    >Total ejecución territorial registrada</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right">{{
                    parseFloat(totalMontoSuscritoDepartamento).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="8" sm="12" class="pt-0 pb-0">
              <v-list-item two-line>
                <v-list-item-content class="pb-0 pt-0">
                  <v-list-item-title class="text-h6 text-right"
                    >Total ejecución territorial acumulada antes del registro
                    actual</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-h6 text-right">{{
                    parseFloat(totalAcumuladoTerritorial).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "GTQ"
                      }
                    )
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion ejecucion territorial-->

      <!-- inicio:: seccion  Programación operativa del proyecto -->
      <v-card elevation-1 class="mt-6">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Programación operativa del proyecto
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
              <v-subheader class="text-h6 font-weight-black">
                Asignación financiera fuente cooperante
              </v-subheader>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersProgramacionesFC"
                :items="acumuladosEjecucionCooperante"
                :loading="tableLoadingAcumuladoEjecucionesFc"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
          }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.subdivision }}</td>
                    <td>{{ item.descripcion }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoProgramado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                  </tr>
                </template>

                <template v-slot:body.append="{ headers }">
                  <tr>
                    <td class="black--text">
                      Totales
                    </td>
                    <td></td>
                    <td class="text-right">
                      {{
                        parseFloat(totalAcumuladoProgramadoFC).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(totalAcumuladoEjecutadoFC).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                  </tr>
              </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de la programación operativa para la fuente
                    cooperante
                  </p>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <!--<v-form 
                    ref="formEjecucionTerritorial"
                    v-on:submit.prevent="registrarProgramacionFC"
                    v-model="validFormProgramacionFC">
                    
                    <v-row class="mt-4">
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            <v-select
                                v-model="subdivisionPOAFCSeleccionada"
                                :items="subdivisionesPoaFC"
                                :loading="false"
                                dense
                                filled 
                                label="Subdivisión"
                                item-text="descripcion"
                                item-value="id"
                                return-object
                                :no-data-text="
                                    coberturas != null
                                    ? 'Selecciona un departamento y municipio'
                                    : 'No se han encontrado departamentos y/o municipios registrados en cobertura'
                                "
                                menu-props="auto"
                            >

                                <template v-slot:selection="{ item }" class="pt-2">
                                    <v-list-item v-on="on" v-bind="attrs" class="pl-0" style="min-height: 40px !important;">
                                        <v-list-item-content style="padding: 2px 0 !important;">
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <v-col cols="8">
                                                        <v-col cols="12" class="pt-5 pb-1 pl-0">
                                                            <span>{{ item.nombreActividad + ' | ' + item.descripcion }}</span>
                                                        </v-col>
                                                   
                                                    </v-col>
                                                <v-spacer></v-spacer>
                                                <v-chip text-color="white" color="blue-grey darken-1" small>
                                                    {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                </v-chip>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                
                                </template>

                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <v-col cols="8">
                                                        <v-col cols="12" class="pt-1 pb-1">
                                                            <span>{{ item.nombreActividad }}</span>
                                                        </v-col>
                                                        <v-col cols="12" class="pt-1 pb-1">
                                                            <span>{{ item.descripcion }}</span>
                                                        </v-col>
                                                    </v-col>
                                                <v-spacer></v-spacer>
                                                <v-chip text-color="white" color="blue-grey darken-1" small>
                                                    {{ parseFloat(item.aporteFuenteCooperante).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                </v-chip>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosProgramacionFC.primerCuatrimestre"
                                label="Primer Cuatrimestre (GTQ)"
                                prefix="Q"
                                :rules="[
                                    required('primer cuatrimestre'),
                                    decimals('primer cuatrimestre')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosProgramacionFC.segundoCuatrimestre"
                                label="Segundo Cuatrimestre (GTQ)"
                                prefix="Q"
                                :rules="[
                                    required('segundo cuatrimestre'),
                                    decimals('segundo cuatrimestre')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosProgramacionFC.tercerCuatrimestre"
                                label="Tercer Cuatrimestre (GTQ)"
                                prefix="Q"
                                :rules="[
                                    required('tercer cuatrimestre'),
                                    decimals('tercer cuatrimestre')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>
                   
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validFormProgramacionFC"
                                :loading="btnRegistroProgramacionFCLoading"
                                
                            >
                                Registrar asignación
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>-->

          <!--<v-row class="mt-4">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-subtitle-1 black--text">
                            Asignación fuente cooperante registrada
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row>
                    
                    <--inicio:: tabla datos financieros --
                    <v-col cols="12" md="12" sm="12" class="pt-2">
                        <v-data-table outlined
                            dense
                            class="elevation-1"
                            :headers="headersProgramacionesFC"
                            :items="asignacionesFinancierasFC"
                            :loading="tableLoadingAsignacionesFinancierasFC"
                            hide-default-footer
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.municipio ? item.municipio + ", " + item.departamento : item.departamento  }}%</td>
                                    <td class="text-right">{{ parseFloat(item.montoEjecutado).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) }}</td>
                                    <td>
                                        <v-btn
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            :disabled="btnEliminarEjecucionFisicaFCDisabled"
                                            color="blue-grey lighten-5"
                                            @click="eliminarEjecucionTerritorial(item.id)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar registro
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                <v-icon left>mdi-alert</v-icon> No existe información registrada de la programación operativa fuente cooperante
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                   
                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0"  v-if="desembolsosRecibidosFc.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Total avance</v-list-item-title>
                                <v-list-item-subtitle class="text-h6 text-right">{{parseFloat(totalDesembolsosRecibidos).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" md="8" sm="12" class="pt-0 pb-0"  v-if="desembolsosRecibidosFc.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6 text-right">Total desembolsos acumulados antes del registro actual</v-list-item-title>
                                <v-list-item-subtitle class="text-h6 text-right">{{parseFloat(totalDesembolsosRecibidos).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    

                </v-row>-->

          <v-row>
            <v-col cols="12" md="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <!-- Asignación contrapartida -->
          <v-row v-if="contraPartidaRegistrada">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
              <v-subheader class="text-h6 font-weight-black" color="black">
                Asignación financiera contrapartida
              </v-subheader>
            </v-col>
          </v-row>

          <v-row v-if="contraPartidaRegistrada">
            <v-col cols="12" md="12" sm="12">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersProgramacionesCP"
                :items="acumuladosEjecucionContrapartida"
                :loading="tableLoadingAcumuladoEjecucionesCp"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.subdivision }}</td>
                    <td>{{ item.descripcion }}</td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoProgramado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(item.montoEjecutado).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                  </tr>
                </template>
                <template v-slot:body.append="{ headers }">
                  <tr>
                    <td class="black--text">
                      Totales
                    </td>
                    <td></td>
                    <td class="text-right">
                      {{
                        parseFloat(totalAcumuladoProgramadoCP).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        parseFloat(totalAcumuladoEjecutadoCP).toLocaleString(
                          "es-GT",
                          { style: "currency", currency: "GTQ" }
                        )
                      }}
                    </td>
                  </tr>
              </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existe información
                    registrada de la programación operativa para la
                    contrapartida y otros aportes
                  </p>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <!--<v-form 
                    ref="formEjecucionTerritorial"
                    v-on:submit.prevent="registrarProgramacionCP"
                    v-model="validFormProgramacionCP">
                    
                    <v-row class="mt-4">
                        <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                            <v-select
                                v-model="subdivisionPOACPSeleccionada"
                                :items="subdivisionesPoaCP"
                                :loading="false"
                                dense
                                filled 
                                label="Subdivisión"
                                item-text="descripcion"
                                item-value="id"
                                return-object
                                :no-data-text="
                                    coberturas != null
                                    ? 'Selecciona un departamento y municipio'
                                    : 'No se han encontrado departamentos y/o municipios registrados en cobertura'
                                "
                                menu-props="auto"
                            >

                                <template v-slot:selection="{ item }" class="pt-2">
                                    <v-list-item v-on="on" v-bind="attrs" class="pl-0" style="min-height: 40px !important;">
                                        <v-list-item-content style="padding: 2px 0 !important;">
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <v-col cols="8">
                                                        <v-col cols="12" class="pt-5 pb-1 pl-0">
                                                            <span>{{ item.nombreActividad + ' | ' + item.descripcion }}</span>
                                                        </v-col>
                                                    
                                                    </v-col>
                                                <v-spacer></v-spacer>
                                                <v-chip text-color="white" color="blue-grey darken-1" small>
                                                    {{ parseFloat(item.monto).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                </v-chip>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                
                                </template>

                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <v-col cols="8">
                                                        <v-col cols="12" class="pt-1 pb-1">
                                                            <span>{{ item.nombreActividad }}</span>
                                                        </v-col>
                                                        <v-col cols="12" class="pt-1 pb-1">
                                                            <span>{{ item.descripcion }}</span>
                                                        </v-col>
                                                    </v-col>
                                                <v-spacer></v-spacer>
                                                <v-chip text-color="white" color="blue-grey darken-1" small>
                                                    {{ parseFloat(item.monto).toLocaleString('es-GT', { style: 'currency', currency: 'GTQ' })  }}
                                                </v-chip>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            
                            </v-select>
                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosProgramacionCP.primerCuatrimestre"
                                label="Primer Cuatrimestre (GTQ)"
                                prefix="Q"
                                :rules="[
                                    required('primer cuatrimestre'),
                                    decimals('primer cuatrimestre')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosProgramacionCP.segundoCuatrimestre"
                                label="Segundo Cuatrimestre (GTQ)"
                                prefix="Q"
                                :rules="[
                                    required('segundo cuatrimestre'),
                                    decimals('segundo cuatrimestre')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="datosProgramacionCP.tercerCuatrimestre"
                                label="Tercer Cuatrimestre (GTQ)"
                                prefix="Q"
                                :rules="[
                                    required('tercer cuatrimestre'),
                                    decimals('tercer cuatrimestre')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>
                   
                        <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                            
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validFormProgramacionCP"
                                :loading="btnRegistroProgramacionCPLoading"
                                
                            >
                                Registrar asignación
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-form>-->

          <!--<v-row class="mt-4">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-subtitle-1 black--text">
                            Asignación financiera contrapartida registrada
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row>
                    
                    <--inicio:: tabla datos financieros --
                    <v-col cols="12" md="12" sm="12" class="pt-2">
                        <v-data-table outlined
                            dense
                            class="elevation-1"
                            :headers="headersProgramacionesCP"
                            :items="asignacionesFinancierasCP"
                            :loading="tableLoadingAsignacionesFinancierasCP"
                            hide-default-footer
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.municipio ? item.municipio + ", " + item.departamento : item.departamento  }}%</td>
                                    <td class="text-right">{{ parseFloat(item.montoEjecutado).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) }}</td>
                                    <td>
                                        <v-btn
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            :disabled="btnEliminarEjecucionFisicaFCDisabled"
                                            color="blue-grey lighten-5"
                                            @click="eliminarEjecucionTerritorial(item.id)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar registro
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                <v-icon left>mdi-alert</v-icon> No existe información registrada de la programación operativa contrapartida
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                    <--fin:: tabla datos financieros --
                    <-- inicio: total de la secion --

                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0"  v-if="desembolsosRecibidosFc.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Total avance</v-list-item-title>
                                <v-list-item-subtitle class="text-h6 text-right">{{parseFloat(totalDesembolsosRecibidos).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" md="8" sm="12" class="pt-0 pb-0"  v-if="desembolsosRecibidosFc.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6 text-right">Total desembolsos acumulados antes del registro actual</v-list-item-title>
                                <v-list-item-subtitle class="text-h6 text-right">{{parseFloat(totalDesembolsosRecibidos).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    

                </v-row>-->
        </v-card-text>
      </v-card>
      <!-- fin:: seccion  Programación operativa del proyecto-->

      <!-- inicio:: seccion  evidencias -->
      <v-card elevation-1 class="mt-6">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Evidencias
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formEvidencia"
            v-on:submit.prevent="cargarArchivoEvidencia"
            v-model="validFormEvidencia"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                <v-textarea
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosEvidencia.descripcion"
                  label="Descripción de la evidencia (Fotografías, ayudas de memoria, presentaciones)"
                  :rules="[
                    required('descripción de la evidencia'),
                    minLength('descripción de la evidencia', 5),
                    maxLength('descripción de la evidencia', 2000)
                  ]"
                  rows="3"
                  maxlength="2000"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <template>
                  <v-file-input
                    id="respaldoFile"
                    dense
                    filled
                    placeholder="Seleccionar archivo de evidencia"
                    label="Archivo de evidencia"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[fileRequired('Evidencia')]"
                    :show-size="1000"
                    ref="respaldoFile"
                    class="required"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                        text-overline
                                        grey--text
                                        text--darken-3
                                        mx-2
                                    "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormEvidencia"
                  :loading="btnRegistroEvidenciaLoading"
                >
                  Registrar evidencia
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12">
              <v-subheader class="text-subtitle-1 black--text">
                Evidencias Registradas
              </v-subheader>
              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--inicio:: tabla datos financieros -->
            <v-col cols="12" md="12" sm="12" class="pt-2">
              <v-data-table
                outlined
                dense
                class="elevation-1"
                :headers="headersEvidencias"
                :items="evidencias"
                :loading="tableLoadingEvidencias"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                  sortByText: 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.descripcion }}</td>
                    <td>
                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        color="blue-grey lighten-5"
                        @click="descargarArchivo(item.pathImagen)"
                      >
                        <v-icon left>mdi-download</v-icon> Descargar evidencia
                      </v-btn>

                      <v-btn
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        :disabled="btnEliminarEvidenciaDisabled"
                        color="blue-grey lighten-5"
                        @click="eliminarEvidencia(item.id)"
                      >
                        <v-icon left>mdi-delete</v-icon> Eliminar registro
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:no-data>
                  <p class="text-h7">
                    <v-icon left>mdi-alert</v-icon> No existen evidencias
                    registradas
                  </p>
                </template>
              </v-data-table>
            </v-col>
            <!--fin:: tabla datos financieros -->
          </v-row>
        </v-card-text>
      </v-card>
      <!-- fin:: seccion  evidencias-->
    </div>

    <!--begin:: dialog generar iaff -->
    <v-dialog
      v-model="dialogGenerarIaff"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Generar documento IAFF

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnGenerar"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogGenerarIaff = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de generar el documento del Informe de Avance
                Físico y Financiero con la información registrada?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogGenerarIaff = false"
            :disabled="btnGenerar"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="validarIaff()"
            :loading="btnGenerar"
          >
            Sí, generar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog confirmar información -->
    <v-dialog
      v-model="dialogConfirmacion"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmación de la información

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnConfirmacion"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de confirmar la información del Informe de Avance
                Físico y Financiero?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogConfirmacion = false"
            :disabled="btnConfirmacion"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="confirmarIaff()"
            :loading="btnConfirmacion"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
import Vue from "vue";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";

import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import {
  VALIDAR_IAFF,
  CONFIRMAR_IAFF,
  OBTENER_DESEMBOLSOS_RECIBIDOS_FC,
  OBTENER_DESEMBOLSOS_ANTERIORES_RECIBIDOS_FC,
  REGISTRAR_DESEMBOLSOS_RECIBIDOS_FC,
  ELIMINAR_DESEMBOLSO_RECIBIDO_FC,
  REGISTRAR_EJECUCION_FC,
  OBTENER_EJECUCIONES_FC,
  OBTENER_EJECUCIONES_FC_ANTERIORES,
  ELIMINAR_EJECUCION_FC,
  OBTENER_ACUMULADO_EJECUCIONES_FUENTE_COOPERANTE,
  REGISTRAR_EJECUCION_CP,
  OBTENER_EJECUCIONES_CP,
  ELIMINAR_EJECUCION_CP,
  OBTENER_ACUMULADO_EJECUCIONES_CONTRAPARTIDA,
  OBTENER_EJECUCIONES_FISICAS_FC,
  REGISTRAR_EJECUCION_FISICA_FC,
  ELIMINAR_EJECUCION_FISICA_FC,
  OBTENER_EJECUCIONES_FISICAS_CP,
  REGISTRAR_EJECUCION_FISICA_CP,
  ELIMINAR_EJECUCION_FISICA_CP,
  REGISTRAR_EJECUCION_TERRITORIAL,
  OBTENER_EJECUCIONES_TERRITORIALES,
  ELIMINAR_EJECUCION_TERRITORIAL,
  REGISTRAR_EVIDENCIA_IAFF,
  OBTENER_EVIDENCIAS_IAFF,
  ELIMINAR_EVIDENCIA_IAFF,
  REGISTRAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF,
  OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF,
  OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_ANTERIORES_IAFF,
  ELIMINAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF,
  ACTAULIZAR_CUENTA_DESEMBOLSOS_RECIBIDOS_IAFF
} from "@/core/services/store/proyectoscns/iaff/iaffcns.module";

import { OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

import { OBTENER_DATOS_FINANCIEROS_CNS } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";

import { OBTENER_COBERTURAS_CNS } from "@/core/services/store/proyectoscns/coberturas/coberturacns.module";

import { OBTENER_PLANIFICACION_ANUAL_CNS } from "@/core/services/store/proyectoscns/programacionesanuales/programacionanualcns.module";

import DownloadFile from "@/core/untils/downloadfile.js";
import moment from "moment";

moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "SeccionAvanceBimestralIaff",
  components: {
    SnackAlert,
    DialogLoader
  },
  props: ["iaffId", "proyectoId", "periodoId"],
  data() {
    return {
      skeletonLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      validFormDesembolsoFuenteCooperante: false,
      validFormEjecucionCooperante: false,
      validFormEjecucionContrapartida: false,
      validFormEjecucionFisicaCooperante: false,
      validFormEjecucionFisicaContrapartida: false,
      validFormEjecucionPresupuestariaAnual: false,
      validFormEjecucionTerritorial: false,
      validFormProgramacionFC: false,
      validFormProgramacionCP: false,
      validFormEvidencia: false,

      btnRegistroEjecucionFisicaCooperanteLoading: false,
      btnRegistroEjecucionFisicaContrapartidaLoading: false,
      btnRegistroDesembolsoFuenteCooperanteLoading: false,
      btnRegistroEjecucionFuenteCooperanteLoading: false,
      btnRegistrEjecucionContrapartidaLoading: false,
      btnRegistroEjecucionPresupuestariaAnualLoading: false,
      btnRegistroEjecucionTerritorialLoading: false,
      btnRegistroProgramacionFCLoading: false,
      btnRegistroProgramacionCPLoading: false,
      btnRegistroEvidenciaLoading: false,
      btnEliminarDesembolsoFCDisabled: false,
      btnEliminarEjecucionFCDisabled: false,
      btnEliminarEjecucionCPDisabled: false,
      btnEliminarEjecucionPresupuestariaDisabled: false,
      btnEliminarEjecucionFisicaCPDisabled: false,
      btnEliminarEjecucionFisicaFCDisabled: false,
      btnEliminarEvidenciaDisabled: false,

      tableLoadingDesembolsosRecibidosFc: false,
      tableLoadingEjecucionesFc: false,
      ejecucionesCooperanteAnteriores: [],
      tableLoadingEjecucionesFcAnteriores: false,
      totalEjecucionFCAnterior: 0.0,
      tableLoadingEjecucionesCp: false,
      tableLoadingEjecucionesFisicasFC: false,
      tableLoadingEjecucionesPresupuestarias: false,
      tableLoadingEjecucionesFisicasCP: false,
      tableLoadingEjecucionesTerritoriales: false,
      tableLoadingAsignacionesFinancierasFC: false,
      tableLoadingAsignacionesFinancierasCP: false,
      tableLoadingEvidencias: false,

      ejecucionesPresupuestariasAnualesAnteriores: [],
      tableLoadingEjecucionesPresupuestariasAnteriores: false,
      totalEjecucionPresupuestariaAnualAnterior: 0.0,

      ejecucionesPresupuestariasAnuales: [],
      totalEjecucionPresupuestariaAnual: 0.0,
      totalEjecucionPresupuestariaAnualModificado: 0.0,
      totalEjecucionPresupuestariaAnualVigente: 0.0,
      montoAsignadoEjecucionPresupuestaria: 0.0,
      montoVigenteEjecucionPresupuestaria: 0.0,

      subDivisiones: [],
      subDivisionesCP: [],

      subdivisionesPoaFC: [],
      subdivisionPOAFCSeleccionada: {},
      subDivisionesPoaCP: [],
      subdivisionPOACPSeleccionada: {},
      datosCuentaDesembolsos: {
        iaffId: 0,
        cuentaConDesembolsosRecibidos: 0
      },
      cuentaConDesembolsosItems: [
        { id: 1, text: "Sí" },
        { id: 2, text: "No" }
      ],
      montoMonedaOriginalDesembolso: "",
      tipoCambioDesembolso: "",
      montoEjecutadoCooperante: "",
      montoModificadoEjecucionPresupuestaria: "",
      montoEjecutadoEjecucionPresupuestaria: "",
      montoEjecutadoContrapartida: "",
      datosDesembolso: {
        fecha: "",
        montoMonedaOriginal: 0,
        tipoCambio: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin",
        totalQuetzales: 0
      },
      ejecucionPorActividadCns: {},
      datosEjecucionCooperante: {
        fechaEjecucion: "",
        montoEjecutado: "",
        ejecucionesPorActividadCnsId: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      contrapartidasActividades: {},
      datosEjecucionContrapartida: {
        fechaEjecucion: "",
        montoEjecutado: 0,
        contrapartidasActividadesId: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      },

      avanceFisicoCooperante: "",
      subdivisionFisicaCooperanteSeleccionada: {},
      datosEjecucionFisicaCooperante: {
        id: 0,
        avance: 0,
        ejecucionesPorActividadCNSId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },

      avanceFisicoContrapartida: "",
      datosEjecucionFisicaContrapartida: {
        id: 0,
        avance: 0,
        contrapartidasActividadesId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },

      datosEjecucionPresupuestaria: {
        id: 0,
        mesId: null,
        iafcnsId: this.iafcnsId,
        estadosId: 1,
        montoModificado: 0.0,
        montoEjecutado: 0.0,
        justificacion: ""
      },
      meses: [],

      datosEjecucionTerritorial: {
        montosSuscritoPorDeptoCNSId: 0,
        montoEjecutado: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      },

      datosProgramacion: [],
      montoAsignadoInicioCicloFiscal: 0.0,

      datosProgramacionFC: {
        id: 0,
        programacionAnualActividadesId: 0,
        primerCuatrimestre: 0,
        segundoCuatrimestre: 0,
        tercerCuatrimestre: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      },

      datosProgramacionCP: {
        id: 0,
        programacionAnualContrapartidaId: 0,
        primerCuatrimestre: 0,
        segundoCuatrimestre: 0,
        tercerCuatrimestre: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      },

      datosEvidencia: {
        id: 0,
        pathImagen: "",
        descripcion: "",
        iafcnsId: 1,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      menuFechaDesembolso: false,
      menuFechaEjecucionFC: false,
      menuFechaEjecucionCP: false,

      coberturas: [],
      ddCoberturaLoading: false,

      fechaDesembolsoFuenteCooperante: "",
      fechaEjecucionFC: "",
      fechaEjecucionCP: "",
      desembolsosRecibidosFc: [],
      desembolsosAnterioresRecibidosFc: [],
      tableLoadingDesembolsosAnterioresRecibidosFc: false,
      totalDesembolsosAnterioresRecibidos: 0.0,
      ejecucionesCooperante: [],
      ejecucionesContrapartida: [],
      ejecucionesFisicasCooperante: [],
      promedioPorcentajeEjecucionFC: 0.0,
      promedioPorcentajeAcumuladoEjecucionFC: 0.0,
      promedioPorcentajeEjecucionCP: 0.0,
      promedioPorcentajeAcumuladoEjecucionCP: 0.0,
      totalAcumuladoFisicoFC: 0.0,
      totalAcumuladoFisicoCP: 0.0,
      ejecucionesFisicasContrapartida: [],
      acumuladosEjecucionCooperante: [],
      totalAcumuladoEjecucionFC: 0.0,
      totalAcumuladoProgramadoFC: 0.0,
      totalAcumuladoEjecutadoFC: 0.0,
      tableLoadingAcumuladoEjecucionesFc: false,

      totalAcumuladoEjecucionCP: 0.0,
      totalAcumuladoEjecutadoCP: 0.0,
      totalAcumuladoProgramadoCP: 0.0,
      acumuladosEjecucionContrapartida: [],
      tableLoadingAcumuladoEjecucionesCp: false,

      ejecucionesTerritoriales: [],
      asignacionesFinancierasFC: [],
      asignacionesFinancierasCP: [],
      evidencias: [],

      totalDesembolsosRecibidos: "",
      avanceTotalEjecucionCooperante: "",
      avanceTotalEjecucionContrapartida: "",
      totalEjecucionFC: "",
      totalEjecucionCP: "",

      archivoEvidencia: [],
      totalSubDivisionCooperante: 0,
      totalSubDivisionContraPartida: 0,
      datosFinancierosProyecto: [],
      totalDatosFinancieros: 0,
      fuenteCooperanteRegistrada: false,
      monedaFuenteCooperante: "",
      datoFinancieroFCRegistrado: false,
      monedaContraPartida: "",
      contraPartidaRegistrada: false,
      ejecucionFinancieraEnabled: true,
      otrosRegistrado: false,
      monedaOtros: "",
      datoFinancieroOtrosRegistrado: 0,
      totalDatosFinancierosFC: 0,
      totalDatosFinancierosCP: 0,
      totalDatosFinancierosOtros: 0,
      totalContraPartidaOtros: 0,
      totalAcumuladoDesembolsos: 0,

      totalMontoSuscritoDepartamento: 0.0,
      totalAcumuladoTerritorial: 0,
      ddMontosDepartamentoLoading: false,
      ejecucionTerritorialSeleccionada: {},
      montosDepartamento: [],
      datosIaff: {},
      btnValidarIaff: false,
      btnConfirmacion: false,
      dialogConfirmacion: false,
      dialogGenerarIaff: false,
      btnGenerar: false,
      idProyecto: 0,
      datosProyecto: {},
      activarRegistroDesembolsos: false,
      dateFechaDesembolsoFC: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFechaEjecucionFC: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateFechaEjecucionCP: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      pathDocumentoIaff: "",
      pathDocumentoResumenIaff: "",
      ...validations
    };
  },

  methods: {
    resetItems(tipo) {
      if (tipo == 1) {
        this.skeletonLoading = true;
      }

      this.datosCuentaDesembolsos.cuentaConDesembolsosRecibidos = 2;
      this.datosCuentaDesembolsos.iaffId = this.iaffId;
      this.activarRegistroDesembolsos = false;

      this.obtenerDatosIaff(this.iaffId).then(() => {
        this.obtenerDatosPoa(this.programacionAnualId);
        this.obtenerDatosEspecificosProyecto(this.idProyecto);
      });

      this.obtenerEjecucionesPresupuestariasAnuales(this.iaffId);
      this.obtenerEjecucionesPresupuestariasAnualesAnteriores(this.iaffId);

      this.obtenerEjecucionesCooperante(this.iaffId);
      this.obtenerEjecucionesCooperanteAnteriores(this.iaffId, this.proyectoId);

      this.resetItemsDesembolsosRecibidosFC();
      this.resetItemsDesembolsosAnterioresRecibidos();
      this.resetItemsEjecucionCooperante();
      this.resetItemsEjecucionPresupuestariaAnual();
      this.resetItemsEjecucionContrapartida();
      this.resetEjecucionTerritorial();
      this.datosFinancierosProyecto = [];
      this.totalDatosFinancieros = 0;
      this.fuenteCooperanteRegistrada = false;
      this.monedaFuenteCooperante = "";
      this.datoFinancieroFCRegistrado = false;
      this.monedaContraPartida = "";
      this.contraPartidaRegistrada = false;
      this.otrosRegistrado = false;
      this.monedaOtros = "";
      this.datoFinancieroOtrosRegistrado = 0;
      this.totalDatosFinancierosFC = 0;
      this.totalDatosFinancierosCP = 0;
      this.totalDatosFinancierosOtros = 0;
      this.totalContraPartidaOtros = 0;

      this.totalSubDivisionCooperante = 0;
      this.totalSubDivisionContraPartida = 0;
      this.fechaDesembolsoFuenteCooperante = "";
      this.totalDesembolsosRecibidos = "";

      this.montoMonedaOriginalDesembolso = "";
      this.tipoCambioDesembolso = "";
      this.datosDesembolso.totalQuetzales = "";

      this.avanceTotalEjecucionCooperante = "";
      this.avanceTotalEjecucionContrapartida = "";

      this.fechaEjecucionCP = "";
      this.montoEjecutadoContrapartida = "";

      this.avanceFisicoCooperante = "";
      this.subdivisionFisicaCooperanteSeleccionada = {};
      this.datosEjecucionFisicaCooperante = {
        id: 0,
        avance: 0,
        ejecucionesPorActividadCNSId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      };

      this.avanceFisicoContrapartida = "";
      this.datosEjecucionFisicaContrapartida = {
        id: 0,
        avance: 0,
        contrapartidasActividadesId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      };

      this.datosProgramacionFC = {
        id: 0,
        programacionAnualActividadesId: 0,
        primerCuatrimestre: 0,
        segundoCuatrimestre: 0,
        tercerCuatrimestre: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      };

      this.datosProgramacionCP = {
        id: 0,
        programacionAnualContrapartidaId: 0,
        primerCuatrimestre: 0,
        segundoCuatrimestre: 0,
        tercerCuatrimestre: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      };

      this.datosEvidencia = {
        id: 0,
        pathImagen: "",
        descripcion: "",
        iafcnsId: 1,
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    resetItemsDesembolsosRecibidosFC() {
      this.fechaDesembolsoFuenteCooperante = "";
      this.montoMonedaOriginalDesembolso = "";
      this.tipoCambioDesembolso = "";
      this.datosDesembolso = {
        fecha: "",
        montoMonedaOriginal: "",
        tipoCambio: "",
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    resetItemsDesembolsosAnterioresRecibidos() {
      this.totalDesembolsosAnterioresRecibidos = 0.0;
      this.desembolsosAnterioresRecibidosFc = [];
    },

    resetItemsEjecucionCooperante() {
      this.fechaEjecucionFC = "";
      this.montoEjecutadoCooperante = "";
      this.ejecucionPorActividadCns = {};
      this.datosEjecucionCooperante = {
        fechaEjecucion: "",
        montoEjecutado: "",
        ejecucionesPorActividadCnsId: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    resetItemsEjecucionPresupuestariaAnual() {
      this.montoModificadoEjecucionPresupuestaria = "";
      this.montoEjecutadoEjecucionPresupuestaria = "";
      this.datosEjecucionPresupuestaria = {
        id: 0,
        mesId: null,
        iafcnsId: this.iafcnsId,
        estadosId: 1,
        montoModificado: 0.0,
        montoEjecutado: 0.0,
        justificacion: ""
      };
    },

    resetItemsEjecucionContrapartida() {
      this.contrapartidasActividades = {};
      this.fechaEjecucionCP = "";
      this.montoEjecutadoContrapartida = "";
      this.datosEjecucionContrapartida = {
        fechaEjecucion: "",
        montoEjecutado: 0,
        contrapartidasActividadesId: 0,
        iafcnsId: this.iaffId,
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    resetEjecucionTerritorial() {
      this.ejecucionTerritorialSeleccionada = {};
      this.datosEjecucionTerritorial = {
        montosSuscritoPorDeptoCNSId: 0,
        montoEjecutado: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }

        this.archivoEvidencia = e;
      }
    },

    async obtenerDatosEspecificosProyecto(idProyecto) {
      this.skeletonLoading = true;
      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;
            //console.log(this.datosProyecto);
          }
          this.skeletonLoading = false;
          //this.skeletonAvance[idProyecto] = false;
        })
        .catch(() => {
          //console.logerror
          //this.skeletonAvance[idProyecto] = false;
          this.dialogLoaderVisible = false;
          this.datosProyecto = [];
          this.skeletonLoading = false;
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    //Obtener los datos principales del Iaff
    async obtenerDatosIaff(iaffId) {
      this.datosIaff = {};
      this.totalAcumuladoDesembolsos = 0;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `IAFFCNS/${iaffId}` })
        .then(res => {
          if (res.status === 200) {
            this.datosIaff = res.data;
            this.totalAcumuladoDesembolsos = this.datosIaff.acumuladoDesembolsos;
            this.totalAcumuladoEjecucionFC = this.datosIaff.acumuladoEjecucionFuenteCooperante;
            this.totalAcumuladoEjecucionCP = this.datosIaff.acumuladoEjecucionContrapartida;
            this.totalAcumuladoFisicoFC = this.datosIaff.acumuladoFisicoFC;
            this.totalAcumuladoFisicoCP = this.datosIaff.acumuladoFisicoCP;
            this.totalAcumuladoTerritorial = this.datosIaff.acumuladoTerritorial;
            this.pathDocumentoIaff = this.datosIaff.pathDocumento;
            this.pathDocumentoResumenIaff = this.datosIaff.pathResumenEjecutivo;
            this.programacionAnualId = this.datosIaff.programacionAnualId;
            this.idProyecto = this.datosIaff.proyectosCNSId;
            this.datosCuentaDesembolsos.cuentaConDesembolsosRecibidos = this.datosIaff.cuentaConDesembolsosRecibidos;
            this.datosCuentaDesembolsos.iaffId = this.datosIaff.id;

            if (this.datosCuentaDesembolsos.cuentaConDesembolsosRecibidos === 1)
              this.activarRegistroDesembolsos = true;
            else 
              this.activarRegistroDesembolsos = false;

            this.$emit("ocultar-dialog-loader", null);
            //this.totalSubDivisionCooperante = this.subDivisiones.reduce((a,b) => a + (b['monto'] || 0), 0);
          } else {
            this.$emit(
              "ocultar-dialog-loader",
              "Ocurrió un error al intentar obtener la información del IAFF, por favor, póngase en contacto con un administrador del sistema."
            );
          }
        })
        .catch(() => {
          this.$emit(
            "ocultar-dialog-loader",
            "Ocurrió un error al intentar obtener la información del IAFF, por favor, póngase en contacto con un administrador del sistema."
          );
          this.datosIaff = {};
        });
    },

    //Obtener datos de la planificación anual
    async obtenerDatosPoa(idProgramacion) {
      this.datosProgramacion = [];

      //   this.ddPeriodosLoading=true;
      this.seccionesBloqueadas = false;
      await this.$store
        .dispatch(OBTENER_PLANIFICACION_ANUAL_CNS, idProgramacion)
        .then(res => {
          if (res.status === 200) {
            this.datosProgramacion = res.data;
            //Monto Asignado al Inicio del Ciclo Fiscal
            this.montoAsignadoInicioCicloFiscal = this.datosProgramacion.montoAsignado.toString();
          }
          // this.ddPeriodosLoading=false;
        })
        .catch(() => {
          this.datosProgramacion = [];
          //     this.ddPeriodosLoading=false;
        });
    },

    //Obtener los datos financieros
    async obtenerDatosFinancieros(proyectoId) {
      // this.tableDatosFinancierosLoading=true;
      this.datosFinancierosProyecto = [];
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.datosFinancierosProyecto = res.data;
            this.totalDatosFinancieros = this.datosFinancierosProyecto.reduce(
              (a, b) => a + (b["montoTotalQuetzales"] || 0),
              0
            );

            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.fuenteCooperanteRegistrada = true;
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;
            searchObjectFC
              ? (this.datoFinancieroFCRegistrado = searchObjectFC.length)
              : (this.datoFinancieroFCRegistrado = 0);
            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.contraPartidaRegistrada = true;
            } else {
              this.contraPartidaRegistrada = false;
            }

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.otrosRegistrado = true;
              this.monedaOtros = searchObjectOtros.codigoMoneda;
            } else {
              this.otrosRegistrado = false;
            }

            searchObjectOtros
              ? (this.datoFinancieroOtrosRegistrado = searchObjectOtros.length)
              : (this.datoFinancieroOtrosRegistrado = 0);

            //Sumar fuente cooperante
            this.totalDatosFinancierosFC = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 1
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            //console.log(this.totalDatosFinancierosFC)
            //Sumar total contrapartida
            /*this.totalDatosFinancierosCP = this.datosFinancierosProyecto.filter(({
                                                                                    tiposDatosFinancierosId
                                                                                }) => tiposDatosFinancierosId === 2).reduce((a,b) =>  a + (b['montoTotalQuetzales'] || 0), 0);
                    */

            this.totalDatosFinancierosCP = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 2
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalDatosFinancierosOtros = this.datosFinancierosProyecto
              .filter(
                ({ tiposDatosFinancierosId }) => tiposDatosFinancierosId === 3
              )
              .reduce((a, b) => a + (b["montoTotalQuetzales"] || 0), 0);

            this.totalContraPartidaOtros =
              parseFloat(this.totalDatosFinancierosCP) +
              parseFloat(this.totalDatosFinancierosOtros);
          }
          //this.tableDatosFinancierosLoading=false;
        })
        .catch(() => {
          //console.logerror
          this.datosFinancierosProyecto = [];
          //this.tableDatosFinancierosLoading=false;
        });
    },

    //Obtener los meses
    async obtenerMeses() {
      this.meses = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `Mes`
        })
        .then(res => {
          if (res.status === 200) {
            this.meses = res.data;
          }
        })
        .catch(() => {
          this.meses = [];
        });
    },

    //Obtener subdivisiones/actividades (Componente, objetivo, indicador, resultado, meta ) fuente cooperante
    async obtenerSubDivisiones(proyectoId) {
      this.subDivisiones = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `EjecucionesPorActividadCNS/all/1/${proyectoId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisiones = res.data;
            this.totalSubDivisionCooperante = this.subDivisiones.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subDivisiones = [];
        });
    },

    //Obtener subdivisiones / actividades (Componente, objetivo, indicador, resultado, meta ) contrapartida
    async obtenerSubDivisionesContraPartida(proyectoId) {
      this.subDivisionesCP = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ContrapartidasActividades/all/1/${proyectoId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.subDivisionesCP = res.data;
            this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce(
              (a, b) => a + (b["monto"] || 0),
              0
            );
          }
        })
        .catch(() => {
          this.subDivisionesCP = [];
        });
    },

    /**
     * Obtener las subdivisiones asignadas al POA fuente cooperante por proyecto y año
     */
    async obtenerSubdivisionesPoaFC(proyectoId, periodoId) {
      this.subdivisionesPoaFC = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ProgramacionAnualActividades/all/programacion/${periodoId}/${proyectoId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.subdivisionesPoaFC = res.data;
            //this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce((a,b) => a + (b['monto'] || 0), 0);
          }
        })
        .catch(() => {
          this.subdivisionesPoaFC = [];
        });
    },

    /**
     * Obtener las subdivisiones asignadas al POA contrapartida por proyecto y año
     */
    async obtenerSubdivisionesPoaCP(proyectoId, periodoId) {
      this.subdivisionesPoaCP = [];

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ProgramacionAnualContrapartida/all/programacion/${periodoId}/${proyectoId}`
        })
        .then(res => {
          if (res.status === 200) {
            this.subdivisionesPoaCP = res.data;
            //this.totalSubDivisionContraPartida = this.subDivisionesCP.reduce((a,b) => a + (b['monto'] || 0), 0);
          }
        })
        .catch(() => {
          this.subdivisionesPoaCP = [];
        });
    },

    //Obtener los departamentos y municipios desde cobertura cns
    async obtenerCoberturaProyecto(proyectoId) {
      this.ddCoberturaLoading = true;
      this.coberturas = [];
      await this.$store
        .dispatch(OBTENER_COBERTURAS_CNS, { tipo: 1, id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.coberturas = res.data;
          }
          this.ddCoberturaLoading = false;
        })
        .catch(() => {
          this.coberturas = [];
          this.ddCoberturaLoading = false;
        });
    },

    //Obtener los desembolsos recibidos por FC
    async obtenerDesembolsosRecibidos(iaffId) {
      this.desembolsosRecibidosFc = [];
      this.tableLoadingDesembolsosRecibidosFc = true;

      await this.$store
        .dispatch(OBTENER_DESEMBOLSOS_RECIBIDOS_FC, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(() => {
          this.desembolsosRecibidosFc = this.$store.state.iaffcns.desembolsosRecibidos;
          this.totalDesembolsosRecibidos = 0.0;
          if (this.desembolsosRecibidosFc.length > 0) {
            //Sumar desembolsos recibidos
            this.totalDesembolsosRecibidos = this.desembolsosRecibidosFc.reduce(
              (a, b) => a + (b["totalQuetzales"] || 0),
              0
            );
          }
          this.tableLoadingDesembolsosRecibidosFc = false;
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.tableLoadingDesembolsosRecibidosFc = false;
          this.skeletonLoading = false;
          //console.log(err)
        });
    },

    //Obtener los desembolsos recibidos por FC
    async obtenerDesembolsosAnterioresRecibidos(iaffId) {
      this.desembolsosAnterioresRecibidosFc = [];
      this.tableLoadingDesembolsosAnterioresRecibidosFc = true;

      await this.$store
        .dispatch(OBTENER_DESEMBOLSOS_ANTERIORES_RECIBIDOS_FC, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.desembolsosAnterioresRecibidosFc = res.data;
            this.totalDesembolsosAnterioresRecibidos = 0.0;
            if (this.desembolsosAnterioresRecibidosFc.length > 0) {
              //Sumar desembolsos recibidos
              this.totalDesembolsosAnterioresRecibidos = this.desembolsosAnterioresRecibidosFc.reduce(
                (a, b) => a + (b["totalQuetzales"] || 0),
                0
              );
            }
          }
          this.tableLoadingDesembolsosAnterioresRecibidosFc = false;
          //this.skeletonLoading = false;
        })
        .catch(() => {
          this.tableLoadingDesembolsosAnterioresRecibidosFc = false;
          //this.skeletonLoading = false;
          //console.log(err)
        });
    },

    //Obtener las ejecuciones de la Fuente Cooperamte
    async obtenerEjecucionesCooperante(iaffId) {
      this.ejecucionesCooperante = [];
      this.tableLoadingEjecucionesFc = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_FC, { iaffId: iaffId, estadoId: 1 })
        .then(() => {
          this.ejecucionesCooperante = this.$store.state.iaffcns.ejecucionesCooperante;

          this.totalEjecucionFC = 0.0;
          if (this.ejecucionesCooperante.length > 0) {
            //Sumar desembolsos recibidos
            this.totalEjecucionFC = this.ejecucionesCooperante.reduce(
              (a, b) => a + (b["montoEjecutado"] || 0),
              0
            );
          }
          this.tableLoadingEjecucionesFc = false;
        })
        .catch(() => {
          this.tableLoadingEjecucionesFc = false;
          //console.log(err)
        });
    },

    //Obtener las ejecuciones de la Fuente Cooperamte
    async obtenerEjecucionesCooperanteAnteriores(iaffId, proyectoId) {
      this.ejecucionesCooperanteAnteriores = [];
      this.tableLoadingEjecucionesFcAnteriores = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_FC_ANTERIORES, { iaffId: iaffId, proyectoId: proyectoId })
        .then(res => {
          this.ejecucionesCooperanteAnteriores = res.data;

          this.totalEjecucionFCAnterior = 0.0;
          if (this.ejecucionesCooperanteAnteriores.length > 0) {
            //Sumar desembolsos recibidos
            this.totalEjecucionFCAnterior = this.ejecucionesCooperanteAnteriores.reduce(
              (a, b) => a + (b["montoEjecutado"] || 0),
              0
            );
          }
          this.tableLoadingEjecucionesFcAnteriores = false;
        })
        .catch(() => {
          this.ejecucionesCooperanteAnteriores = [];
          this.tableLoadingEjecucionesFcAnteriores = false;
          //console.log(err)
        });
    },

    //Obtener el acumulado de las ejecuciones anteriores + actual de la Fuente Cooperamte
    async obtenerAcumnuladoEjecucionesCooperante(proyectoId, iaffId) {
      this.acumuladosEjecucionCooperante = [];
      this.tableLoadingAcumuladoEjecucionesFc = true;

      await this.$store
        .dispatch(OBTENER_ACUMULADO_EJECUCIONES_FUENTE_COOPERANTE, {
          proyectoId: proyectoId,
          iaffId: iaffId
        })
        .then(res => {
          if (res.status === 200) {
            this.acumuladosEjecucionCooperante = res.data;

            this.totalAcumuladoEjecutadoFC = 0.0;
            this.totalAcumuladoProgramadoFC = 0.0;
            if (this.acumuladosEjecucionCooperante.length > 0) {
              //Sumar desembolsos recibidos
              this.totalAcumuladoEjecutadoFC = this.acumuladosEjecucionCooperante.reduce(
                (a, b) => a + (b["montoEjecutado"] || 0),
                0
              );

              this.totalAcumuladoProgramadoFC = this.acumuladosEjecucionCooperante.reduce(
                (a, b) => a + (b["montoProgramado"] || 0),
                0
              );
            }
          }

          this.tableLoadingAcumuladoEjecucionesFc = false;
        })
        .catch(() => {
          this.tableLoadingAcumuladoEjecucionesFc = false;
          //console.log(err)
        });
    },

    //Obtener las ejecuciones de la Contrapartida
    async obtenerEjecucionesContrapartida(iaffId) {
      this.ejecucionesContrapartida = [];
      this.tableLoadingEjecucionesCp = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_CP, { iaffId: iaffId, estadoId: 1 })
        .then(() => {
          this.ejecucionesContrapartida = this.$store.state.iaffcns.ejecucionesContrapartida;
          this.totalEjecucionCP = 0.0;
          if (this.ejecucionesContrapartida.length > 0) {
            //Sumar desembolsos recibidos
            this.totalEjecucionCP = this.ejecucionesContrapartida.reduce(
              (a, b) => a + (b["montoEjecutado"] || 0),
              0
            );
          }
          this.tableLoadingEjecucionesCp = false;
        })
        .catch(() => {
          this.tableLoadingEjecucionesCp = false;
          //console.log(err)
        });
    },

    //Obtener el acumulado de las ejecuciones anteriores + actual de la Fuente Contrapartida
    async obtenerAcumnuladoEjecucionesContrapartida(proyectoId, iaffId) {
      //console.log(proyectoId)
      this.acumuladosEjecucionContrapartida = [];
      this.tableLoadingAcumuladoEjecucionesCp = true;

      await this.$store
        .dispatch(OBTENER_ACUMULADO_EJECUCIONES_CONTRAPARTIDA, {
          proyectoId: proyectoId,
          iaffId: iaffId
        })
        .then(res => {
          if (res.status === 200) {
            this.acumuladosEjecucionContrapartida = res.data;


            this.totalAcumuladoEjecutadoCP = 0.0;
            this.totalAcumuladoProgramadoCP = 0.0;
            if (this.acumuladosEjecucionContrapartida.length > 0) {
              //Sumar desembolsos recibidos
              this.totalAcumuladoEjecutadoCP = this.acumuladosEjecucionContrapartida.reduce(
                (a, b) => a + (b["montoEjecutado"] || 0),
                0
              );

              this.totalAcumuladoProgramadoCP = this.acumuladosEjecucionContrapartida.reduce(
                (a, b) => a + (b["montoProgramado"] || 0),
                0
              );
            }
          }

          this.tableLoadingAcumuladoEjecucionesCp = false;
        })
        .catch(() => {
          this.tableLoadingAcumuladoEjecucionesCp = false;
          //console.log(err);
        });
    },

    //Obtener las ejecuciones físicas de la fuente cooperante
    async obtenerEjecucionesFisicasCooperante(iaffId) {
      this.ejecucionesFisicasCooperante = [];
      this.tableLoadingEjecucionesFisicasFC = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_FISICAS_FC, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(() => {
          this.ejecucionesFisicasCooperante = this.$store.state.iaffcns.ejecucionesFisicasCooperante;
          this.promedioPorcentajeEjecucionFC = 0.0;
          this.totalAcumuladoFisicoFC = 0.0;

          if (this.ejecucionesFisicasCooperante.length > 0) {
            //Sumar desembolsos recibidos
            this.promedioPorcentajeEjecucionFC =
              this.ejecucionesFisicasCooperante.reduce(
                (a, b) => a + (b["avance"] || 0),
                0
              ) / this.ejecucionesFisicasCooperante.length;
            this.totalAcumuladoFisicoFC =
              this.ejecucionesFisicasCooperante.reduce(
                (a, b) => a + (b["avanceAcumulado"] || 0),
                0
              ) / this.ejecucionesFisicasCooperante.length;
          }
          this.tableLoadingEjecucionesFisicasFC = false;
        })
        .catch(() => {
          this.tableLoadingEjecucionesFisicasFC = false;
          //console.log(err)
        });
    },

    //Obtener las ejecuciones físicas de la contrapartida (Aporte nacional)
    async obtenerEjecucionesFisicasContrapartida(iaffId) {
      this.ejecucionesFisicasContrapartida = [];
      this.tableLoadingEjecucionesFisicasCP = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_FISICAS_CP, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.ejecucionesFisicasContrapartida = this.$store.state.iaffcns.ejecucionesFisicasContrapartida;
            this.totalAcumuladoFisicoCP = 0.0;
            this.promedioPorcentajeEjecucionCP = 0.0;
            if (this.ejecucionesFisicasContrapartida.length > 0) {
              //Sumar desembolsos recibidos
              this.promedioPorcentajeEjecucionCP =
                this.ejecucionesFisicasContrapartida.reduce(
                  (a, b) => a + (b["avance"] || 0),
                  0
                ) / this.ejecucionesFisicasContrapartida.length;
              this.totalAcumuladoFisicoCP =
                this.ejecucionesFisicasContrapartida.reduce(
                  (a, b) => a + (b["avanceAcumulado"] || 0),
                  0
                ) / this.ejecucionesFisicasContrapartida.length;
            }
          }
          this.tableLoadingEjecucionesFisicasCP = false;
        })
        .catch(() => {
          this.tableLoadingEjecucionesFisicasCP = false;
          //console.log(err)
        });
    },

    //Obtener las ejecuciones físicas de la contrapartida (Aporte nacional)
    async obtenerEjecucionesPresupuestariasAnuales(iaffId) {
      this.ejecucionesPresupuestariasAnuales = [];
      this.tableLoadingEjecucionesPresupuestarias = true;
      this.totalEjecucionPresupuestariaAnual = 0;
      await this.$store
        .dispatch(OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.ejecucionesPresupuestariasAnuales = res.data;
            //Sumar los totales
            this.totalEjecucionPresupuestariaAnual = this.ejecucionesPresupuestariasAnuales.reduce(
              (a, b) => a + (b["montoEjecutado"] || 0),
              0
            );

            /*this.totalEjecucionPresupuestariaAnualModificado = this.ejecucionesPresupuestariasAnuales.reduce(
              (a, b) => a + (b["montoModificado"] || 0),
              0
            );

            this.totalEjecucionPresupuestariaAnualVigente = this.datosProgramacion.montoAsignado + this.totalEjecucionPresupuestariaAnualModificado;*/
          }
          this.tableLoadingEjecucionesPresupuestarias = false;
        })
        .catch(() => {
          this.tableLoadingEjecucionesPresupuestarias = false;
          //console.log(err)
        });
    },

    //Obtener las ejecuciones presupuestarias anteriores del iaff y proyecto
    async obtenerEjecucionesPresupuestariasAnualesAnteriores(iaffId) {
      this.ejecucionesPresupuestariasAnualesAnteriores = [];
      this.tableLoadingEjecucionesPresupuestariasAnteriores = true;
      //this.totalEjecucionPresupuestariaAnual = 0;
      await this.$store
        .dispatch(OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_ANTERIORES_IAFF, {
          iaffId: iaffId
        })
        .then(res => {
          if (res.status === 200) {
            this.ejecucionesPresupuestariasAnualesAnteriores = res.data;
            //Sumar los totales
            this.totalEjecucionPresupuestariaAnualAnterior = this.ejecucionesPresupuestariasAnualesAnteriores.reduce(
              (a, b) => a + (b["montoEjecutado"] || 0),
              0
            );

            this.totalEjecucionPresupuestariaAnualModificado = this.ejecucionesPresupuestariasAnualesAnteriores.reduce(
              (a, b) => a + (b["montoModificado"] || 0),
              0
            );

            this.totalEjecucionPresupuestariaAnualVigente =
              this.datosProgramacion.montoAsignado +
              this.totalEjecucionPresupuestariaAnualModificado;
          }
          this.tableLoadingEjecucionesPresupuestariasAnteriores = false;
        })
        .catch(() => {
          this.tableLoadingEjecucionesPresupuestariasAnteriores = false;
          //console.log(err)
        });
    },

    /*
     * Obtener las ejecuciones territoriales registradas del iaff
     */
    async obtenerEjecucionesTerritoriales(iaffId) {
      this.ejecucionesTerritoriales = [];
      this.tableLoadingEjecucionesTerritoriales = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_TERRITORIALES, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.ejecucionesTerritoriales = res.data;
          }

          /*  if(this.ejecucionesFisicasContrapartida.length > 0){
                    //Sumar desembolsos recibidos
                    this.avanceTotalEjecucionContrapartida = this.ejecucionesFisicasContrapartida.reduce((a,b) => a + ((b['avance']) || 0), 0);
                }*/
          this.tableLoadingEjecucionesTerritoriales = false;
        })
        .catch(() => {
          this.ejecucionesTerritoriales = [];
          this.tableLoadingEjecucionesTerritoriales = false;
          //console.log(err)
        });
    },

    /*
     * Obtener las evidencias del iaff
     */
    async obtenerEvidencias(iaffId) {
      this.evidencias = [];
      this.tableLoadingEvidencias = true;

      await this.$store
        .dispatch(OBTENER_EVIDENCIAS_IAFF, { iaffId: iaffId, estadoId: 1 })
        .then(res => {
          if (res.status === 200) {
            this.evidencias = res.data;
          }

          /*  if(this.ejecucionesFisicasContrapartida.length > 0){
                    //Sumar desembolsos recibidos
                    this.avanceTotalEjecucionContrapartida = this.ejecucionesFisicasContrapartida.reduce((a,b) => a + ((b['avance']) || 0), 0);
                }*/
          this.tableLoadingEvidencias = false;
        })
        .catch(() => {
          this.evidencias = [];
          this.tableLoadingEvidencias = false;
          //console.log(err)
        });
    },

    //Obtener el lsitado de proyeccion de desempbolosos registrados
    async obtenerMontosSuscritosDepto(proyectoId) {
      this.montosDepartamento = [];
      this.ddMontosDepartamentoLoading = true;
      this.totalMontoSuscritoDepartamento = "0.00";

      await this.$store
        .dispatch(OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.montosDepartamento = res.data;
            this.totalMontoSuscritoDepartamento = this.montosDepartamento.reduce(
              (a, b) => parseFloat(a) + (parseFloat(b["monto"]) || 0),
              0
            );
          }
          this.ddMontosDepartamentoLoading = false;
        })
        .catch(() => {
          this.montosDepartamento = [];
          this.ddMontosDepartamentoLoading = false;
        });
    },

    //Actualizar el campo si cuenta con desembolsos el iff
    async actualizarCuentaConDesembolsos() {
      this.dialogLoaderText = "Actualizando datos de desembolsos recibidos....";
      this.dialogLoaderVisible = true;

      await this.$store
        .dispatch(ACTAULIZAR_CUENTA_DESEMBOLSOS_RECIBIDOS_IAFF, {
          id: this.datosCuentaDesembolsos.iaffId,
          cuentaConDesembolsos: this.datosCuentaDesembolsos
            .cuentaConDesembolsosRecibidos
        })
        .then(res => {
          if (res.status === 200) {
            this.datosCuentaDesembolsos.cuentaConDesembolsosRecibidos = this.datosCuentaDesembolsos.cuentaConDesembolsosRecibidos;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDesembolsosRecibidos(this.iaffId);
          } else {
            this.datosCuentaDesembolsos.cuentaConDesembolsosRecibidos = this.datosIaff.cuentaConDesembolsosRecibidos;
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }

          if (this.datosCuentaDesembolsos.cuentaConDesembolsosRecibidos === 1)
            this.activarRegistroDesembolsos = true;
          else this.activarRegistroDesembolsos = false;

          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Ha ocurrido un error mientras se intentaba realizar el proceso, por favor, póngase en contacto con un administrador."
          );
        });
    },

    //Registrar desemboloso recibido por la fuente cooperante
    async registrarDesembolsoFuenteCooperante() {
      this.datosDesembolso.fecha = moment(
        this.fechaDesembolsoFuenteCooperante,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.datosDesembolso.fecha = moment(
        this.computedFechaDesembolsoFC,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      this.datosDesembolso.montoMonedaOriginal = parseFloat(
        this.montoMonedaOriginalDesembolso
      );
      this.datosDesembolso.tipoCambio = parseFloat(this.tipoCambioDesembolso);

      //Validar la cantidad de desembolsos recibidos no sea mayor a lo suscrito por la fuente cooperante
      if (
        parseFloat(this.datosDesembolso.totalQuetzales) +
          parseFloat(this.totalDesembolsosRecibidos) >
        parseFloat(this.totalDatosFinancierosFC)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar ${parseFloat(
            this.datosDesembolso.totalQuetzales
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} +  ${parseFloat(
            this.totalDesembolsosRecibidos
          ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" })}  
                                                                         sobrepasa al monto suscrito por la fuente cooperante ${parseFloat(
                                                                           this
                                                                             .totalDatosFinancierosFC
                                                                         ).toLocaleString(
                                                                           "es-GT",
                                                                           {
                                                                             style:
                                                                               "currency",
                                                                             currency:
                                                                               "GTQ"
                                                                           }
                                                                         )}`
        );

        return;
      }

      // console.log(this.datosDesembolso.totalQuetzales +" "+ this.totalDesembolsosRecibidos  +" "+  this.totalDatosFinancierosFC )

      this.btnRegistroDesembolsoFuenteCooperanteLoading = true;
      this.datosDesembolso.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_DESEMBOLSOS_RECIBIDOS_FC, {
          datos: this.datosDesembolso
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDesembolsosRecibidos(this.iaffId);
            //this.dialogRegistro = false;
            this.resetItemsDesembolsosRecibidosFC();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroDesembolsoFuenteCooperanteLoading = false;
        })
        .catch(() => {
          this.btnRegistroDesembolsoFuenteCooperanteLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    //Eliminar dato de desembolso recibido FC
    async eliminarDesembolsoRecibido(id) {
      this.btnEliminarDesembolsoFCDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de desembolso...";
      await this.$store
        .dispatch(ELIMINAR_DESEMBOLSO_RECIBIDO_FC, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDesembolsosRecibidos(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarDesembolsoFCDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarDesembolsoFCDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    ///Fuente cooperante
    //Registrar ejecucion fuente cooperante
    async registrarEjecucionCooperante() {
      let montoRegistro = parseFloat(
        Number(this.montoEjecutadoCooperante).toFixed(2)
      );

      let montoSuscritoSubdivision = parseFloat(
        Number(this.ejecucionPorActividadCns.montoPendiente).toFixed(2)
      );

      let montoRegistroAcumulado =
        parseFloat(Number(this.montoEjecutadoCooperante).toFixed(2)) +
        parseFloat(Number(this.totalEjecucionFC).toFixed(2));

      if (montoRegistro > montoSuscritoSubdivision) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar ${parseFloat(
            montoRegistro
          ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" })}   
                                                                         sobrepasa al monto pendiente de la subdivisión especificada ${parseFloat(
                                                                           montoSuscritoSubdivision
                                                                         ).toLocaleString(
                                                                           "es-GT",
                                                                           {
                                                                             style:
                                                                               "currency",
                                                                             currency:
                                                                               "GTQ"
                                                                           }
                                                                         )}`
        );
        return;
      }

      //Validar que la suma de las subdivsiones registradas + la subdivision a registrar no sobrepasen el monto suscrito de la misma
      let totalSubdivision = this.ejecucionesCooperante
        .filter(
          ({ ejecucionesPorActividadCNSId }) =>
            ejecucionesPorActividadCNSId === this.ejecucionPorActividadCns.id
        )
        .reduce((a, b) => a + (b["montoEjecutado"] || 0), 0);

      //console.log(totalSubdivision);

      let montoSubdivisionRegistrar =
        montoRegistro + parseFloat(Number(totalSubdivision).toFixed(2));

      //console.log(montoSubdivisionRegistrar);

      if (
        montoSubdivisionRegistrar >
        parseFloat(Number(montoSuscritoSubdivision).toFixed(2))
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar para la subdivision ${
            this.ejecucionPorActividadCns.descripcion
          } ${parseFloat(montoRegistro).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} +  ${parseFloat(totalSubdivision).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} sobrepasa al monto suscrito por la fuente cooperante ${parseFloat(
            montoSuscritoSubdivision
          ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" })}`
        );

        return;
      }

      //Validar que lo ejecutado no sea mayor a lo suscrito de la subdivision
      if (this.datosEjecucionCooperante.aporteFuenteCooperante)
        if (
          montoRegistroAcumulado >
          parseFloat(Number(this.totalDatosFinancierosFC).toFixed(2))
        ) {
          //Validar la cantidad de desembolsos recibidos no sea mayor a lo suscrito por la fuente cooperante
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `La cantidad que desea registrar ${parseFloat(
              this.datosEjecucionCooperante.montoEjecutado
            ).toLocaleString("es-GT", {
              style: "currency",
              currency: "GTQ"
            })} +  ${parseFloat(this.totalEjecucionFC).toLocaleString("es-GT", {
              style: "currency",
              currency: "GTQ"
            })}  
                                                                         sobrepasa al monto suscrito por la fuente cooperante ${parseFloat(
                                                                           this
                                                                             .totalDatosFinancierosFC
                                                                         ).toLocaleString(
                                                                           "es-GT",
                                                                           {
                                                                             style:
                                                                               "currency",
                                                                             currency:
                                                                               "GTQ"
                                                                           }
                                                                         )}`
          );

          return;
        }

      if (this.computedFechaEjecucionFC.length > 0) {
        //this.datosEjecucionCooperante.fechaEjecucion = moment(this.fechaEjecucionFC, "DD-MM-YYYY").format("YYYY-MM-DD");
        this.datosEjecucionCooperante.fechaEjecucion = moment(
          this.computedFechaEjecucionFC,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
      } else {
        delete this.datosEjecucionCooperante.fechaEjecucion;
      }

      this.datosEjecucionCooperante.ejecucionesPorActividadCNSId = this.ejecucionPorActividadCns.id;

      this.datosEjecucionCooperante.montoEjecutado = parseFloat(
        this.montoEjecutadoCooperante
      );
      this.datosEjecucionCooperante.iafcnsId = this.iaffId;
      this.btnRegistroEjecucionFuenteCooperanteLoading = true;
      //this.datosEjecucionCooperante.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_EJECUCION_FC, {
          datos: this.datosEjecucionCooperante
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesCooperante(this.iaffId);
            //this.dialogRegistro = false;
            this.resetItemsEjecucionCooperante();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionFuenteCooperanteLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionFuenteCooperanteLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución de fuente cooperante
     */

    async eliminarEjecucionCooperante(id) {
      this.btnEliminarEjecucionCFDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_EJECUCION_FC, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesCooperante(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    ///Contrapartida
    //Registrar ejecucion contrapartida
    async registrarEjecucionContrapartida() {
      let montoRegistro = parseFloat(
        Number(this.montoEjecutadoContrapartida).toFixed(2)
      );

      let montoSuscritoSubdivision = parseFloat(
        Number(this.contrapartidasActividades.montoPendiente).toFixed(2)
      );

      let montoRegistroAcumulado =
        parseFloat(Number(this.montoEjecutadoContrapartida).toFixed(2)) +
        parseFloat(Number(this.totalEjecucionCP).toFixed(2));

      /*  console.log(montoSuscritoSubdivision)
            console.log(montoRegistro)
            console.log(montoRegistroAcumulado) */

      if (montoRegistro > montoSuscritoSubdivision) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar ${parseFloat(
            montoRegistro
          ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" })}   
                                                                         sobrepasa al monto suscrito para la subdivisión especificada ${parseFloat(
                                                                           montoSuscritoSubdivision
                                                                         ).toLocaleString(
                                                                           "es-GT",
                                                                           {
                                                                             style:
                                                                               "currency",
                                                                             currency:
                                                                               "GTQ"
                                                                           }
                                                                         )}`
        );
        return;
      }

      //Validar que la suma de las subdivsiones registradas + la subdivision a registrar no sobrepasen el monto suscrito de la misma
      let totalSubdivision = this.ejecucionesContrapartida
        .filter(
          ({ contrapartidasActividadesId }) =>
            contrapartidasActividadesId === this.contrapartidasActividades.id
        )
        .reduce((a, b) => a + (b["montoEjecutado"] || 0), 0);

      //console.log(totalSubdivision + " Total registrado");

      let montoSubdivisionRegistrar =
        montoRegistro + parseFloat(Number(totalSubdivision).toFixed(2));

      /* console.log(montoSubdivisionRegistrar + " Total registrado + a registrar");
            console.log(montoSuscritoSubdivision + " Monto suscrito de la subdivision") */

      if (
        montoSubdivisionRegistrar >
        parseFloat(Number(montoSuscritoSubdivision).toFixed(2))
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar para la subdivision ${
            this.contrapartidasActividades.descripcion
          } ${parseFloat(montoRegistro).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} +  ${parseFloat(totalSubdivision).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} sobrepasa al monto suscrito por la fuente cooperante ${parseFloat(
            montoSuscritoSubdivision
          ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" })}`
        );

        return;
      }

      //Validar la cantidad de desembolsos recibidos no sea mayor a lo suscrito por la fuente cooperante
      if (montoRegistroAcumulado > parseFloat(this.totalContraPartidaOtros)) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La cantidad que desea registrar ${parseFloat(
            this.montoEjecutadoContrapartida
          ).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })} +  ${parseFloat(this.totalEjecucionCP).toLocaleString("es-GT", {
            style: "currency",
            currency: "GTQ"
          })}  
                                                                         sobrepasa al monto suscrito por la contra partida y otros aportes ${parseFloat(
                                                                           this
                                                                             .totalContraPartidaOtros
                                                                         ).toLocaleString(
                                                                           "es-GT",
                                                                           {
                                                                             style:
                                                                               "currency",
                                                                             currency:
                                                                               "GTQ"
                                                                           }
                                                                         )}`
        );

        return;
      }

      if (this.computedFechaEjecucionCP.length > 0) {
        this.datosEjecucionContrapartida.fechaEjecucion = moment(
          this.computedFechaEjecucionCP,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
      } else {
        delete this.datosEjecucionContrapartida.fechaEjecucion;
      }

      //this.datosEjecucionContrapartida.fechaEjecucion = moment(this.fechaEjecucionCP, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosEjecucionContrapartida.montoEjecutado = parseFloat(
        this.montoEjecutadoContrapartida
      );

      this.datosEjecucionContrapartida.iafcnsId = this.iaffId;
      this.datosEjecucionContrapartida.contraPartidasActividadesId = this.contrapartidasActividades.id;

      this.btnRegistrEjecucionContrapartidaLoading = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_EJECUCION_CP, {
          datos: this.datosEjecucionContrapartida
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesContrapartida(this.iaffId);
            //this.dialogRegistro = false;
            this.resetItemsEjecucionContrapartida();
            // this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistrEjecucionContrapartidaLoading = false;
        })
        .catch(() => {
          this.btnRegistrEjecucionContrapartidaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución de contrapartida
     */
    async eliminarEjecucionContrapartida(id) {
      this.btnEliminarEjecucionCPDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_EJECUCION_CP, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesContrapartida(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    ///////// EJECUCION FISICA ////////////

    ///Cooperante

    //Registrar ejecucion fisica cooperante
    async registrarEjecucionFisicaCooperante() {
      this.datosEjecucionFisicaCooperante.fechaEjecucion = moment(
        this.fechaEjecucionCP,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.datosEjecucionFisicaCooperante.avance = parseFloat(
        this.avanceFisicoCooperante
      );

      this.datosEjecucionFisicaCooperante.iafcnsId = this.iaffId;
      this.datosEjecucionFisicaCooperante.ejecucionesPorActividadCNSId = this.subdivisionFisicaCooperanteSeleccionada.id;

      this.btnRegistroEjecucionFisicaCooperanteLoading = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_EJECUCION_FISICA_FC, {
          datos: this.datosEjecucionFisicaCooperante
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.subdivisionFisicaCooperanteSeleccionada = {};
            this.avanceFisicoCooperante = "";
            this.obtenerEjecucionesFisicasCooperante(this.iaffId);
            //this.dialogRegistro = false;
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionFisicaCooperanteLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionFisicaCooperanteLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución fisica cooperante
     */
    async eliminarEjecucionFisicaCooperante(id) {
      this.btnEliminarEjecucionCPDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_EJECUCION_FISICA_FC, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesFisicasCooperante(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    ///CONTRAPARTIDA

    //Registrar ejecucion fisica contrapartida
    async registrarEjecucionFisicaContrapartida() {
      this.datosEjecucionFisicaContrapartida.fechaEjecucion = moment(
        this.fechaEjecucionCP,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.datosEjecucionFisicaContrapartida.avance = parseFloat(
        this.avanceFisicoContrapartida
      );

      this.datosEjecucionFisicaContrapartida.iafcnsId = this.iaffId;

      this.btnRegistroEjecucionFisicaContrapartidaLoading = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_EJECUCION_FISICA_CP, {
          datos: this.datosEjecucionFisicaContrapartida
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.datosEjecucionFisicaContrapartida.contraPartidasActividadesId = null;
            this.avanceFisicoContrapartida = "";
            this.obtenerEjecucionesFisicasContrapartida(this.iaffId);
            //this.dialogRegistro = false;
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionFisicaContrapartidaLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionFisicaContrapartidaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución fisica contrapartida
     */
    async eliminarEjecucionFisicaContrapartida(id) {
      this.btnEliminarEjecucionCPDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_EJECUCION_FISICA_CP, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesFisicasContrapartida(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    /**
     * Registrar la ejecucion presupuestaria anual
     */
    async registrarEjecucionPresupuestariaAnual() {
      this.btnRegistroEjecucionPresupuestariaAnualLoading = true;

      if (this.montoModificadoEjecucionPresupuestaria.length > 0) {
        this.datosEjecucionPresupuestaria.montoModificado = parseFloat(
          this.montoModificadoEjecucionPresupuestaria
        );
      } else {
        this.montoModificadoEjecucionPresupuestaria = null;
      }

      this.datosEjecucionPresupuestaria.montoEjecutado = parseFloat(
        this.montoEjecutadoEjecucionPresupuestaria
      );
      this.datosEjecucionPresupuestaria.iafcnsId = this.iaffId;
      this.$store
        .dispatch(REGISTRAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF, {
          datos: this.datosEjecucionPresupuestaria
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesPresupuestariasAnuales(this.iaffId);
            //this.dialogRegistro = false;
            this.resetItemsEjecucionPresupuestariaAnual();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionPresupuestariaAnualLoading = false;
        })
        .catch(() => {
          this.btnRegistroEjecucionPresupuestariaAnualLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });

      //console.log(this.datosEjecucionPresupuestaria);
    },

    /*
     * Eliminar Ejecución territoral iaff
     */
    async eliminarEjecucionPresupuestariaAnual(id) {
      this.btnEliminarEjecucionPresupuestariaDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText =
        "Eliminando registro de ejecución presupuestaria...";
      await this.$store
        .dispatch(ELIMINAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesPresupuestariasAnuales(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionPresupuestariaDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionPresupuestariaDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    /**
     * Registrar ejecución territorial del proyecto
     */
    async registrarEjecucionTerritorial() {
      /* 
            this.datosEjecucionTerritorial.fechaEjecucion = moment(this.fechaEjecucionCP, "DD-MM-YYYY").format("YYYY-MM-DD");
            this.datosEjecucionTerritorial.avance = parseInt(this.avanceFisicoContrapartida);
            */

      //Validar que la ejecución sea menor o igual al monto registrado en datos financieros
      if (
        parseFloat(this.datosEjecucionTerritorial.montoEjecutado) >
        parseFloat(this.ejecucionTerritorialSeleccionada.monto)
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `El monto que desea registrar ${this.datosEjecucionTerritorial.montoEjecutado} sobrepasa el monto suscrito en datos financieros para este departamento/municipio ${this.ejecucionTerritorialSeleccionada.monto}`
        );
        return;
      }

      this.datosEjecucionTerritorial.montosSuscritoPorDeptoCNSId = this.ejecucionTerritorialSeleccionada.id;
      this.datosEjecucionTerritorial.iafcnsId = this.iaffId;

      this.btnRegistroEjecucionTerritorialLoading = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_EJECUCION_TERRITORIAL, {
          datos: this.datosEjecucionTerritorial
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesTerritoriales(this.iaffId);
            //this.dialogRegistro = false;
            this.resetEjecucionTerritorial();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEjecucionTerritorialLoading = false;
        })
        .catch(error => {
          this.btnRegistroEjecucionTerritorialLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Registrar la programación operativa para la fuente cooperante
    async registrarProgramacionFC() {
      this.datosProgramacionFC.programacionAnualActividadesId = this.subdivisionPOAFCSeleccionada.id;

      let sumaCuatrimestres =
        parseFloat(this.datosProgramacionFC.primerCuatrimestre) +
        parseFloat(this.datosProgramacionFC.segundoCuatrimestre) +
        parseFloat(this.datosProgramacionFC.tercerCuatrimestre);

      //Validar que la cantidad colocada en los trimestres no sea mayor a la subdivision
      if (
        sumaCuatrimestres >
        this.subdivisionPOAFCSeleccionada.aporteFuenteCooperante
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La suma de los cuatrimestres que intenta ingresar ${parseFloat(
            sumaCuatrimestres
          ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" })} 
                                                               sobrepasa el monto registrado para la subdivisión seleccionada ${parseFloat(
                                                                 this
                                                                   .subdivisionPOAFCSeleccionada
                                                                   .aporteFuenteCooperante
                                                               ).toLocaleString(
                                                                 "es-GT",
                                                                 {
                                                                   style:
                                                                     "currency",
                                                                   currency:
                                                                     "GTQ"
                                                                 }
                                                               )}`
        );
        return;
      }
    },

    async registrarProgramacionCP() {
      this.datosProgramacionCP.programacionAnualActividadesId = this.subdivisionPOACPSeleccionada.id;

      let sumaCuatrimestres =
        parseFloat(this.datosProgramacionCP.primerCuatrimestre) +
        parseFloat(this.datosProgramacionCP.segundoCuatrimestre) +
        parseFloat(this.datosProgramacionCP.tercerCuatrimestre);

      //Validar que la cantidad colocada en los trimestres no sea mayor a la subdivision
      if (sumaCuatrimestres > this.subdivisionPOACPSeleccionada.monto) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `La suma de los cuatrimestres que intenta ingresar ${parseFloat(
            sumaCuatrimestres
          ).toLocaleString("es-GT", { style: "currency", currency: "GTQ" })} 
                                                               sobrepasa el monto registrado para la subdivisión seleccionada ${parseFloat(
                                                                 this
                                                                   .subdivisionPOACPSeleccionada
                                                                   .monto
                                                               ).toLocaleString(
                                                                 "es-GT",
                                                                 {
                                                                   style:
                                                                     "currency",
                                                                   currency:
                                                                     "GTQ"
                                                                 }
                                                               )}`
        );
        return;
      }
    },

    /*
     * Eliminar Ejecución territoral iaff
     */
    async eliminarEjecucionTerritorial(id) {
      this.btnEliminarEjecucionCPDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando registro de ejecución...";
      await this.$store
        .dispatch(ELIMINAR_EJECUCION_TERRITORIAL, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEjecucionesFisicasContrapartida(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEjecucionCPDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    /*
     * EVIDENCIAS
     */

    // Registrar archivo de evidencia
    async cargarArchivoEvidencia() {
      const file = this.archivoEvidencia;
      this.btnRegistroEvidenciaLoading = true;
      await this.$store
        .dispatch(CARGAR_ARCHIVO, {
          file: file,
          path: "\\ArchivosSigeaci\\cns\\iaff\\evidencias\\"
        })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.archivoCargado = true;
            this.registrarEvidencia(res.pathArchivo);

            return true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
            this.archivoCargado = false;
            this.btnRegistroEvidenciaLoading = true;
            return false;
          }
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error al intentar cargar el archivo de evidencia, por favor, póngase en contacto con un administrador del sistema. REF: ${error}.`
          );
          this.btnRegistroEvidenciaLoading = true;
          return false;
        });
    },

    //Registrar evidencia, posterior a cargar el archivo
    async registrarEvidencia(pathArchivo) {
      this.datosEvidencia.pathImagen = pathArchivo;

      this.datosEvidencia.iafcnsId = this.iaffId;

      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_EVIDENCIA_IAFF, { datos: this.datosEvidencia })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEvidencias(this.iaffId);
            //this.dialogRegistro = false;
            this.resetItems(2);
            document.querySelector("#respaldoFile").value = "";
            this.$refs.respaldoFile.reset();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroEvidenciaLoading = false;
        })
        .catch(() => {
          this.btnRegistroEvidenciaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    /*
     * Eliminar Ejecución territoral iaff
     */
    async eliminarEvidencia(id) {
      this.btnEliminarEvidenciaDisabled = true;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Eliminando evidencia...";
      await this.$store
        .dispatch(ELIMINAR_EVIDENCIA_IAFF, id)
        .then(res => {
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerEvidencias(this.iaffId);
            // this.resetItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEliminarEvidenciaDisabled = false;
          this.dialogLoaderVisible = false;
        })
        .catch(error => {
          this.btnEliminarEvidenciaDisabled = false;
          this.dialogLoaderVisible = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. ${error}`
          );
        });
    },

    //Mostrar el dialogo para validar/generar el iaff
    mostrarDialogValidacion() {
      this.dialogGenerarIaff = true;
    },

    //Validar el iaff y generar el pdf correspondiente
    async validarIaff() {
      this.btnValidarIaff = true;
      this.btnGenerar = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(VALIDAR_IAFF, this.datosIaff.id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDatosIaff(this.iaffId);
            this.descargarArchivo(res.data);
            this.pathDocumentoIaff = res.data;
            this.dialogGenerarIaff = false;
            this.$emit("obtener-iaff");
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnValidarIaff = false;
          this.btnGenerar = false;
        })
        .catch(() => {
          this.btnValidarIaff = false;
          this.btnGenerar = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro, por favor, póngase en contacto con un administrador del sistema.`
          );
        });
    },

    mostrarDialogConfirmacion() {
      this.dialogConfirmacion = true;
    },

    //Actualizar el estado del IAFF a confirmado
    async confirmarIaff() {
      this.btnConfirmacion = true;

      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      await this.$store
        .dispatch(CONFIRMAR_IAFF, this.datosIaff.id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.pathDocumentoResumenIaff = res.data;
            this.obtenerDatosIaff(this.iaffId);
            this.dialogConfirmacion = false;
            this.descargarArchivo(res.data);
            this.$emit("obtener-iaff");
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnConfirmacion = false;
        })
        .catch(() => {
          this.btnConfirmacion = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    formatDatePicker(date) {
      //console.log(date)
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      DownloadFile.download(path);
    }
  },

  created() {
    this.resetItems(1);
    this.obtenerDatosFinancieros(this.proyectoId);
    this.obtenerDesembolsosRecibidos(this.iaffId);
    this.obtenerDesembolsosAnterioresRecibidos(this.iaffId);
    this.obtenerMeses();
    this.obtenerSubDivisiones(this.proyectoId);
    this.obtenerSubDivisionesContraPartida(this.proyectoId);

    this.obtenerSubdivisionesPoaFC(this.proyectoId, this.periodoId);
    this.obtenerSubdivisionesPoaCP(this.proyectoId, this.periodoId);

    
    this.obtenerAcumnuladoEjecucionesCooperante(this.proyectoId, this.iaffId);
    this.obtenerAcumnuladoEjecucionesContrapartida(
      this.proyectoId,
      this.iaffId
    );
    this.obtenerEjecucionesContrapartida(this.iaffId);

    this.obtenerEjecucionesFisicasCooperante(this.iaffId);
    this.obtenerEjecucionesFisicasContrapartida(this.iaffId);
    
    this.obtenerMontosSuscritosDepto(this.proyectoId);
    this.obtenerEjecucionesTerritoriales(this.iaffId);
    this.obtenerEvidencias(this.iaffId);

    this.obtenerCoberturaProyecto(this.proyectoId);
  },

  watch: {
    montoMonedaOriginalDesembolso: function() {
      this.datosDesembolso.totalQuetzales =
        this.montoMonedaOriginalDesembolso * this.tipoCambioDesembolso;
    },

    tipoCambioDesembolso: function() {
      this.datosDesembolso.totalQuetzales =
        this.montoMonedaOriginalDesembolso * this.tipoCambioDesembolso;
    }
  },

  computed: {
    computedFechaDesembolsoFC: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaDesembolsoFC);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },

    computedFechaEjecucionFC: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaEjecucionFC);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },

    computedFechaEjecucionCP: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaEjecucionCP);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },

    headersDesembolsosRecibidosFC() {
      return [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha"
        },
        {
          text: "Monto Moneda Original",
          align: "start",
          sortable: false,
          value: "montoMonedaOriginal"
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: false,
          value: "tipoCambio"
        },
        {
          text: "Monto Total Quetzales",
          align: "end",
          sortable: false,
          value: "totalQuetzales"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        }
      ];
    },

    headersDesembolsosAnterioresRecibidosFC() {
      return [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha"
        },
        {
          text: "Monto Moneda Original",
          align: "start",
          sortable: false,
          value: "montoMonedaOriginal"
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: false,
          value: "tipoCambio"
        },
        {
          text: "Monto Total Quetzales",
          align: "end",
          sortable: false,
          value: "totalQuetzales"
        }
      ];
    },

    headersEjecucionesCooperante() {
      return [
        {
          text: "Fecha Ejecución",
          align: "start",
          sortable: false,
          value: "fechaEjecucion"
        },
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreActividad"
        },
        {
          text: "Monto Suscrito (GTQ)",
          align: "start",
          sortable: false,
          value: "aporteFuenteCooperante"
        },
        {
          text: "Monto Ejecutado (GTQ)",
          align: "start",
          sortable: false,
          value: "montoEjecutado"
        },
        {
          text: "% Avance",
          align: "start",
          sortable: false,
          value: ""
        },
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        }
      ];
    },

    headersEjecucionesCooperanteAnteriores() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreActividad"
        },
        {
          text: "Monto Suscrito (GTQ)",
          align: "start",
          sortable: false,
          value: "aporteFuenteCooperante"
        },
        {
          text: "Monto Ejecutado (GTQ)",
          align: "start",
          sortable: false,
          value: "montoEjecutado"
        },
        {
          text: "% Avance",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersEjecucionesContrapartida() {
      return [
        {
          text: "Fecha Ejecución",
          align: "start",
          sortable: false,
          value: "fechaEjecucion"
        },
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreActividad"
        },
        {
          text: "Monto Suscrito (GTQ)",
          align: "start",
          sortable: false,
          value: "aporteFuenteCooperante"
        },
        {
          text: "Monto Ejecutado (Quetzales)",
          align: "end",
          sortable: false,
          value: "montoEjecutado"
        },
        {
          text: "% Avance",
          align: "start",
          sortable: false,
          value: ""
        },
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        }
      ];
    },

    headersEjecucionesFisicasCooperante() {
      return [
        {
          text: "Avance",
          align: "start",
          sortable: false,
          value: "avance"
        },
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreActividad"
        },
        {
          text: "Acumulado Subdivisión",
          align: "start",
          sortable: false,
          value: "acumuladoSubdivision"
        },
        /*{
                    text: "Monto Ejecutado (Quetzales)",
                    align: "end",
                    sortable: false,
                    value: "montoEjecutado"
                },*/
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        }
      ];
    },

    headersEjecucionesFisicasContrapartida() {
      return [
        {
          text: "Avance",
          align: "start",
          sortable: false,
          value: "avance"
        },
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "nombreActividad"
        },
        {
          text: "Acumulado Subdivisión",
          align: "start",
          sortable: false,
          value: "acumuladoSubdivision"
        },
        /*{
                    text: "Monto Ejecutado (Quetzales)",
                    align: "end",
                    sortable: false,
                    value: "montoEjecutado"
                },*/
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        }
      ];
    },

    headersEjecucionesPresupuestariasAnuales() {
      return [
        {
          text: "Mes",
          align: "start",
          sortable: true,
          value: "nombreMes"
        },
        {
          text: "Monto Modificado",
          align: "start",
          sortable: false,
          value: "montoModificado"
        },
        {
          text: "Monto Ejecutado",
          align: "start",
          sortable: false,
          value: "montoEjecutado"
        },
        {
          text: "Justificación",
          align: "start",
          sortable: false,
          value: "justificacion"
        },
        /*{
                    text: "Monto Ejecutado (Quetzales)",
                    align: "end",
                    sortable: false,
                    value: "montoEjecutado"
                },*/
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "nombreMoneda"
        }
      ];
    },

    headersEjecucionesTerritoriales() {
      return [
        {
          text: "Municipio, departamento",
          align: "start",
          sortable: false,
          value: "municipio"
        },
        {
          text: "Monto ejecutado",
          align: "start",
          sortable: false,
          value: "montoEjecutado"
        },
        /*{
                    text: "Monto Ejecutado (Quetzales)",
                    align: "end",
                    sortable: false,
                    value: "montoEjecutado"
                },*/
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: ""
        }
      ];
    },

    headersProgramacionesFC() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "subdivision"
        },
        {
          text: "Descripción",
          align: "start",
          sortable: false,
          value: "nombreActividad"
        },
        {
          text: "Total programado",
          align: "start",
          sortable: false,
          value: "totalProgramado"
        },
        {
          text: "Total ejecutado",
          align: "start",
          sortable: false,
          value: "totalEjecutado"
        }
        /*{
                    text: "Primer Cuatrimestre",
                    align: "start",
                    sortable: false,
                    value: "primerCuatrimestre"
                },
                {
                    text: "Segundo Cuatrimestre",
                    align: "start",
                    sortable: false,
                    value: "segundoCuatrimestre"
                },
                {
                    text: "Tercer Cuatrimestre",
                    align: "start",
                    sortable: false,
                    value: "tercerCuatrimestre"
                },*/
        /*{
                    text: "Monto Ejecutado (Quetzales)",
                    align: "end",
                    sortable: false,
                    value: "montoEjecutado"
                },*/
        /*{
                    text: "Acciones",
                    align: "start",
                    sortable: true,
                    value: ""
                },*/
      ];
    },

    headersProgramacionesCP() {
      return [
        {
          text: "Subdivisión",
          align: "start",
          sortable: false,
          value: "subdivision"
        },
        {
          text: "Descripción",
          align: "start",
          sortable: false,
          value: "nombreActividad"
        },
        {
          text: "Total programado",
          align: "start",
          sortable: false,
          value: "totalProgramado"
        },
        {
          text: "Total ejecutado",
          align: "start",
          sortable: false,
          value: "totalEjecutado"
        }
        /* {
                    text: "Primer Cuatrimestre",
                    align: "start",
                    sortable: false,
                    value: "primerCuatrimestre"
                },
                {
                    text: "Segundo Cuatrimestre",
                    align: "start",
                    sortable: false,
                    value: "segundoCuatrimestre"
                },
                {
                    text: "Tercer Cuatrimestre",
                    align: "start",
                    sortable: false,
                    value: "tercerCuatrimestre"
                }, */
        /*{
                    text: "Monto Ejecutado (Quetzales)",
                    align: "end",
                    sortable: false,
                    value: "montoEjecutado"
                },*/
        /*  {
                    text: "Acciones",
                    align: "start",
                    sortable: true,
                    value: ""
                }, */
      ];
    },

    headersEvidencias() {
      return [
        {
          text: "Descripción",
          align: "start",
          sortable: false,
          value: "descripcion"
        },

        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: ""
        }
      ];
    }
  }
};
</script>

<style>
.big-select-selected-text{
  max-width: 100% !important;
}
</style>
