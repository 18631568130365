<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$ADMINISTRADOR')
                  ? `/proyectos/cns/administracion`
                  : `/proyectos/cns/externo/registro-informacion`
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <span class="card-label font-weight-bolder text-dark">
              Avance Físico y Financiero </span
            ><br />
            <v-skeleton-loader
              v-if="skeletonLoading"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-if="!skeletonLoading">
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                {{ codigoProyecto }}
              </span>
              <br />
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                {{ datosProyecto.nombreProyecto }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalActualizarEstado"
              :disabled="skeletonLoading"
            >
              Actualizar estado proyecto
            </v-btn>

            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right mr-2"
              @click="modalNuevo"
              :disabled="skeletonLoading"
            >
              Registrar IAFF
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="items"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Año, periodo"
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  small
                  :color="
                    item.procedencia === 'SIGEACI'
                      ? 'blue-grey lighten-4'
                      : 'blue lighten-4'
                  "
                  :text-color="
                    item.procedencia === 'SIGEACI'
                      ? 'blue-grey lighten-1'
                      : 'blue lighten-1'
                  "
                >
                  {{ item.procedencia }}
                </v-chip>
              </td>
              <td>{{ item.periodo }}</td>
              <td>{{ item.nombreBimestre }} <br /></td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadosId === 1 && item.procedencia == `SIGEACI`
                      ? 'light-blue lighten-4'
                      : item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 3
                      ? 'teal lighten-4'
                      : item.estadosId === 4
                      ? 'light-blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1 && item.procedencia == `SIGEACI`
                      ? 'light-blue lighten-1'
                      : item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 3
                      ? 'teal lighten-1'
                      : item.estadosId === 4
                      ? 'light-blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{
                    item.estadosId === 1 && item.procedencia == `SICOOPERA`
                      ? `En registro`
                      : item.estadosId === 1 && item.procedencia == `SIGEACI`
                      ? `Confirmado`
                      : item.estadosId === 3 && item.procedencia == `SICOOPERA`
                      ? `Documento Generado`
                      : item.estado
                  }}
                </v-chip>

                <v-btn
                  v-if="
                    (item.estadosId === 1 || item.estadosId === 3) &&
                      item.procedencia == `SICOOPERA`
                  "
                  color="deep-orange lighten-2"
                  class="ml-1"
                  @click="
                    mostrarDialogAdvertenciaEstado(
                      item.estadosId === 1
                        ? `Es necesario generar y confirmar el IAFF correspondiente al ${item.nombreBimestre} bimestre del año ${item.periodo}.`
                        : `Es necesario confirmar el IAFF correspondiente al ${item.nombreBimestre} bimestre del año ${item.periodo}.`
                    )
                  "
                  text
                  fab
                >
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn
                  v-if="
                    (item.estadosId === 1 || item.estadosId === 3) &&
                      item.procedencia == `SICOOPERA`
                  "
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogActualizar(item.id, item.periodosId)"
                >
                  <v-icon left>mdi-pencil</v-icon
                  >{{
                    item.estadosId == 1 || item.estadosId === 3
                      ? `Actualizar información`
                      : item.estadosId === 4
                      ? `Visualizar`
                      : ``
                  }}
                </v-btn>

                <v-btn
                  v-if="item.procedencia == `SIGEACI`"
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="
                    item.procedencia == 'SIGEACI'
                      ? descargarArchivo(item.pathIaff, 'sigeaci')
                      : descargarArchivo(item.pathIaff, 'sicoopera')
                  "
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Descargar IAFF
                </v-btn>

                <v-btn
                  v-if="
                    item.procedencia == `SICOOPERA` &&
                      (item.estadosId === 3 || item.estadosId === 4)
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="descargarArchivo(item.pathIaff, 'sicoopera')"
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Descargar IAFF
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 4 && item.procedencia == `SICOOPERA`"
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="
                    descargarArchivo(item.pathResumenEjecutivo, 'sicoopera')
                  "
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Descargar Resumen
                  Ejecutivo
                </v-btn>

                <v-btn
                  v-if="
                    item.estadosId === 4 &&
                      item.procedencia == `SICOOPERA` &&
                      (currentRoles.includes('SCO$ADMINISTRADOR') ||
                        currentRoles.includes('SCO$SUBADMINISTRADOR'))
                  "
                  class="
                    ma-2
                    btn-bg-light  
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  @click="
                    mostrarModalActivar(
                      item.id,
                      item.periodo,
                      item.nombreBimestre
                    )
                  "
                  target="_blank"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-file-document-edit-outline</v-icon>
                  Activar IAFF
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="900"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ dialogTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-row>
            <v-col cols="12">
              <v-alert
                v-if="periodos.length > 0"
                outlined
                type="info"
                prominent
                border="left"
              >
                Se mostrarán únicamente los periodos para los cuales se haya
                registrado previamente una programación anual.
              </v-alert>
            </v-col>
          </v-row>

          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-row>
              <v-col cols="12" md="6" sm="12" xs="12" class="pt-4 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.periodosId"
                  :items="periodos"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Año"
                  item-text="periodo"
                  item-value="id"
                  :rules="[selectRequired('periodo')]"
                  menu-props="auto"
                  @change="validarPeriodoPoa"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="12" xs="12" class="pt-4 pb-0">
                <v-select
                  dense
                  filled
                  :disabled="!existePoa"
                  v-model="datosItem.bimestreId"
                  :items="bimestres"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Bimestre"
                  item-text="nombreBimestre"
                  item-value="id"
                  :rules="[selectRequired('bimestre')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-4 pb-0">
                <v-alert
                  v-if="!existePoa && periodoValidado"
                  outlined
                  color="deep-orange"
                  prominent
                  border="left"
                >
                  No se puede registrar Informe de Anance Físico y Financiero
                  debido a que no existe una programación anual para el periodo
                  seleccionado.
                </v-alert>

                <v-alert
                  v-if="periodos.length <= 0"
                  outlined
                  type="warning"
                  color="deep-orange"
                  prominent
                  border="left"
                >
                  No se puede registrar Informe de Avance Físico y Financiero,
                  debido a que no se han encontrado programaciones anuales para
                  este proyecto.
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>
                <!--:disabled="!validDocForm" type="submit"-->
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="
                    !validForm ||
                      (!existePoa && periodoValidado) ||
                      periodos.length <= 0
                  "
                  :loading="btnRegistroLoading"
                >
                  Guardar
                </v-btn>

                <v-btn
                  color="grey lighten-5"
                  elevation="0"
                  class="mb-2 float-right grey lighten-5 mr-2"
                  @click="
                    dialogRegistro = false;
                    resetForm();
                  "
                  :disabled="btnRegistroLoading"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>

    <!--end:: modal registro-->

    <!--inicio:: secciones iaff-->
    <v-dialog
      v-model="dialogInformacionIaff"
      persistent
      max-width="1280px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <div class="row mt-0 mb-0">
          <div
            class="col-12 col-md-12 m-0 p-0"
            style="margin-top: -5px !important;"
          >
            <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
          </div>
        </div>
        <v-card-title>
          Registro IAFF | {{ codigoProyecto }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogInformacionIaff = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Avance Bimestral
              <v-icon>mdi-progress-check</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              Dificultades y Propuestas de Solución
              <v-icon>mdi-sync-alert</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Contactos
              <v-icon>mdi-account-box-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-4" @click="actualizarEstadoActualIaff(iaffId)">
              Generar/Confirmar IAFF
              <v-icon>mdi-check-all</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text class="blue-grey lighten-5">
                  <SeccionAvanceBimestralIaff
                    ref="SeccionAvanceBimestralIaff"
                    :iaffId="iaffId"
                    :proyectoId="proyectoId"
                    :periodoId="periodoId"
                    @ocultar-dialog-loader="ocultarDialogLoader"
                    @obtener-iaff="obtenerItems"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <SeccionDificultadesIaff
                    ref="SeccionDificultadesIaff"
                    :iaffId="iaffId"
                    :proyectoId="proyectoId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text>
                  <!--<SeccionContactosIaff ref="SeccionContactosIaff" :iaffId="iaffId" :proyectoId="proyectoId"/>-->
                  <SeccionContactosProyectoCns
                    ref="SeccionContactosIaff"
                    :id="proyectoId"
                    :tipoUsuario="`ext`"
                    :confirmacion="false"
                    :iaff="true"
                    :iaffId="iaffId"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text style="background: #f4f5f7">
                  <SeccionActualizacionEstado
                    ref="SeccionActualizacionEstado"
                    :iaffId="iaffId"
                    :proyectoId="proyectoId"
                    :tipoUsuario="`ext`"
                    @obtener-iaff="obtenerItems"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--fin:: secciones iaff-->
    <!--begin:: dialog confirmar información -->
    <v-dialog
      v-model="dialogActivar"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Activar IAFF

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnActivarLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogActivar = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de activar nuevamente el Informe Avance Físico y
                Financiero del {{ bimestreActivarIaff }} bimestre y año
                {{ anioActivarIaff }} ?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogActivar = false"
            :disabled="btnActivarLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="activarIaff()"
            :loading="btnActivarLoading"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--inicio:: dialog advertencia estado-->
    <v-dialog
      v-model="dialogAdvertenciaEstado"
      persistent
      max-width="500px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <!--  <div class="row mt-0 mb-0">
            <div class="col-12 col-md-12 m-0 p-0" style="margin-top: -5px !important;">
              <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
            </div>
        </div> -->
        <v-card-title>
          Advertencia del estado del IAFF
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogAdvertenciaEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <p class="text-h6">
                {{ textoAdvertenciaEstado }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--fin:: dialog advertencia estado -->

    <!-- inicio:: dialog cambio de estado -->
    <v-dialog
      v-model="dialogActualizarEstado"
      width="1200"
      transition="scroll-y-transition"
      persistent
    >
      <v-card class="mt-6" outlined>
        <v-card-title class="text-h5">
          Solicitud de cambio de estado del proyecto

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnActualizarEstadoLoading"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogActualizarEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-subtitle-1 black--text">
                Fuente Cooperante
                <span class="text-subtitle-1 red--text text--darken-1"> </span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Suscrito</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosProyecto.totalSuscritoFuenteCoo).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Ejecutado</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosProyecto.totalEjecutadoFuenteCoo).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Pendiente de ejecutar</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ parseFloat(datosProyecto.totalPendienteEjecutarFuenteCoo).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}
                    <v-icon small :color="datosProyecto.totalPendienteEjecutarFuenteCoo != 0 ? `orange darken-2` : `#26c6da`">{{datosProyecto.totalPendienteEjecutarFuenteCoo != 0 ? `mdi-alert-outline` : `mdi-check-all`}}</v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row v-if="contraPartidaRegistrada || otrosRegistrado">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-subtitle-1 black--text">
                Contrapartida y Otros Aportes
                <span class="text-subtitle-1 red--text text--darken-1"> </span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Suscrito</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosProyecto.totalSuscripcionContrapartida).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Ejecutado</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosProyecto.totalEjecutadoContrapartida).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Pendiente de ejecutar</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ parseFloat(datosProyecto.totalPendienteEjecutarContrapartida).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}
                    <v-icon small :color="datosProyecto.totalPendienteEjecutarContrapartida != 0 ? `orange darken-2` : `#26c6da`">{{datosProyecto.totalPendienteEjecutarContrapartida != 0 ? `mdi-alert-outline` : `mdi-check-all`}}</v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>


          <v-row v-if="territorioRegistrado">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-subtitle-1 black--text">
                Territorio
                <span class="text-subtitle-1 red--text text--darken-1"> </span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Suscrito</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosProyecto.totalSuscritoTerritorio).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Ejecutado</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosProyecto.totalEjecutadoTerritorio).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Pendiente de ejecutar</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ parseFloat(datosProyecto.totalPendienteEjecutarTerritorio).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}
                    <v-icon small :color="datosProyecto.totalPendienteEjecutarTerritorio != 0 ? `orange darken-2` : `#26c6da`">{{datosProyecto.totalPendienteEjecutarTerritorio != 0 ? `mdi-alert-outline` : `mdi-check-all`}}</v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col
              cols="12"
              md="12"
              sm="12"
              v-if="datosItem.totalPendienteEjecutarGeneral != 0 || datosProyecto.totalPendienteEjecutarTerritorio > 0"
            >
              <v-alert outlined color="orange darken-2" dense>
                <v-icon color="orange darken-2">mdi-alert-outline</v-icon>
                Existen montos pendientes de ejecutar
              </v-alert>
            </v-col>

            <!-- <v-col
              cols="12"
              md="12"
              sm="12"
              class="mt-2"
              v-if="datosIaff.estadosId != 4"
            >
              <v-alert outlined color="orange darken-2" dense>
                <v-icon color="orange darken-2">mdi-alert-outline</v-icon>
                Es necesario confirmar el presente IAFF para poder solicitar el
                cambio de estado del proyecto
              </v-alert>
            </v-col> -->
          </v-row>

          <v-row justify="center" class="mt-6">
            <v-col cols="12" md="6" sm="12" xs="12">
              <v-card color="#039BE5" dark>
                <v-card-title class="text-h6 white--text">
                  Estado actual del proyecto {{ datosItem.codigo }}
                </v-card-title>
                <v-card-subtitle class="text-subtitle-1">{{ estadoActualProyecto }}</v-card-subtitle>
              </v-card>
            </v-col>

            <v-col cols="12" md="12" class="pt-2" v-if="estadoProyectoId == 3 || estadoProyectoId == 5 || estadoProyectoId == 7 || estadoProyectoId == 9">
              <v-alert outlined color="orange darken-2" dense v-if="solicitudRechazada == 2">
                <v-icon color="orange darken-2">mdi-alert-outline</v-icon>
                El proyecto tiene pendiente la aprobación o rechazo de una
                solicitud de cambio de estado
              </v-alert>
              <v-alert outlined color="orange darken-2" dense v-if="solicitudRechazada == 1">
                Se ha rechazado la solicitud registrada, por favor, atender las
                observaciones realizadas y enviar nuevamente la solicitud: <p class="text-h6 black--text">- {{ observacionesRechazo }}</p><br>
                
              </v-alert>
            </v-col>
          </v-row>
          <v-row 
            v-if="
              estadoProyectoId == 2 ||
                estadoProyectoId == 8 ||
                estadoProyectoId == 4 ||
                solicitudRechazada == 1
            "
          >
            <v-col cols="12" md="12">
              <v-select
                v-model="actualizaBeneficiariosSel"
                :items="actualizarBeneficiariosItems"
                :loading="false"
                :disabled="
                  /*datosItem.totalPendienteEjecutarGeneral != 0 ||
                    datosIaff.estadosId != 4*/ false
                "
                dense
                filled
                label="¿Desea actualizar la información de los beneficiarios del proyecto?"
                item-text="text"
                item-value="id"
                menu-props="auto"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12" v-if="actualizaBeneficiariosSel == 1">
              <!--inicio:: condiciones de beneficiarios -->
              <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-1 pb-0">
                  <v-subheader class="text-h5 black--text">
                    Detalle de los beneficiarios del proyecto
                  </v-subheader>
                </v-col>
              </v-row>

              <v-row class="pb-3">
                <v-col cols="12" md="12" sm="12">
                  <v-alert outlined color="#1488c2" border="left">
                    <p>
                      Los campos marcados con un asterisco (<span
                        color="red"
                        class="red--text"
                        >*</span
                      >) son obligatorios
                    </p>
                  </v-alert>
                </v-col>
              </v-row>

              <v-card class="mt-2" outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                      <v-subheader class="text-h6 black--text">
                        Beneficiarios
                      </v-subheader>
                    </v-col>
                  </v-row>

                  <v-form
                    ref="formTipoBeneficiarios"
                    v-on:submit.prevent="actualizarTipoBeneficiarios"
                    v-model="validFormTipoBeneficiarios"
                  >
                    <v-row>
                      <v-col cols="12" md="7" sm="12" class="pt-6 pb-0">
                        <v-select
                          v-model="beneficiarioDirectoSel"
                          :items="beneficiariosDirectosItems"
                          :loading="false"
                          dense
                          filled
                          class="required"
                          label="¿El proyecto cuenta con beneficiarios directos?"
                          item-text="text"
                          item-value="id"
                          menu-props="auto"
                          :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                        ></v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        md="5"
                        sm="12"
                        class="pt-6 pb-0"
                        v-if="
                          (tipoUsuario != `int` && !seccionesBloqueadas) ||
                            tipoUsuario === `int`
                        "
                      >
                        <v-btn
                          color="light-blue-502"
                          class="white--text mb-2 float-right"
                          type="submit"
                          medium
                          :elevation="0"
                          :disabled="
                            !validFormTipoBeneficiarios ||
                              beneficiarioDirectoSel === 0
                          "
                          :loading="btnRegistroTipoBeneficiariosLoading"
                        >
                          Guardar información
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-6 pb-0"
                      v-if="habilitarRegistroBeneficiarios"
                    >
                      <v-form
                        ref="formBeneficiarios"
                        v-on:submit.prevent="registrarBeneficiario"
                        v-model="validFormBeneficiarios"
                        v-if="!seccionesBloqueadas"
                      >
                        <v-row>
                          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-select
                              v-model="generoSeleccionado"
                              :items="generos"
                              :loading="false"
                              dense
                              filled
                              class="required"
                              return-object
                              :rules="[selectRequired('Género beneficiado')]"
                              label="Género Beneficiado"
                              item-text="genero"
                              item-value="id"
                              menu-props="auto"
                              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-select
                              v-model="datosBeneficiario.areasBeneficiadasId"
                              :items="areasBeneficiadas"
                              :loading="false"
                              dense
                              filled
                              class="required"
                              :rules="[selectRequired('área beneficiada')]"
                              label="Área Beneficiada"
                              item-text="nombreArea"
                              item-value="id"
                              menu-props="auto"
                              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-select
                              v-model="datosBeneficiario.etniasId"
                              :items="etnias"
                              :loading="false"
                              dense
                              filled
                              class="required"
                              :rules="[selectRequired('etnia')]"
                              label="Etnia"
                              item-text="nombreEtnia"
                              item-value="id"
                              menu-props="auto"
                              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-select
                              v-model="datosBeneficiario.rangosEdadId"
                              :items="rangosEdad"
                              :loading="false"
                              dense
                              filled
                              class="required"
                              :rules="[selectRequired('rango de edad')]"
                              label="Rango de edad"
                              item-text="rango"
                              item-value="id"
                              menu-props="auto"
                              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-text-field
                              dense
                              filled
                              autocomplete="off"
                              class="required"
                              color="blue-grey lighten-2"
                              hint="Detalle el número de beneficiarios para este registro"
                              v-model="datosBeneficiario.numeroBeneficiarios"
                              label="Número de beneficiarios"
                              :rules="[
                                required('número de beneficiarios'),
                                onlyInteger('número de beneficiarios'),
                                minLength('número de beneficiarios', 1),
                                minNumber('número de beneficiarios', 0)
                              ]"
                              maxlength="11"
                              v-mask="'###########'"
                              :disabled="tipoUsuario == `ext` && seccionesBloqueadas"
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                            sm="12"
                            class="pt-0"
                            v-if="
                              (tipoUsuario != `int` && !seccionesBloqueadas) ||
                                tipoUsuario === `int`
                            "
                          >
                            <v-btn
                              color="light-blue-502"
                              class="white--text mb-2 float-right"
                              type="submit"
                              :elevation="0"
                              :disabled="!validFormBeneficiarios || generoSeleccionado.id == 0"
                              :loading="btnRegistroBeneficiarioLoading"
                            >
                              {{
                                accionBeneficiarios === 1
                                  ? `Guardar información`
                                  : `Actualizar información`
                              }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>

                    <!--inicio:: tabla beneficiarios -->
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-6"
                      v-if="habilitarRegistroBeneficiarios"
                    >
                      <v-data-table
                        class="elevation-0"
                        :headers="headersBeneficiarios"
                        :items="beneficiarios"
                        :loading="tableBeneficiariosLoading"
                        loading-text="Cargando..."
                        :header-props="{
                          orderByText: 'Ordenar por',
                        }"
                        :footer-props="{
                          showFirstLastPage: true,
                          firstIcon: 'mdi-page-first',
                          lastIcon: 'mdi-page-last',
                          prevIcon: 'mdi-chevron-left',
                          nextIcon: 'mdi-chevron-right',
                          'items-per-page-text': 'Registros por página',
                          pageText: '{0}-{1} de {2}',
                        }"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td>{{ item.genero }}</td>
                            <td>{{ item.nombreArea }}</td>
                            <td>{{ item.nombreEtnia }}</td>
                            <td>{{ item.rango }}</td>
                            <td class="text-right">{{ item.numeroBeneficiarios }}</td>
                            <td>
                              <v-btn
                                v-if="!seccionesBloqueadas"
                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                small
                                depressed
                                :disabled="btnEliminarBeneficiarioDisabled"
                                color="blue-grey lighten-5"
                                @click="obtenerDatosBeneficiario(item)"
                              >
                                <v-icon left>mdi-file-document-edit-outline</v-icon>
                                Actualizar
                              </v-btn>
                              <v-btn
                                v-if="!seccionesBloqueadas"
                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                small
                                depressed
                                :disabled="btnEliminarBeneficiarioDisabled"
                                color="blue-grey lighten-5"
                                @click="eliminarBeneficiarioDirecto(item.id)"
                              >
                                <v-icon left>mdi-delete</v-icon> Eliminar
                              </v-btn>
                            </td>
                          </tr>
                        </template>

                        <template v-slot:no-data>
                          <p class="text-h7">
                            <v-icon left>mdi-alert</v-icon> No existen beneficiarios
                            registrados
                          </p>
                        </template>
                      </v-data-table>

                      <v-row class="pt-5 pb-8">
                        <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                          <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6 font-weight-bold"
                                >Masculino</v-list-item-title
                              >
                              <v-list-item-subtitle class="text-h6">{{
                                totalMasculino
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                          <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6"
                                >Femenino</v-list-item-title
                              >
                              <v-list-item-subtitle class="text-h6">{{
                                totalFemenino
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                          <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6"
                                >Otros</v-list-item-title
                              >
                              <v-list-item-subtitle class="text-h6">{{
                                totalOtros
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col cols="12" md="3" sm="6" xs="6" class="pb-0">
                          <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                              <v-list-item-title class="text-h6"
                                >Total</v-list-item-title
                              >
                              <v-list-item-subtitle class="text-h6">{{
                                totalBeneficiarios
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!--fin:: tabla beneficiarios -->
                  </v-row>
                </v-card-text>
              </v-card>
              <!--fin:: condiciones de beneficiarios -->
            </v-col>
          </v-row>

          <v-form
            ref="form"
            v-on:submit.prevent="cargarArchivo"
            v-model="validForm"
            class="mt-4"
            v-if="
              estadoProyectoId == 2 ||
                estadoProyectoId == 8 ||
                estadoProyectoId == 4 ||
                solicitudRechazada == 1
            "
          >
            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  v-model="nuevoEstadoId"
                  :items="estadosProyectoActualizacion"
                  :loading="false"
                  :disabled="
                    /*datosItem.totalPendienteEjecutarGeneral != 0 ||
                      datosIaff.estadosId != 4*/ false
                  "
                  dense
                  filled
                  return-object
                  label="Seleccione el estado que solicita para el Proyecto"
                  item-text="estado"
                  item-value="id"
                  menu-props="auto"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <template>
                  <v-file-input
                    id="docFile"
                    dense
                    filled
                    :disabled="
                      /*datosItem.totalPendienteEjecutarGeneral != 0 ||
                        datosIaff.estadosId != 4*/ false
                    "
                    placeholder="Seleccionar archivo .pdf"
                    label="Seleccione el documento de respaldo para el cambio de estado"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[fileRequired('documento')]"
                    :show-size="1000"
                    ref="docFile"
                    class="required"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                            text-overline
                                            grey--text
                                            text--darken-3
                                            mx-2
                                        "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  :disabled="/*
                    datosItem.totalPendienteEjecutarGeneral != 0 ||
                      datosIaff.estadosId != 4*/ false
                  "
                  filled
                  rows="3"
                  v-model="observaciones"
                  label="Observaciones"
                  maxlength="2000"
                  :rules="[
                    minLength('observaciones', 3),
                    maxLength('observaciones', 2000)
                  ]"
                  hint=""
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0"> </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-alert
                  outlined
                  type="warning"
                  color="deep-orange lighten-1"
                  dense
                >
                  El peso de los archivos no debe de superar los 20Mb
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="
                    !validForm ||
                      /*datosItem.totalPendienteEjecutarGeneral != 0 ||
                        datosIaff.estadosId != 4*/ false
                  "
                  :loading="btnRegistroLoading"
                >
                  Enviar solicitud
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- fin: dialog cambio de estado-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DownloadFile from "@/core/untils/downloadfile.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";

import validations from "@/core/untils/validations.js";

import {
  //CONFIRMAR_PROYECTO_CNS,
  OBTENER_DATOS_SECCIONES_PROYECTO
} from "@/core/services/store/proyectoscns/proyectocns.module";


import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";

import {
  OBTENER_IAFFS_CNS,
  REGISTRAR_IAFF_CNS,
  ACTIVAR_IAFF
} from "@/core/services/store/proyectoscns/iaff/iaffcns.module";

import SeccionAvanceBimestralIaff from "@/view/pages/proyectos-cns/iaff/SeccionAvanceBimestralIaff.vue";
import SeccionDificultadesIaff from "@/view/pages/proyectos-cns/iaff/SeccionDificultadesIaff.vue";
//import SeccionContactosIaff from "@/view/pages/proyectos-cns/iaff/SeccionContactosIaff.vue";
import SeccionContactosProyectoCns from "@/view/pages/proyectos-cns/externo/SeccionContactosProyectoCns.vue";
import SeccionActualizacionEstado from "@/view/pages/proyectos-cns/iaff/SeccionActualizacionEstado.vue";

import {
  ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS,
  //ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS,
  OBTENER_BENEFICIARIOS,
  REGISTRAR_BENEFICIARIO,
  ACTUALIZAR_BENEFICIARIO,
  //ELIMINAR_BENEFICIARIO_DIRECTO_CNS,
} from "@/core/services/store/proyectoscns/beneficiarios/beneficiario.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";

import { REGISTRAR_SOLICITUD_CAMBIO_ESTADO_CNS } from "@/core/services/store/proyectoscns/solicitudescambioestado/solicitudcambioestado.module";
import {
  OBTENER_INSTITUCION,
  ACTUALIZAR_INSTITUCION
} from "@/core/services/store/instituciones/institucion.module";
import { OBTENER_PLANIFICACIONES_ANUALES_PERIODO_CNS } from "@/core/services/store/proyectoscns/programacionesanuales/programacionanualcns.module";
import { OBTENER_DATOS_FINANCIEROS_CNS } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";
export default {
  name: "AdminIaff",
  components: {
    SeccionAvanceBimestralIaff,
    SeccionDificultadesIaff,
    //SeccionContactosIaff,
    SeccionContactosProyectoCns,
    SeccionActualizacionEstado,
    DialogLoader,
    SnackAlert,
    DivisorColor
  },
  data() {
    return {
      validForm: false,
      ddRolesLoading: false,
      dialogRegistro: false,
      tableLoading: false,
      dialogInformacionIaff: false,
      dialogLoaderVisible: false,
      dialogSuspension: false,
      dialogLoaderText: "",
      switchItemEstado: true,
      proyectoSeleccionado: "",
      filtro: "",
      dialog: false,
      dialogTitle: "Registrar IAFF",
      accion: 1,
      items: [],
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      estadoProyecto: "",
      parameters: [],
      proyectoId: 0,
      periodoId: 0,
      codigoProyecto: "",
      iaffId: 0,
      tab: "tab-1",
      periodos: [],
      bimestres: [],
      actualizarBeneficiariosItems: [
        { id: 2, text: "No" },
        { id: 1, text: "Si" }
      ],
      actualizaBeneficiariosSel: 2,
      datosItem: {
        id: 0,
        proyectosCNSId: this.proyectoId,
        periodosId: 0,
        bimestreId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      estadosProyecto: [
        { text: "Todos", value: null },
        { value: 1, text: "Activo" },
        { value: 2, text: "Inactivo" }
      ],
      estadoProyectoId: 0,
      estadosProyectoActualizacion: [],
      programacionesAnuales: [],
      validarPoaLoading: false,
      existePoa: false,
      periodoValidado: false,
      datosProyecto: {},
      iaffActivarId: 0,
      anioActivarIaff: "",
      bimestreActivarIaff: "",
      dialogActivar: false,
      btnActivarLoading: false,
      textoAdvertenciaEstado: "",
      dialogAdvertenciaEstado: false,
      //datosProyecto: {},
      skeletonLoading: false,
      contraPartidaRegistrada: false,
      territorioRegistrado: false,
      dialogActualizarEstado: false,
      btnActualizarEstadoLoading: false,
      otrosRegistrado: false,
      datosIaff: {},
      estadoActualProyecto: "",
      observacionesRechazo: "",
      solicitudRechazada: 2,
      observaciones: "",
      nuevoEstadoId: 0,
      cambioBloqueado: false,
      datosFinancierosProyecto: [],
      validFormTipoBeneficiarios: false,
      tipoUsuario: "ext",
      seccionesBloqueadas: false,
      btnRegistroTipoBeneficiariosLoading: false,
      habilitarRegistroBeneficiarios: false,
      beneficiarios: [],
      tableBeneficiariosLoading: false,
      beneficiariosDirectosItems: [
        { id: 0, text: "Seleccione" },
        { id: 1, text: "Si" },
        { id: 2, text: "No" }
      ],
      beneficiarioDirectoSel: 0,
      validFormBeneficiarios: false,
      generos: [],
      generoSeleccionado: {},
      areasBeneficiadas: [],
      etnias: [],
      rangosEdad: [],
      datosBeneficiario: {
        proyectosCNSId: this.proyectoId,
        genero: "",
        areasBeneficiadasId: 0,
        etniasId: 0,
        rangosEdadId: 0,
        numeroBeneficiarios: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      },
      btnEliminarBeneficiarioDisabled: false,
      accionBeneficiarios: 1,
      cardDatosLoading: true,
      btnRegistroBeneficiarioLoading: false,
      archivoCargado: false,
      datosArchivo: {},
      ...validations
    };
  },

  methods: {
    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estadoProyecto.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estadoProyecto.text;
    },

    modalNuevo() {
      this.accion = 1;
      this.dialogTitle = "Registrar IAFF";
      this.btnRegistroText = "Registrar IAFF";
      this.switchItemEstado = true;
      this.dialogRegistro = true;
    },

    modalActualizarEstado() {
      this.obtenerEstados();
      this.dialogActualizarEstado = true;
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 20) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (20Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    async obtenerDatosProyecto(proyectoId) {
      this.skeletonLoading = true;
      this.datosItem = {};

      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: proyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.datosProyecto = res.data;
            this.estadoProyectoId = this.datosProyecto.estadoProyectoId;
            this.estadoActualProyecto = this.datosProyecto.estadoProyecto;
            this.observacionesRechazo = this.datosProyecto.observacionesSolicitudRechazada;
            this.solicitudRechazada = this.datosProyecto.solicitudRechazada;


            if (this.datosProyecto.estadoProyectoId != 2) {
              this.cambioBloqueado = true;
            } else {
              this.cambioBloqueado = false;
            }

            if (this.datosProyecto.detalleMontoPorDepartamento == 1) {
              this.territorioRegistrado = true;
            }

            this.habilitarRegistroBeneficiarios = false;
            
            if (this.datosProyecto.beneficiariosDirectos) {
              this.beneficiarioDirectoSel = this.datosProyecto.beneficiariosDirectos;
              if (this.beneficiarioDirectoSel == 1) {
                this.obtenerBeneficiarios(this.datosProyecto.id);
                this.habilitarRegistroBeneficiarios = true;
              }
            } else {
              this.beneficiarioDirectoSel = 0;
            }
            this.obtenerEstados();
            this.obtenerDatosFinancieros(proyectoId);
            //console.log(this.habilitarRegistroBeneficiarios)
            // console.log(this.datosItem)
            /*this.estadoProyectoId = this.datosItem.estadoProyectoId;
            this.estadoActualProyecto = this.datosItem.estadoProyecto;
            if (this.datosItem.estadoProyectoId != 2) {
              this.cambioBloqueado = true;
            } else {
              this.cambioBloqueado = false;
            }

            //this.obtenerEstados();*/
          }
          this.skeletonLoading = false;
        })
        .catch(e => {
          console.log(e)
          this.skeletonLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    async obtenerEstados() {
      this.estadosProyectoActualizacion = [
        {
          id: 3,
          estado: "Solicitud Cierre / Liquidación",
          disabled:
            this.datosProyecto.estadoProyectoId == 2 || this.datosProyecto.estadoProyectoId == 8 ||
                this.solicitudRechazada == 1 ? false : true
            //this.datosProyecto.totalPendienteEjecutarGeneral > 0 ? true : false
        }, //Solicitud de cierre / liquidación
        {
          id: 5,
          estado: "Solicitud de Finalizado",
          disabled:
          this.datosProyecto.estadoProyectoId == 2 || this.datosProyecto.estadoProyectoId == 4  ||
                this.solicitudRechazada == 1  ? false : true 
          //this.datosProyecto.totalPendienteEjecutarGeneral > 0 ? true : false
        }, // Solicitud de finalización
        {
          id: 7,
          estado: "Solicitud de Suspensión",
          disabled:
            this.datosProyecto.estadoProyectoId == 2  ||
                this.solicitudRechazada == 1 ? false : true 
        }, // Solicitud de suspendido
        {
          id: 9,
          estado: "Seguimiento",
          disabled:
            this.datosProyecto.estadoProyectoId == 8 ? false : true 
        } // Seguimiento
      ];
      //console.log(this.datosItem.totalPendienteEjecutarGeneral)
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
    },

    //Mostrar el dialogo para la suspension
    mostrarDialogSuspension(nombreProyecto) {
      this.proyectoSeleccionado = nombreProyecto;
      this.dialogSuspension = true;
    },

    resetForm() {
      this.datosItem = {
        id: 0,
        proyectosCNSId: this.proyectoId,
        periodosId: 0,
        bimestreId: 0,
        estadosId: 1,
        usuarioCreacion: "admin"
      };
      this.switchItemEstado = true;
      this.accion = 1;
      this.existePoa = false;
      this.periodoValidado = false;
    },

    //Periodos
    async obtenerPeriodos(proyectoId) {
      this.periodos = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: `ProgramacionAnualCNS/Proyecto/Periodos/${proyectoId}/${4}`
        })
        .then(res => {
          if (res.status === 200) {
            this.periodos = res.data;
          }
        })
        .catch(() => {
          this.periodos = [];
        });
    },

    //Bimestres
    async obtenerBimestres() {
      this.bimestres = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Bimestre/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.bimestres = res.data;
          }
        })
        .catch(() => {
          this.bimestres = [];
        });
    },

    /// Obtener los items
    async obtenerItems() {
      this.items = [];
      this.tableLoading = true;

      await this.$store
        .dispatch(OBTENER_IAFFS_CNS, {
          proyectoId: this.proyectoId,
          estadoId: 134
        })
        .then(() => {
          this.items = this.$store.state.iaffcns.iaffsCns;
          //console.log(this.items)
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
          //console.log(err)
        });
    },

    async validarPeriodoPoa() {
      this.programacionesAnuales = [];
      this.validarPoaLoading = true;
      this.periodoValidado = false;
      this.dialogLoaderText =
        "Validando Poa para el periodo seleccionado, espere por favor...";
      this.dialogLoaderVisible = true;
      await this.$store
        .dispatch(OBTENER_PLANIFICACIONES_ANUALES_PERIODO_CNS, {
          proyectoId: this.proyectoId,
          estadoId: 4,
          periodoId: this.datosItem.periodosId
        })
        .then(res => {
          if (res.status === 200) {
            this.programacionesAnuales = res.data;
            this.existePoa = true;
            //console.log(this.items)
            //console.log(this.items)
          } else {
            this.existePoa = false;
          }
          this.dialogLoaderVisible = false;
          this.periodoValidado = true;
          this.validarPoaLoading = false;
        })
        .catch(() => {
          this.dialogLoaderVisible = false;
          this.validarPoaLoading = false;
          this.existePoa = false;
          this.periodoValidado = true;
          //console.log(err)
        });
    },

    ///Registro de nuevo iaff (Encabezado)
    async registrarItem() {
      this.btnRegistroLoading = true;
      this.datosItem.proyectosCNSId = this.proyectoId;
      this.datosItem.usuarioCreacion = "adm";
      //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
      this.switchItemEstado
        ? (this.datosItem.estadosId = 1)
        : (this.datosItem.estadosId = 2);

      //?Si accion es 1 se realiza el registro
      if (this.accion === 1) {
        await this.$store
          .dispatch(REGISTRAR_IAFF_CNS, this.datosItem)
          .then(res => {
            this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.obtenerItems();
              this.dialogRegistro = false;
              this.resetForm();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
            this.btnRegistroLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
      }
      //?Si accion es 2 se realiza la actualizacion
      else {
        await this.$store
          .dispatch(ACTUALIZAR_INSTITUCION, {
            datosItem: this.datosItem,
            id: this.datosItem.id
          })
          .then(res => {
            this.btnLoading = false;
            this.dialogLoaderVisible = false;
            this.btnRegistroLoading = false;

            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow(
                "success",
                "Mensaje",
                res.message
              );
              this.dialogRegistro = false;
              this.obtenerItems();
              this.resetForm();
            } else {
              this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                res.message
              );
            }
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error durante el registro.`
            );
          });
      }
    },

    //Mostrar el dialog para actualizar la información de iaff
    mostrarDialogActualizar(iaffId, periodoId) {
      this.dialogLoaderText = "Obteniendo información...";
      this.dialogLoaderVisible = true;
      this.tab = "tab-1";
      this.iaffId = iaffId;
      this.periodoId = periodoId;

      if (this.$refs.SeccionAvanceBimestralIaff) {
        this.$refs.SeccionAvanceBimestralIaff.resetItems(2);

        this.$refs.SeccionAvanceBimestralIaff.obtenerDatosFinancieros(
          this.proyectoId
        );

        this.$refs.SeccionAvanceBimestralIaff.obtenerDesembolsosRecibidos(
          this.iaffId
        );
        this.$refs.SeccionAvanceBimestralIaff.obtenerDesembolsosAnterioresRecibidos(
          this.iaffId
        );
        this.$refs.SeccionAvanceBimestralIaff.obtenerMeses();

        this.$refs.SeccionAvanceBimestralIaff.obtenerSubDivisiones(
          this.proyectoId
        );

        this.$refs.SeccionAvanceBimestralIaff.obtenerSubDivisionesContraPartida(
          this.proyectoId
        );

        /*this.$refs.SeccionAvanceBimestralIaff.obtenerEjecucionesCooperante(
          this.iaffId
        );*/
        this.$refs.SeccionAvanceBimestralIaff.obtenerAcumnuladoEjecucionesCooperante(
          this.proyectoId,
          this.iaffId
        );
        this.$refs.SeccionAvanceBimestralIaff.obtenerAcumnuladoEjecucionesContrapartida(
          this.proyectoId,
          this.iaffId
        );

        this.$refs.SeccionAvanceBimestralIaff.obtenerEjecucionesContrapartida(
          this.iaffId
        );

        this.$refs.SeccionAvanceBimestralIaff.obtenerSubdivisionesPoaCP(
          this.proyectoId,
          this.periodoId
        );
        this.$refs.SeccionAvanceBimestralIaff.obtenerSubdivisionesPoaFC(
          this.proyectoId,
          this.periodoId
        );
        this.$refs.SeccionAvanceBimestralIaff.obtenerEjecucionesFisicasCooperante(
          this.iaffId
        );
        this.$refs.SeccionAvanceBimestralIaff.obtenerEjecucionesFisicasContrapartida(
          this.iaffId
        );

        /*this.$refs.SeccionAvanceBimestralIaff.obtenerEjecucionesPresupuestariasAnuales(
          this.iaffId
        );*/

        this.$refs.SeccionAvanceBimestralIaff.obtenerCoberturaProyecto(
          this.proyectoId
        );

        this.$refs.SeccionAvanceBimestralIaff.obtenerMontosSuscritosDepto(
          this.proyectoId
        );

        this.$refs.SeccionAvanceBimestralIaff.obtenerEjecucionesTerritoriales(
          this.iaffId
        );
        /*
        .then(()=> {
          this.$refs.SeccionAvanceBimestralIaff.obtenerTiposFuenteCooperante();
        
          this.$refs.SeccionAvanceBimestralIaff.obtenerFuentesBilaterales().then(() => {
            this.$refs.SeccionAvanceBimestralIaff.obtenerDatosItem(this.idProyecto);  
          });
        })*/
      }

      if (this.$refs.SeccionDificultadesIaff) {
        this.$refs.SeccionDificultadesIaff.resetItems(1);
        this.$refs.SeccionDificultadesIaff.obtenerInformacionIaff(this.iaffId);
      }

      if (this.$refs.SeccionContactosIaff) {
        this.$refs.SeccionContactosIaff.resetItems(1);
        this.$refs.SeccionContactosIaff.obtenerContactos(this.proyectoId);
      }

      if (this.$refs.SeccionActualizacionEstado) {
        this.$refs.SeccionActualizacionEstado.obtenerDatosProyecto(
          this.proyectoId
        );
        this.$refs.SeccionActualizacionEstado.obtenerEstados();
      }

      setTimeout(() => {
        this.dialogInformacionIaff = true;
      }, "2000");
    },

    ocultarDialogLoader(mensaje) {
      this.dialogLoaderVisible = false;
      if (mensaje != null) {
        this.$refs.snackalert.SnackbarShow("warning", "Alerta", mensaje);
      }
    },

    //!Obtener datos de un item especifico
    obtenerDatosItem(Id) {
      this.empresaId = Id;
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";
      this.dialogTitulo = `Actualizar ${this.itemName}`;
      this.btnRegistroText = `Actualizar ${this.itemName}`;
      //!Pre seleccionar el tab de datos de la empresa
      this.activeTab = "empresa";
      this.$store
        .dispatch(OBTENER_INSTITUCION, Id)
        .then(res => {
          //console.log(res);
          if (res.status === 200) {
            this.datosItem = this.$store.state.institucion.institucion;
            this.datosItem.estadosId === 1
              ? (this.switchItemEstado = true)
              : (this.switchItemEstado = false);
            this.accion = 2;
            this.dialogRegistro = true;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
          );
          this.dialogLoaderVisible = false;
        });
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path, tipo) {
      /* let newPath = path.replace(/\\/g, '/');
        //console.log(newPath);
        window.open("http://localhost:5236"+ newPath, "_blank"); */

      if (tipo == "sigeaci") DownloadFile.downloadSigeaci(path);

      if (tipo == "sicoopera") DownloadFile.download(path);
    },

    //Mostar el modal/dialog para activar el iaff
    mostrarModalActivar(iaffActivarId, iaffActivarAnio, iaffActivarBimestre) {
      this.iaffActivarId = iaffActivarId;
      this.anioActivarIaff = iaffActivarAnio;
      this.bimestreActivarIaff = iaffActivarBimestre;
      this.dialogActivar = true;
    },

    async activarIaff() {
      this.btnActivarLoading = true;
      await this.$store
        .dispatch(ACTIVAR_IAFF, this.iaffActivarId)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.dialogActivar = false;
            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnActivarLoading = false;
        })
        .catch(() => {
          this.btnActivarLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    actualizarEstadoActualIaff(id) {
      //console.log(id)
      if (this.$refs.SeccionActualizacionEstado) {
        this.$refs.SeccionActualizacionEstado.resetItems(id);
      }
    },

    mostrarDialogAdvertenciaEstado(texto) {
      this.textoAdvertenciaEstado = texto;
      this.dialogAdvertenciaEstado = true;
    },

    //Obtener los datos financieros
    //Obtener los datos financieros
    async obtenerDatosFinancieros(proyectoId) {
      // this.tableDatosFinancierosLoading=true;
      this.datosFinancierosProyecto = [];
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.datosFinancierosProyecto = res.data;
            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.fuenteCooperanteRegistrada = true;
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;
            searchObjectFC
              ? (this.datoFinancieroFCRegistrado = searchObjectFC.length)
              : (this.datoFinancieroFCRegistrado = 0);
            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.contraPartidaRegistrada = true;
            } else {
              this.contraPartidaRegistrada = false;
            }

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.otrosRegistrado = true;
              this.monedaOtros = searchObjectOtros.codigoMoneda;
            } else {
              this.otrosRegistrado = false;
            }

            searchObjectOtros
              ? (this.datoFinancieroOtrosRegistrado = searchObjectOtros.length)
              : (this.datoFinancieroOtrosRegistrado = 0);
          }
          //this.tableDatosFinancierosLoading=false;
        })
        .catch(() => {
          //console.logerror
          this.datosFinancierosProyecto = [];
          //this.tableDatosFinancierosLoading=false;
        });
    },

    resetFormBeneficiarios () {
      this.accionBeneficiarios = 1;
      this.generoSeleccionado = {};

      this.datosBeneficiario = {
        proyectosCNSId: this.proyectoId,
        genero: "",
        areasBeneficiadasId: 0,
        etniasId: 0,
        rangosEdadId: 0,
        numeroBeneficiarios: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      };
    },

    async registrarBeneficiario() {
      this.btnRegistroBeneficiarioLoading = true;
      this.datosBeneficiario.proyectosCNSId = this.proyectoId;

      this.datosBeneficiario.genero = this.generoSeleccionado.codigo;

      let dispatch = REGISTRAR_BENEFICIARIO;

      if (this.accionBeneficiarios === 2){
        dispatch = ACTUALIZAR_BENEFICIARIO;
      }

      await this.$store
        .dispatch(dispatch, { datos: this.datosBeneficiario })
        .then(res => {
          this.btnLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerBeneficiarios(this.proyectoId);
            this.resetFormBeneficiarios();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroBeneficiarioLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
      this.btnRegistroBeneficiarioLoading = false;
    },

    //Actualizar el tipo de beneficiarios con los que cuenta el proyecto
    async actualizarTipoBeneficiarios() {
      this.btnRegistroTipoBeneficiariosLoading = true;

      this.datosTipoBeneficiario.id = this.id;
      this.datosTipoBeneficiario.beneficiariosDirectos = this.beneficiarioDirectoSel;
      this.datosTipoBeneficiario.usuarioActualizacoin = "admin";

      await this.$store
        .dispatch(ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS, {
          datos: this.datosTipoBeneficiario
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.beneficiarioDirectoSel.id === 1
              ? (this.habilitarRegistroBeneficiarios = true)
              : (this.habilitarRegistroBeneficiarios = false);
            this.obtenerBeneficiarios(this.proyectoId);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroTipoBeneficiariosLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnRegistroTipoBeneficiariosLoading = false;
        });
    },

    //Obtener los beneficiarios
    async obtenerBeneficiarios(proyectoId) {
      this.skeletonLoading = true;
      this.beneficiarios = [];
      this.tableBeneficiariosLoading = true;
      this.totalMasculino = 0;
      this.totalFemenino = 0;
      this.totalOtros = 0;
      this.$store
        .dispatch(OBTENER_BENEFICIARIOS, {
          estadoId: 1,
          tipoProyecto: 1,
          proyectoId: proyectoId
        })
        .then(res => {
          if (res.status === 200) {
            this.beneficiarios = res.data;

            this.totalMasculino = this.beneficiarios
              .filter(({ genero }) => genero === "M")
              .reduce(
                (a, b) =>
                  parseFloat(a) + (parseFloat(b["numeroBeneficiarios"]) || 0),
                0
              );

            this.totalFemenino = this.beneficiarios
              .filter(({ genero }) => genero === "F")
              .reduce(
                (a, b) =>
                  parseFloat(a) + (parseFloat(b["numeroBeneficiarios"]) || 0),
                0
              );
            this.totalOtros = this.beneficiarios
              .filter(({ genero }) => genero === "O")
              .reduce(
                (a, b) =>
                  parseFloat(a) + (parseFloat(b["numeroBeneficiarios"]) || 0),
                0
              );

            this.totalBeneficiarios = this.beneficiarios.reduce(
              (a, b) =>
                parseFloat(a) + (parseFloat(b["numeroBeneficiarios"]) || 0),
              0
            );
          }
          this.tableBeneficiariosLoading = false;
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.tableBeneficiariosLoading = false;
          this.skeletonLoading = false;
        });
    },

    //Obtener los generos
    async obtenerGeneros() {
      this.generos = [
        { id: 0, genero: "Seleccione", codigo: "N/A" },
        { id: 1, genero: "Femenino", codigo: "F" },
        { id: 2, genero: "Masculino", codigo: "M" },
        { id: 3, genero: "Otros", codigo: "O" }
      ];

      /* this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Generos'})
                .then(res => {
                    if (res.status === 200) {
                        this.generos = res.data;
                        this.cardDatosLoading = false;
                    }
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                }); */
    },

    //Colocar los datos en los campos para editar
    obtenerDatosBeneficiario(datoSeleccionado) {
      this.accionBeneficiarios = 2;
      this.datosBeneficiario.id = datoSeleccionado.id;
      this.datosBeneficiario.departamentosId = datoSeleccionado.departamentosId;
      this.datosBeneficiario.areasBeneficiadasId =
        datoSeleccionado.areasBeneficiadasId;
      this.datosBeneficiario.etniasId = datoSeleccionado.etniasId;
      this.datosBeneficiario.rangosEdadId = datoSeleccionado.rangosEdadId;
      this.datosBeneficiario.numeroBeneficiarios = datoSeleccionado.numeroBeneficiarios.toString();
      this.generoSeleccionado = this.generos.find(
        dato => dato.codigo == datoSeleccionado.genero
      );
    },

    //Obtener areas beneficiadas
    async obtenerAreasBeneficiadas() {
      this.areasBeneficiadas = [];

      this.cardDatosLoading = true;

      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "AreasBeneficiadas/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.areasBeneficiadas = res.data;
            this.cardDatosLoading = false;
          }
        })
        .catch(() => {
          this.cardDatosLoading = false;
        });
    },

    //Obtener etnias
    async obtenerEtnias() {
      this.etnias = [];

      this.cardDatosLoading = true;

      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Etnias/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.etnias = res.data;
            this.cardDatosLoading = false;
          }
        })
        .catch(() => {
          this.cardDatosLoading = false;
        });
    },

    //Obtener rangos de edad
    async obtenerRangosEdad() {
      this.rangosEdad = [];

      this.cardDatosLoading = true;

      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "RangosEdad/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.rangosEdad = res.data;
            this.cardDatosLoading = false;
          }
        })
        .catch(() => {
          this.cardDatosLoading = false;
        });
    },

    //Cargar el archivo previo a actualizar el estado
    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnRegistroLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cns\\documentos\\cambioestado\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            this.datosArchivo.pathDocumentoRespaldo = res.pathArchivo;
            this.datosArchivo.path = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.registrarPath(res.pathArchivo);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnRegistroLoading = false;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnRegistroLoading = false;
        });
    },

    //Registrar el nuevo estado del proyecto
    async registrarPath(path) {
      //this.datosArchivo.documentosCNSId = this.tipoDocumentoSeleccionado.id;
      this.datosArchivo.proyectosCNSId = this.proyectoId;
      this.datosArchivo.observaciones = this.observaciones;

      let datosSolicitudEstado = {};

      datosSolicitudEstado.proyectosCNSId = this.proyectoId;
      datosSolicitudEstado.estadosProyectoId = this.nuevoEstadoId.id;
      datosSolicitudEstado.pathDocumentoRespaldo = path;
      datosSolicitudEstado.estadosId = 1;
      datosSolicitudEstado.usuarioCreacion = "ad";

      await this.$store
        .dispatch(REGISTRAR_SOLICITUD_CAMBIO_ESTADO_CNS, datosSolicitudEstado)
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.obtenerDocumentosCargados(this.id);
            //if(this.archivo && this.archivo .size){
            //console.log("aui")
            //document.querySelector('#respaldoFile').value = '';
            //this.$refs.respaldoFile.reset();
            //this.$refs.docFile.reset();
            this.nuevoEstadoId = {};
            this.observaciones = "";
            this.archivo = [];
            document.querySelector("#docFile").value = "";
            this.$refs.docFile.reset();
            this.obtenerDatosProyecto(this.proyectoId);
            //this.obtenerDatosIaff(this.iaffId);
            //this.resetForm();
            //this.$refs['respaldoFile'][0].reset();
            //}

            this.btnRegistroLoading = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroLoading = false;
        });

      this.btnRegistroLoading = false;
    },

  },
  created() {
    this.parameters = this.$route.params;
    this.proyectoId = parseInt(
      this.$CryptoJS.AES.decrypt(
        this.parameters.proyectoId,
        "KEYADMINCNS2022"
      ).toString(this.CryptoJS.enc.Utf8)
    );
    this.obtenerDatosProyecto(this.proyectoId);
    this.codigoProyecto = this.parameters.codigoProyecto;
    this.obtenerPeriodos(this.proyectoId);
    this.obtenerBimestres();
    this.obtenerItems();
    this.obtenerGeneros();
    this.obtenerAreasBeneficiadas();
    this.obtenerEtnias();
    this.obtenerRangosEdad();
    //this.obtenerInstituciones();
    //this.obtenerTiposIngreso();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos" },
      { title: "CNS", to: "/proyectos/cns/externo/registro-informacion" },
      { title: "IAFF" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }), //, currentRoles :"currentRoles"

    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    headers() {
      return [
        {
          text: "Procedencia",
          align: "start",
          sortable: false,
          value: "procedencia"
        },
        {
          text: "Año",
          align: "start",
          sortable: true,
          value: "nombreInstitucion"
        },
        {
          text: "Bimestre",
          align: "start",
          sortable: true,
          value: "correo"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },
    headersBeneficiarios() {
      return [
        {
          text: "Género beneficiado",
          align: "start",
          sortable: false,
          value: "nombreGenero"
        },
        {
          text: "Área",
          align: "start",
          sortable: true,
          value: "nombreArea"
        },
        {
          text: "Etnia",
          align: "start",
          sortable: true,
          value: "nombreEtnia"
        },
        {
          text: "Grupo etario",
          align: "start",
          sortable: true,
          value: "rango"
        },
        {
          text: "Beneficiados",
          align: "start",
          sortable: true,
          value: "numeroBeneficiados"
        },
        {
          text: "Acción",
          align: "start",
          sortable: true,
          value: ""
        }
      ];
    }
  }
};
</script>
