var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text"},[_vm._v(" Dificultades y Propuesta de solución ")]),_c('v-divider',{staticClass:"mt-0 pt-0"})],1)],1),_c('v-form',{ref:"formDificultades",on:{"submit":function($event){$event.preventDefault();return _vm.registrarDificultades.apply(null, arguments)}},model:{value:(_vm.validFormDificultades),callback:function ($$v) {_vm.validFormDificultades=$$v},expression:"validFormDificultades"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6"},[_vm._v(" Desembolsos ")])],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Dificultades de desembolsos","rules":[
                  _vm.required('dificultades de desembolsos'),
                  _vm.minLength('dificultades de desembolsos', 5),
                  _vm.maxLength('dificultades de desembolsos', 2000)
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.dificultadesDesembolsos),callback:function ($$v) {_vm.$set(_vm.datosIaff, "dificultadesDesembolsos", $$v)},expression:"datosIaff.dificultadesDesembolsos"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Acciones propuestas para superar la dificultad","rules":[
                  _vm.required('acciones propuestas para superar la dificultad'),
                  _vm.minLength(
                    'acciones propuestas para superar la dificultad',
                    5
                  ),
                  _vm.maxLength(
                    'acciones propuestas para superar la dificultad',
                    2000
                  )
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.accionesDesembolsos),callback:function ($$v) {_vm.$set(_vm.datosIaff, "accionesDesembolsos", $$v)},expression:"datosIaff.accionesDesembolsos"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6"},[_vm._v(" Ejecución financiera ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Dificultades de Ejecución Financiera","rules":[
                  _vm.required('Dificultades de Ejecución Financiera'),
                  _vm.minLength('Dificultades de Ejecución Financiera', 5),
                  _vm.maxLength('Dificultades de Ejecución Financiera', 2000)
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.dificultadesFinancieras),callback:function ($$v) {_vm.$set(_vm.datosIaff, "dificultadesFinancieras", $$v)},expression:"datosIaff.dificultadesFinancieras"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Acciones propuestas para superar la dificultad","rules":[
                  _vm.required('Acciones propuestas para superar la dificultad'),
                  _vm.minLength(
                    'Acciones propuestas para superar la dificultad',
                    5
                  ),
                  _vm.maxLength(
                    'Acciones propuestas para superar la dificultad',
                    2000
                  )
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.accionesFinancieras),callback:function ($$v) {_vm.$set(_vm.datosIaff, "accionesFinancieras", $$v)},expression:"datosIaff.accionesFinancieras"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1),(_vm.mostrarAccionesPresupuestarias)?_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6"},[_vm._v(" Ejecución Presupuestaria ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Dificultades de Ejecución Presupuestaria","rules":[
                  _vm.required('dificultades de Ejecución Presupuestaria'),
                  _vm.minLength('dificultades de Ejecución Presupuestaria', 5),
                  _vm.maxLength('dificultades de Ejecución Presupuestaria', 2000)
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.dificultadesPresupuestarias),callback:function ($$v) {_vm.$set(_vm.datosIaff, "dificultadesPresupuestarias", $$v)},expression:"datosIaff.dificultadesPresupuestarias"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Acciones propuestas para superar la dificultad","rules":[
                  _vm.required('Acciones propuestas para superar la dificultad'),
                  _vm.minLength(
                    'Acciones propuestas para superar la dificultad',
                    5
                  ),
                  _vm.maxLength(
                    'Acciones propuestas para superar la dificultad',
                    2000
                  )
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.accionesPresupuestarias),callback:function ($$v) {_vm.$set(_vm.datosIaff, "accionesPresupuestarias", $$v)},expression:"datosIaff.accionesPresupuestarias"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6"},[_vm._v(" Ejecución Física ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Dificultades de Ejecución Física","rules":[
                  _vm.required('dificultades de Ejecución Física'),
                  _vm.minLength('dificultades de Ejecución Física', 5),
                  _vm.maxLength('dificultades de Ejecución Física', 2000)
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.dificultadesFisicas),callback:function ($$v) {_vm.$set(_vm.datosIaff, "dificultadesFisicas", $$v)},expression:"datosIaff.dificultadesFisicas"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Acciones propuestas para superar la dificultad","rules":[
                  _vm.required('Acciones propuestas para superar la dificultad'),
                  _vm.minLength(
                    'Acciones propuestas para superar la dificultad',
                    5
                  ),
                  _vm.maxLength(
                    'Acciones propuestas para superar la dificultad',
                    2000
                  )
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.accionesFisicas),callback:function ($$v) {_vm.$set(_vm.datosIaff, "accionesFisicas", $$v)},expression:"datosIaff.accionesFisicas"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6"},[_vm._v(" Otros ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Observaciones generales","rules":[
                  _vm.required('observaciones generales'),
                  _vm.minLength('observaciones generales', 5),
                  _vm.maxLength('observaciones generales', 2000)
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.observacionesGenerales),callback:function ($$v) {_vm.$set(_vm.datosIaff, "observacionesGenerales", $$v)},expression:"datosIaff.observacionesGenerales"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.seccionesBloqueadas,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Describa acciones adicionales","rules":[
                  _vm.required('acciones adicionales'),
                  _vm.minLength('acciones adicionales', 5),
                  _vm.maxLength('acciones adicionales', 2000)
                ],"maxlength":"2000","rows":"3"},model:{value:(_vm.datosIaff.avanceRequisitos),callback:function ($$v) {_vm.$set(_vm.datosIaff, "avanceRequisitos", $$v)},expression:"datosIaff.avanceRequisitos"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"mb-2 float-right mt-2",attrs:{"color":"primary","type":"submit","elevation":0,"disabled":!_vm.validFormDificultades || _vm.seccionesBloqueadas,"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar información ")])],1)],1)],1)],1)],1)],1):_vm._e(),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }