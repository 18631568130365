<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <!-- inicio:: seccion detalle de los desembolsos recibidos -->
      <v-card outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Dificultades y Propuesta de solución
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-form
            ref="formDificultades"
            v-on:submit.prevent="registrarDificultades"
            v-model="validFormDificultades"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
                <v-subheader class="text-h6">
                  Desembolsos
                </v-subheader>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.dificultadesDesembolsos"
                  label="Dificultades de desembolsos"
                  :rules="[
                    required('dificultades de desembolsos'),
                    minLength('dificultades de desembolsos', 5),
                    maxLength('dificultades de desembolsos', 2000)
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.accionesDesembolsos"
                  label="Acciones propuestas para superar la dificultad"
                  :rules="[
                    required('acciones propuestas para superar la dificultad'),
                    minLength(
                      'acciones propuestas para superar la dificultad',
                      5
                    ),
                    maxLength(
                      'acciones propuestas para superar la dificultad',
                      2000
                    )
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
                <v-subheader class="text-h6">
                  Ejecución financiera
                </v-subheader>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.dificultadesFinancieras"
                  label="Dificultades de Ejecución Financiera"
                  :rules="[
                    required('Dificultades de Ejecución Financiera'),
                    minLength('Dificultades de Ejecución Financiera', 5),
                    maxLength('Dificultades de Ejecución Financiera', 2000)
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.accionesFinancieras"
                  label="Acciones propuestas para superar la dificultad"
                  :rules="[
                    required('Acciones propuestas para superar la dificultad'),
                    minLength(
                      'Acciones propuestas para superar la dificultad',
                      5
                    ),
                    maxLength(
                      'Acciones propuestas para superar la dificultad',
                      2000
                    )
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row v-if="mostrarAccionesPresupuestarias">
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
                <v-subheader class="text-h6">
                  Ejecución Presupuestaria
                </v-subheader>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.dificultadesPresupuestarias"
                  label="Dificultades de Ejecución Presupuestaria"
                  :rules="[
                    required('dificultades de Ejecución Presupuestaria'),
                    minLength('dificultades de Ejecución Presupuestaria', 5),
                    maxLength('dificultades de Ejecución Presupuestaria', 2000)
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.accionesPresupuestarias"
                  label="Acciones propuestas para superar la dificultad"
                  :rules="[
                    required('Acciones propuestas para superar la dificultad'),
                    minLength(
                      'Acciones propuestas para superar la dificultad',
                      5
                    ),
                    maxLength(
                      'Acciones propuestas para superar la dificultad',
                      2000
                    )
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
                <v-subheader class="text-h6">
                  Ejecución Física
                </v-subheader>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.dificultadesFisicas"
                  label="Dificultades de Ejecución Física"
                  :rules="[
                    required('dificultades de Ejecución Física'),
                    minLength('dificultades de Ejecución Física', 5),
                    maxLength('dificultades de Ejecución Física', 2000)
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.accionesFisicas"
                  label="Acciones propuestas para superar la dificultad"
                  :rules="[
                    required('Acciones propuestas para superar la dificultad'),
                    minLength(
                      'Acciones propuestas para superar la dificultad',
                      5
                    ),
                    maxLength(
                      'Acciones propuestas para superar la dificultad',
                      2000
                    )
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-1">
                <v-subheader class="text-h6">
                  Otros
                </v-subheader>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.observacionesGenerales"
                  label="Observaciones generales"
                  :rules="[
                    required('observaciones generales'),
                    minLength('observaciones generales', 5),
                    maxLength('observaciones generales', 2000)
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  dense
                  :disabled="seccionesBloqueadas"
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosIaff.avanceRequisitos"
                  label="Describa acciones adicionales"
                  :rules="[
                    required('acciones adicionales'),
                    minLength('acciones adicionales', 5),
                    maxLength('acciones adicionales', 2000)
                  ]"
                  maxlength="2000"
                  rows="3"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-btn
                  color="primary"
                  class="mb-2 float-right mt-2"
                  type="submit"
                  :elevation="0"
                  :disabled="!validFormDificultades || seccionesBloqueadas"
                  :loading="btnRegistroLoading"
                >
                  Guardar información
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>

<script>
//import Vue from 'vue';
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";

import {
  OBTENER_IAFF_CNS,
  REGISTRAR_DIFICULTADES_IAFF_CNS
} from "@/core/services/store/proyectoscns/iaff/iaffcns.module";

export default {
  name: "SeccionDificultadesIaff",
  components: {
    SnackAlert,
    DialogLoader
  },
  props: ["iaffId", "proyectoId"],
  data() {
    return {
      skeletonLoading: false,
      validFormDificultades: false,
      btnRegistroLoading: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      datosIaff: {
        id: 0,
        dificultadesDesembolsos: "",
        accionesDesembolsos: "",
        dificultadesFinancieras: "",
        accionesFinancieras: "",
        dificultadesPresupuestarias: "",
        accionesPresupuestarias: "",
        dificultadesFisicas: "",
        accionesFisicas: "",
        observacionesGenerales: "",
        avanceRequisitos: ""
      },
      mostrarAccionesPresupuestarias: false,
      seccionesBloqueadas: false,
      ...validations
    };
  },

  methods: {
    resetItems(tipo) {
      if (tipo === 1) {
        this.skeletonLoading = true;
      }

      this.datosIaff = {
        id: 0,
        dificultadesDesembolsos: "",
        accionesDesembolsos: "",
        dificultadesFinancieras: "",
        accionesFinancieras: "",
        dificultadesPresupuestarias: "",
        accionesPresupuestarias: "",
        dificultadesFisicas: "",
        accionesFisicas: "",
        observacionesGenerales: "",
        avanceRequisitos: ""
      };
    },

    //Obtener los datos del iaff
    async obtenerInformacionIaff(iaffId) {
      this.datosIaff = {};

      await this.$store
        .dispatch(OBTENER_IAFF_CNS, iaffId)
        .then(res => {
          if (res.status === 200) {
            this.datosIaff = res.data;

            if (
              this.datosIaff.estadosId == 2 ||
              //this.datosIaff.estadosId == 3 ||
              this.datosIaff.estadosId == 4
            )
              this.seccionesBloqueadas = true;

            if (!this.datosIaff.dificultadesDesembolsos)
              this.datosIaff.dificultadesDesembolsos = "";

            if (!this.datosIaff.accionesDesembolsos)
              this.datosIaff.accionesDesembolsos = "";

            if (!this.datosIaff.dificultadesFinancieras)
              this.datosIaff.dificultadesFinancieras = "";

            if (!this.datosIaff.accionesFinancieras)
              this.datosIaff.accionesFinancieras = "";

            //Validar que la cooperación sea financiera 1 para mostrar dificultades y acciones presupuestarias
            if (this.datosIaff.tiposCooperacionId === 1) {
              this.mostrarAccionesPresupuestarias = true;
              if (!this.datosIaff.dificultadesPresupuestarias)
                this.datosIaff.dificultadesPresupuestarias = "";

              if (!this.datosIaff.accionesPresupuestarias)
                this.datosIaff.accionesPresupuestarias = "";
            } else {
              this.mostrarAccionesPresupuestarias = false;
              this.datosIaff.dificultadesPresupuestarias = "";
              this.datosIaff.accionesPresupuestarias = "";
            }
            if (!this.datosIaff.dificultadesFisicas)
              this.datosIaff.dificultadesFisicas = "";

            if (!this.datosIaff.accionesFisicas)
              this.datosIaff.accionesFisicas = "";

            if (!this.datosIaff.observacionesGenerales)
              this.datosIaff.observacionesGenerales = "";

            if (!this.datosIaff.avanceRequisitos)
              this.datosIaff.avanceRequisitos = "";
          }
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.datosIaff = {};
        });
    },

    //Registrar dififultades para iaff
    async registrarDificultades() {
      this.btnRegistroLoading = true;
      //this.datosDesembolso.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(REGISTRAR_DIFICULTADES_IAFF_CNS, {
          id: this.iaffId,
          datos: this.datosIaff
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.obtenerDesembolsosRecibidos(this.iaffId);
            //this.dialogRegistro = false;
            //this.resetItems(2);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(() => {
          this.btnRegistroLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    }
  },

  created() {
    this.resetItems(1);
    this.obtenerInformacionIaff(this.iaffId);
  }
};
</script>
