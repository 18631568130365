<template>
  <div>
    <div v-if="skeletonLoading">
      <v-row>
        <v-col cols="12" md="12">
          <v-skeleton-loader
            min-height="600px"
            type="article, article, article, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="!skeletonLoading">
      <!-- inicio:: seccion  generación y confirmación del iaff -->
      <v-card elevation-1 class="mt-6">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Confirmación IAFF
              </v-subheader>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-divider class="mt-0 pt-3 pb-2"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <!--  <v-col cols="12" md="12" sm="12" xs="12">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <span class="gray--text mt-3 font-weight-bold text-h6">
                                    Existe información pendiente de completar para poder validar y/o confirmar el Informe de Avance Físico y Financiero
                                </span>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-divider class="mt-0 pt-1 pb-0"></v-divider>
                            </v-col>


                            <v-col cols="12" md="12" xs="12" class="pb-0 pt-0">
                                <v-list-item>
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-subtitle-1 font-weight-bold"><v-icon medium color="orange darken-2">mdi-alert-outline </v-icon> Desembolsos Recibidos</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-subtitle-1 font-weight-bold"><v-icon medium color="orange darken-2">mdi-alert-outline </v-icon> Desembolsos Recibidos</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-col> -->

            <v-col cols="12" md="12" sm="12" xs="12">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span class="gray--text mt-3 font-weight-bold text-h6">
                    Información del registro del Informe de Avance Físico y
                    Financiero, previo a confirmación
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-divider class="mt-0 pt-1 pb-0"></v-divider>
                </v-col>

                <v-col cols="12" md="4" sm="6" xs="12" v-if="datosIaff.cuentaConDesembolsosRecibidos == 1">
                  <v-card flat outlined>
                    <v-card-title
                      class="text-subtitle-1"
                      :color="
                        desembolsosRecibidosFc.length > 0
                          ? `#26c6da`
                          : `orange darken-2`
                      "
                    >
                      Desembolsos Recibidos
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-icon
                            x-large
                            :color="
                              desembolsosRecibidosFc.length > 0
                                ? `#26c6da`
                                : `orange darken-2`
                            "
                            >{{
                              desembolsosRecibidosFc.length > 0
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" sm="6" xs="12">
                  <v-card flat outlined>
                    <v-card-title
                      class="text-subtitle-1"
                      :color="
                        ejecucionesCooperante.length > 0
                          ? `#26c6da`
                          : `orange darken-2`
                      "
                    >
                      Ejecución de la Fuente Cooperante
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-icon
                            x-large
                            :color="
                              ejecucionesCooperante.length > 0
                                ? `#26c6da`
                                : `orange darken-2`
                            "
                            >{{
                              ejecucionesCooperante.length > 0
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  v-if="contraPartidaRegistrada || otrosRegistrado"
                >
                  <v-card flat outlined>
                    <v-card-title
                      class="text-subtitle-1"
                      :color="
                        ejecucionesContrapartida.length > 0
                          ? `#26c6da`
                          : `orange darken-2`
                      "
                    >
                      Ejecución de la Fuente Nacional o Contrapartida
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-icon
                            x-large
                            :color="
                              ejecucionesContrapartida.length > 0
                                ? `#26c6da`
                                : `orange darken-2`
                            "
                            >{{
                              ejecucionesContrapartida.length > 0
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" sm="6" xs="12">
                  <v-card flat outlined>
                    <v-card-title
                      class="text-subtitle-1"
                      :color="
                        ejecucionesFisicasCooperante.length > 0
                          ? `#26c6da`
                          : `orange darken-2`
                      "
                    >
                      Ejecución física de la Fuente Cooperante
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-icon
                            x-large
                            :color="
                              ejecucionesFisicasCooperante.length > 0
                                ? `#26c6da`
                                : `orange darken-2`
                            "
                            >{{
                              ejecucionesFisicasCooperante.length > 0
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  v-if="contraPartidaRegistrada || otrosRegistrado"
                >
                  <v-card flat outlined>
                    <v-card-title
                      class="text-subtitle-1"
                      :color="
                        ejecucionesFisicasContrapartida.length > 0
                          ? `#26c6da`
                          : `orange darken-2`
                      "
                    >
                      Ejecución física de la Contrapartida
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-icon
                            x-large
                            :color="
                              ejecucionesFisicasContrapartida.length > 0
                                ? `#26c6da`
                                : `orange darken-2`
                            "
                            >{{
                              ejecucionesFisicasContrapartida.length > 0
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  v-if="montosDepartamento.length > 0"
                >
                  <v-card flat outlined>
                    <v-card-title
                      class="text-subtitle-1"
                      :color="
                        ejecucionesTerritoriales.length > 0
                          ? `#26c6da`
                          : `orange darken-2`
                      "
                    >
                      Ejecución territorial
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-icon
                            x-large
                            :color="
                              ejecucionesTerritoriales.length > 0
                                ? `#26c6da`
                                : `orange darken-2`
                            "
                            >{{
                              ejecucionesTerritoriales.length > 0
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4" sm="6" xs="12">
                  <v-card flat outlined>
                    <v-card-title
                      class="text-subtitle-1"
                      :color="dificultadesIaff ? `#26c6da` : `orange darken-2`"
                    >
                      Dificultades y propuestas de solución
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-icon
                            x-large
                            :color="
                              dificultadesIaff ? `#26c6da` : `orange darken-2`
                            "
                            >{{
                              dificultadesIaff
                                ? "mdi-check-all"
                                : "mdi-alert-circle-outline"
                            }}
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!--<v-col cols="12" md="12" xs="12" class="pb-0 pt-0">
                                 <v-list-item v-if="desembolsosRecibidosFc.length <= 0">
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-subtitle-1  font-weight-bold"><v-icon v-if="desembolsosRecibidosFc.length <= 0 " large color="orange darken-2">mdi-alert-outline</v-icon> Desembolsos Recibidos</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="ejecucionesCooperante.length <= 0 ">
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-subtitle-1 font-weight-bold"><v-icon v-if="ejecucionesCooperante.length <= 0 " large color="orange darken-2">mdi-alert-outline</v-icon> Detalle de ejecución de la Fuente Cooperante</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="ejecucionesContrapartida.length <= 0">
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-hsubtitle-1 font-weight-bold"><v-icon v-if="ejecucionesContrapartida.length <= 0 " large color="orange darken-2">mdi-alert-outline</v-icon> Detalle de ejecución de la Fuente Nacional o Contrapartida</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item> -->

                <!-- <v-list-item v-if="ejecucionesFisicasCooperante.length <= 0">
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-subtitle-1 font-weight-bold"><v-icon v-if="ejecucionesFisicasCooperante.length <= 0 " large color="orange darken-2">mdi-alert-outline</v-icon> Detalle de ejecución física de la Fuente Cooperante</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item> -->

                <!-- <v-list-item v-if="ejecucionesFisicasContrapartida.length <= 0">
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-subtitle-1 font-weight-bold"><v-icon v-if="ejecucionesFisicasContrapartida.length <= 0 " large color="orange darken-2">mdi-alert-outline</v-icon> Detalle de ejecución física de la Contrapartida</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item> -->

                <!-- <v-list-item v-if="ejecucionesTerritoriales.length <= 0">
                                    <v-list-item-content class="pb-0 pt-0">
                                        <v-list-item-title class="text-subtitle-1 font-weight-bold"><v-icon v-if="ejecucionesTerritoriales.length <= 0 " large color="orange darken-2">mdi-alert-outline</v-icon> Ejecución territorial</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item> 

                            </v-col>-->
              </v-row>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="text-center">
              <v-row
                v-if="datosIaff.estadosId === 3 || datosIaff.estadosId === 1"
              >
                <v-col cols="12">
                  <v-btn
                    :disabled="
                      btnValidarIaff ||
                        datosIaff.estadosId === 4 ||
                        (datosIaff.cuentaConDesembolsosRecibidos == 1 && desembolsosRecibidosFc.length <= 0) ||
                        ejecucionesCooperante.length <= 0 ||
                        ((contraPartidaRegistrada || otrosRegistrado) && ejecucionesContrapartida.length <= 0) ||
                        ejecucionesFisicasCooperante.length <= 0 ||
                        ((contraPartidaRegistrada || otrosRegistrado) && ejecucionesFisicasContrapartida.length <= 0) ||
                        (montosDepartamento.length > 0 &&
                          ejecucionesTerritoriales.length <= 0)
                    "
                    :loading="btnValidarIaff"
                    color="light-blue-502"
                    class="mb-2 white--text"
                    depressed
                    large
                    @click="mostrarDialogValidacion()"
                  >
                    <v-icon left>mdi-file-pdf-box</v-icon> Generar IAFF
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  class="text-center"
                  v-if="datosIaff.estadosId === 3"
                >
                  <v-icon large color="#26c6da">
                    mdi-check-all
                  </v-icon>

                  <p class="text-h6">
                    Informe de Avance Físico y Financiero Generado
                  </p>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="text-center"
                  v-if="datosIaff.estadosId === 1"
                >
                  <v-icon large color="orange darken-2">
                    mdi-alert-outline
                  </v-icon>

                  <p class="text-h6">
                    Es necesario generar el IAFF del presente periodo
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6" sm="6" xs="12" class="text-center">
              <v-row v-if="datosIaff.estadosId === 3">
                <v-col cols="12">
                  <v-btn
                    :disabled="
                      datosIaff.estadosId == 4 ||
                        datosIaff.estadosId == 1 ||
                        datosIaff.estadosId == 2 ||
                        btnValidarIaff
                    "
                    :loading="btnValidarIaff"
                    color="light-blue-502"
                    class="mb-2 white--text"
                    depressed
                    large
                    @click="mostrarDialogConfirmacion()"
                  >
                    <v-icon left>mdi-check-all</v-icon> Confirmar IAFF
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  class="text-center"
                  v-if="datosIaff.estadosId === 4"
                >
                  <v-icon large color="#26c6da">
                    mdi-check-all
                  </v-icon>

                  <p class="text-h6">
                    Informe de Avance Físico y Financiero Confirmado
                  </p>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="text-center"
                  v-if="datosIaff.estadosId === 3 || datosIaff.estadosId === 1"
                >
                  <v-icon large color="orange darken-2">
                    mdi-alert-outline
                  </v-icon>

                  <p class="text-h6">
                    Es necesario confirmar el Informe de Avance Físico y
                    Financiero
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="datosIaff.estadosId === 3 || datosIaff.estadosId === 4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Descarga de documentos generados
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>

            <v-col cols="12" md="12">
              <v-list dense>
                <v-list-item
                  v-if="datosIaff.estadosId === 3 || datosIaff.estadosId === 4"
                >
                  <v-list-item-icon>
                    <v-btn
                      color="blue-grey darken-2"
                      text
                      @click="descargarArchivo(pathDocumentoIaff)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Informe de Avance Físico y Financiero
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item v-if="datosIaff.estadosId === 4">
                  <v-list-item-icon>
                    <v-btn
                      color="blue-grey darken-2"
                      text
                      @click="descargarArchivo(pathDocumentoResumenIaff)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Resumen Ejecutivo IAFF
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-6" outlined v-if="1<1">
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-h6 black--text">
                Solicitud de cambio de estado del proyecto
                <span class="text-h6 red--text text--darken-1"> </span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-subtitle-1 black--text">
                Fuente Cooperante
                <span class="text-subtitle-1 red--text text--darken-1"> </span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Suscrito</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosItem.totalSuscritoFuenteCoo).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Ejecutado</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosItem.totalEjecutadoFuenteCoo).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Pendiente de ejecutar</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ parseFloat(datosItem.totalPendienteEjecutarFuenteCoo).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}
                    <v-icon small :color="datosItem.totalPendienteEjecutarFuenteCoo != 0 ? `orange darken-2` : `#26c6da`">{{datosItem.totalPendienteEjecutarFuenteCoo != 0 ? `mdi-alert-outline` : `mdi-check-all`}}</v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row v-if="contraPartidaRegistrada || otrosRegistrado">
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
              <v-subheader class="text-subtitle-1 black--text">
                Contrapartida y Otros Aportes
                <span class="text-subtitle-1 red--text text--darken-1"> </span>
              </v-subheader>

              <v-divider class="mt-0 pt-0"></v-divider>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Suscrito</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosItem.totalSuscripcionContrapartida).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Monto Ejecutado</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">{{ parseFloat(datosItem.totalEjecutadoContrapartida).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item>
                <v-list-item-content class="pt-0 pb-0">
                  <v-list-item-title>Pendiente de ejecutar</v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ parseFloat(datosItem.totalPendienteEjecutarContrapartida).toLocaleString("es-GT", {style: "currency", currency: "GTQ"}) }}
                    <v-icon small :color="datosItem.totalPendienteEjecutarContrapartida != 0 ? `orange darken-2` : `#26c6da`">{{datosItem.totalPendienteEjecutarContrapartida != 0 ? `mdi-alert-outline` : `mdi-check-all`}}</v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col
              cols="12"
              md="12"
              sm="12"
              v-if="datosItem.totalPendienteEjecutarGeneral != 0"
            >
              <v-alert outlined color="orange darken-2" dense>
                <v-icon color="orange darken-2">mdi-alert-outline</v-icon>
                Existen montos pendientes de ejecutar para poder realizar la
                solicitud de cambio de estado
              </v-alert>
            </v-col>

            <v-col
              cols="12"
              md="12"
              sm="12"
              class="mt-2"
              v-if="datosIaff.estadosId != 4"
            >
              <v-alert outlined color="orange darken-2" dense>
                <v-icon color="orange darken-2">mdi-alert-outline</v-icon>
                Es necesario confirmar el presente IAFF para poder solicitar el
                cambio de estado del proyecto
              </v-alert>
            </v-col>
          </v-row>

          <v-row justify="center" class="mt-6">
            <v-col cols="12" md="6" sm="12" xs="12">
              <v-card color="#039BE5" dark>
                <v-card-title class="text-h6 white--text">
                  Estado actual del proyecto {{ datosItem.codigo }}
                </v-card-title>
                <v-card-subtitle class="text-subtitle-1">{{ estadoActualProyecto }}</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>

          <v-form
            ref="form"
            v-on:submit.prevent="cargarArchivo"
            v-model="validForm"
            class="mt-4"
          >
            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  v-model="nuevoEstadoId"
                  :items="estadosProyecto"
                  :loading="false"
                  :disabled="
                    datosItem.totalPendienteEjecutarGeneral != 0 ||
                      datosIaff.estadosId != 4
                  "
                  dense
                  filled
                  return-object
                  label="Seleccione el estado que solicita para el Proyecto"
                  item-text="estado"
                  item-value="id"
                  menu-props="auto"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <template>
                  <v-file-input
                    id="docFile"
                    dense
                    filled
                    :disabled="
                      datosItem.totalPendienteEjecutarGeneral != 0 ||
                        datosIaff.estadosId != 4
                    "
                    placeholder="Seleccionar archivo .pdf"
                    label="Seleccione el documento de respaldo para el cambio de estado"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[fileRequired('documento')]"
                    :show-size="1000"
                    ref="docFile"
                    class="required"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                            text-overline
                                            grey--text
                                            text--darken-3
                                            mx-2
                                        "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-textarea
                  autocomplete="off"
                  class="required"
                  dense
                  :disabled="
                    datosItem.totalPendienteEjecutarGeneral != 0 ||
                      datosIaff.estadosId != 4
                  "
                  filled
                  rows="3"
                  v-model="observaciones"
                  label="Observaciones"
                  maxlength="2000"
                  :rules="[
                    minLength('observaciones', 3),
                    maxLength('observaciones', 2000)
                  ]"
                  hint=""
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="12" class="pt-6 pb-0"> </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :elevation="0"
                  :disabled="
                    !validForm ||
                      datosItem.totalPendienteEjecutarGeneral != 0 ||
                        datosIaff.estadosId != 4
                  "
                  :loading="btnRegistroLoading"
                >
                  Enviar solicitud
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-alert
                  outlined
                  type="warning"
                  color="deep-orange lighten-1"
                  dense
                >
                  El peso de los archivos no debe de superar los 20Mb
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!--begin:: dialog generar iaff -->
    <v-dialog
      v-model="dialogGenerarIaff"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Generar documento IAFF

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnGenerar"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogGenerarIaff = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de generar el documento del Informe de Avance
                Físico y Financiero con la información registrada?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogGenerarIaff = false"
            :disabled="btnGenerar"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="validarIaff()"
            :loading="btnGenerar"
          >
            Sí, generar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: dialog confirmar información -->
    <v-dialog
      v-model="dialogConfirmacion"
      width="500"
      transition="scroll-y-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmación de la información

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnConfirmacion"
            persistent
            transition="dialog-bottom-transition"
            @click="dialogConfirmacion = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                ¿Está seguro de confirmar la información del Informe de Avance
                Físico y Financiero?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogConfirmacion = false"
            :disabled="btnConfirmacion"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="confirmarIaff()"
            :loading="btnConfirmacion"
          >
            Sí, confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>
<script>
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import {
  //CONFIRMAR_PROYECTO_CNS,
  OBTENER_DATOS_SECCIONES_PROYECTO
} from "@/core/services/store/proyectoscns/proyectocns.module";

import { REGISTRAR_SOLICITUD_CAMBIO_ESTADO_CNS } from "@/core/services/store/proyectoscns/solicitudescambioestado/solicitudcambioestado.module";
import { OBTENER_DATOS_FINANCIEROS_CNS } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";
import {
  VALIDAR_IAFF,
  CONFIRMAR_IAFF,
  OBTENER_DESEMBOLSOS_RECIBIDOS_FC,
  OBTENER_EJECUCIONES_FC,
  OBTENER_EJECUCIONES_CP,
  OBTENER_EJECUCIONES_FISICAS_FC,
  OBTENER_EJECUCIONES_FISICAS_CP,
  OBTENER_EJECUCIONES_TERRITORIALES
  /*   REGISTRAR_DESEMBOLSOS_RECIBIDOS_FC,
  ELIMINAR_DESEMBOLSO_RECIBIDO_FC,
  REGISTRAR_EJECUCION_FC,
  
  ELIMINAR_EJECUCION_FC,
  OBTENER_ACUMULADO_EJECUCIONES_FUENTE_COOPERANTE,
  REGISTRAR_EJECUCION_CP,
  
  ELIMINAR_EJECUCION_CP,
  OBTENER_ACUMULADO_EJECUCIONES_CONTRAPARTIDA,
  ,
  ,
  ELIMINAR_EJECUCION_FISICA_FC,
  OBTENER_EJECUCIONES_FISICAS_CP,
  REGISTRAR_EJECUCION_FISICA_CP,
  ELIMINAR_EJECUCION_FISICA_CP,
  REGISTRAR_EJECUCION_TERRITORIAL,
  ,
  ELIMINAR_EJECUCION_TERRITORIAL,
  REGISTRAR_EVIDENCIA_IAFF,
  OBTENER_EVIDENCIAS_IAFF,
  ELIMINAR_EVIDENCIA_IAFF */
} from "@/core/services/store/proyectoscns/iaff/iaffcns.module";
import { OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS } from "@/core/services/store/proyectoscns/datosfinancieros/datofinancierocns.module";
export default {
  name: "SeccionActualizacionEstado",
  components: {
    SnackAlert,
    DialogLoader
  },
  props: ["iaffId", "proyectoId", "tipoUsuario"],
  data() {
    return {
      validForm: false,
      nuevoEstadoId: 0,
      datosIaff: {},
      totalAcumuladoDesembolsos: 0,
      totalAcumuladoTerritorial: 0,
      desembolsosRecibidosFc: [],
      ejecucionesCooperante: [],
      ejecucionesContrapartida: [],
      ejecucionesFisicasCooperante: [],
      ejecucionesFisicasContrapartida: [],
      montosDepartamento: [],
      skeletonLoading: false,
      estadosProyecto: [],
      datosItem: {},
      cambioBloqueado: false,
      estadoProyectoId: 0,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      datosArchivo: {},
      observaciones: "",
      archivo: [],
      btnValidarIaff: false,
      dialogGenerarIaff: false,
      btnGenerar: false,
      dificultadesIaff: false,
      btnRegistroLoading: false,
      btnConfirmacion: false,
      dialogConfirmacion: false,
      totalMontoSuscritoDepartamento: 0,
      estadoActualProyecto: "",
      datosFinancierosProyecto: [],
      fuenteCooperanteRegistrada: false,
      contraPartidaRegistrada: false,
      otrosRegistrado: false,
      ...validations
    };
  },

  methods: {
    resetItems(iaffId) {
      this.iaffId = iaffId;
      //console.log(iaffId)
      this.obtenerDatosIaff(this.iaffId);
      this.obtenerDesembolsosRecibidos(this.iaffId);
      this.obtenerEjecucionesCooperante(this.iaffId);
      this.obtenerEjecucionesContrapartida(this.iaffId);
      this.obtenerEjecucionesFisicasCooperante(this.iaffId);
      this.obtenerEjecucionesFisicasContrapartida(this.iaffId);
      this.obtenerDatosProyecto(this.proyectoId);
      this.obtenerDatosFinancieros(this.proyectoId);
      this.obtenerMontosSuscritosDepto(this.proyectoId);
      this.obtenerEjecucionesTerritoriales(this.iaffId);
      //this.obtenerEstados();
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 20) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (20Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    //Obtener los datos principales del Iaff
    async obtenerDatosIaff(iaffId) {
      this.datosIaff = {};

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `IAFFCNS/${iaffId}` })
        .then(res => {
          if (res.status === 200) {
            this.datosIaff = res.data;
            this.datosIaff.dificultadesDesembolsos
              ? (this.dificultadesIaff = true)
              : (this.dificultadesIaff = false);
            this.totalAcumuladoDesembolsos = this.datosIaff.acumuladoDesembolsos;
            this.totalAcumuladoEjecucionFC = this.datosIaff.acumuladoEjecucionFuenteCooperante;
            this.totalAcumuladoEjecucionCP = this.datosIaff.acumuladoEjecucionContrapartida;
            this.totalAcumuladoFisicoFC = this.datosIaff.acumuladoFisicoFC;
            this.totalAcumuladoFisicoCP = this.datosIaff.acumuladoFisicoCP;
            this.totalAcumuladoTerritorial = this.datosIaff.acumuladoTerritorial;
            this.pathDocumentoIaff = this.datosIaff.pathDocumento;
            this.pathDocumentoResumenIaff = this.datosIaff.pathResumenEjecutivo;

            this.$emit("ocultar-dialog-loader", null);
            //this.totalSubDivisionCooperante = this.subDivisiones.reduce((a,b) => a + (b['monto'] || 0), 0);
          } else {
            this.$emit(
              "ocultar-dialog-loader",
              "Ocurrió un error al intentar obtener la información del IAFF, por favor, póngase en contacto con un administrador del sistema."
            );
          }
        })
        .catch(() => {
          this.$emit(
            "ocultar-dialog-loader",
            "Ocurrió un error al intentar obtener la información del IAFF, por favor, póngase en contacto con un administrador del sistema."
          );
          this.datosIaff = {};
        });
    },

    async obtenerDatosProyecto(idProyecto) {
      this.skeletonLoading = true;
      this.datosItem = {};

      await this.$store
        .dispatch(OBTENER_DATOS_SECCIONES_PROYECTO, {
          Seccion: "DatosGenerales",
          id: idProyecto
        })
        .then(res => {
          if (res.status === 200) {
            this.datosItem = res.data;
            // console.log(this.datosItem)
            this.estadoProyectoId = this.datosItem.estadoProyectoId;
            this.estadoActualProyecto = this.datosItem.estadoProyecto;
            if (this.datosItem.estadoProyectoId != 2) {
              this.cambioBloqueado = true;
            } else {
              this.cambioBloqueado = false;
            }

            this.obtenerEstados();
          }
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
          //this.datosItem = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    //Obtener los datos financieros
    async obtenerDatosFinancieros(proyectoId) {
      // this.tableDatosFinancierosLoading=true;
      this.datosFinancierosProyecto = [];
      await this.$store
        .dispatch(OBTENER_DATOS_FINANCIEROS_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.datosFinancierosProyecto = res.data;
            //Validar si hay fuente cooperante registrada
            let searchObjectFC = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 1
            );
            if (searchObjectFC) {
              this.fuenteCooperanteRegistrada = true;
              this.monedaFuenteCooperante = searchObjectFC.codigoMoneda;
            }

            //console.log(searchObject);
            //this.datoFinancieroFCRegistrado = searchObjectFC.length;
            searchObjectFC
              ? (this.datoFinancieroFCRegistrado = searchObjectFC.length)
              : (this.datoFinancieroFCRegistrado = 0);
            //this.datoFinancieroFCRegistrado != 0 ? this.datoFinancieroFCRegistrado = true : this.datoFinancieroFCRegistrado = false;

            //Validar si hay contrapartida registrada
            let searchObjectCP = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 2
            );
            if (searchObjectCP) {
              this.monedaContraPartida = searchObjectCP.codigoMoneda;
              this.contraPartidaRegistrada = true;
            } else {
              this.contraPartidaRegistrada = false;
            }

            searchObjectCP
              ? (this.datoFinancieroCPRegistrado = searchObjectCP.length)
              : (this.datoFinancieroCPRegistrado = 0);

            //Validar si hay otros registrado
            let searchObjectOtros = this.datosFinancierosProyecto.find(
              dato => dato.tiposDatosFinancierosId == 3
            );

            if (searchObjectOtros) {
              this.otrosRegistrado = true;
              this.monedaOtros = searchObjectOtros.codigoMoneda;
            } else {
              this.otrosRegistrado = false;
            }

            searchObjectOtros
              ? (this.datoFinancieroOtrosRegistrado = searchObjectOtros.length)
              : (this.datoFinancieroOtrosRegistrado = 0);
          }
          //this.tableDatosFinancierosLoading=false;
        })
        .catch(() => {
          //console.logerror
          this.datosFinancierosProyecto = [];
          //this.tableDatosFinancierosLoading=false;
        });
    },

    //Obtener los desembolsos recibidos por FC
    async obtenerDesembolsosRecibidos(iaffId) {
      this.desembolsosRecibidosFc = [];
      //this.tableLoadingDesembolsosRecibidosFc = true;

      await this.$store
        .dispatch(OBTENER_DESEMBOLSOS_RECIBIDOS_FC, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(res => {
          this.desembolsosRecibidosFc = res.data;
          this.totalDesembolsosRecibidos = 0.0;
          if (this.desembolsosRecibidosFc.length > 0) {
            //Sumar desembolsos recibidos
            this.totalDesembolsosRecibidos = this.desembolsosRecibidosFc.reduce(
              (a, b) => a + (b["totalQuetzales"] || 0),
              0
            );
          }
          //  this.tableLoadingDesembolsosRecibidosFc = false;
          //  this.skeletonLoading = false;
        })
        .catch(() => {
          this.desembolsosRecibidosFc = [];
          // this.skeletonLoading = false;
          //console.log(err)
        });
    },

    //Obtener las ejecuciones de la Fuente Cooperamte
    async obtenerEjecucionesCooperante(iaffId) {
      this.ejecucionesCooperante = [];
      //this.tableLoadingEjecucionesFc = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_FC, { iaffId: iaffId, estadoId: 1 })
        .then(() => {
          this.ejecucionesCooperante = this.$store.state.iaffcns.ejecucionesCooperante;

          this.totalEjecucionFC = 0.0;
          if (this.ejecucionesCooperante.length > 0) {
            //Sumar desembolsos recibidos
            this.totalEjecucionFC = this.ejecucionesCooperante.reduce(
              (a, b) => a + (b["montoEjecutado"] || 0),
              0
            );
          }
          //this.tableLoadingEjecucionesFc = false;
        })
        .catch(() => {
          this.ejecucionesCooperante = [];
          //console.log(err)
        });
    },

    //Obtener las ejecuciones de la Contrapartida
    async obtenerEjecucionesContrapartida(iaffId) {
      this.ejecucionesContrapartida = [];
      //this.tableLoadingEjecucionesCp = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_CP, { iaffId: iaffId, estadoId: 1 })
        .then(() => {
          this.ejecucionesContrapartida = this.$store.state.iaffcns.ejecucionesContrapartida;
          this.totalEjecucionCP = 0.0;
          if (this.ejecucionesContrapartida.length > 0) {
            //Sumar desembolsos recibidos
            this.totalEjecucionCP = this.ejecucionesContrapartida.reduce(
              (a, b) => a + (b["montoEjecutado"] || 0),
              0
            );
          }
          //this.tableLoadingEjecucionesCp = false;
        })
        .catch(() => {
          this.ejecucionesContrapartida = [];
          //console.log(err)
        });
    },

    //Obtener las ejecuciones físicas de la fuente cooperante
    async obtenerEjecucionesFisicasCooperante(iaffId) {
      this.ejecucionesFisicasCooperante = [];
      //this.tableLoadingEjecucionesFisicasFC = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_FISICAS_FC, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(() => {
          this.ejecucionesFisicasCooperante = this.$store.state.iaffcns.ejecucionesFisicasCooperante;
          this.promedioPorcentajeEjecucionFC = 0.0;
          this.totalAcumuladoFisicoFC = 0.0;

          if (this.ejecucionesFisicasCooperante.length > 0) {
            //Sumar desembolsos recibidos
            this.promedioPorcentajeEjecucionFC =
              this.ejecucionesFisicasCooperante.reduce(
                (a, b) => a + (b["avance"] || 0),
                0
              ) / this.ejecucionesFisicasCooperante.length;
            this.totalAcumuladoFisicoFC =
              this.ejecucionesFisicasCooperante.reduce(
                (a, b) => a + (b["avanceAcumulado"] || 0),
                0
              ) / this.ejecucionesFisicasCooperante.length;
          }
          //this.tableLoadingEjecucionesFisicasFC = false;
        })
        .catch(() => {
          this.ejecucionesFisicasCooperante = [];
          //console.log(err)
        });
    },

    //Obtener las ejecuciones físicas de la contrapartida (Aporte nacional)
    async obtenerEjecucionesFisicasContrapartida(iaffId) {
      this.ejecucionesFisicasContrapartida = [];
      //this.tableLoadingEjecucionesFisicasCP = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_FISICAS_CP, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.ejecucionesFisicasContrapartida = this.$store.state.iaffcns.ejecucionesFisicasContrapartida;
            this.totalAcumuladoFisicoCP = 0.0;
            this.promedioPorcentajeEjecucionCP = 0.0;
            if (this.ejecucionesFisicasContrapartida.length > 0) {
              //Sumar desembolsos recibidos
              this.promedioPorcentajeEjecucionCP =
                this.ejecucionesFisicasContrapartida.reduce(
                  (a, b) => a + (b["avance"] || 0),
                  0
                ) / this.ejecucionesFisicasContrapartida.length;
              this.totalAcumuladoFisicoCP =
                this.ejecucionesFisicasContrapartida.reduce(
                  (a, b) => a + (b["avanceAcumulado"] || 0),
                  0
                ) / this.ejecucionesFisicasContrapartida.length;
            }
          }
          //this.tableLoadingEjecucionesFisicasCP = false;
        })
        .catch(() => {
          this.ejecucionesFisicasContrapartida = [];
          //console.log(err)
        });
    },

    //Obtener el lsitado de proyeccion de desempbolosos registrados
    async obtenerMontosSuscritosDepto(proyectoId) {
      this.montosDepartamento = [];
      //this.ddMontosDepartamentoLoading = true;
      this.totalMontoSuscritoDepartamento = "0.00";

      await this.$store
        .dispatch(OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS, { id: proyectoId })
        .then(res => {
          if (res.status === 200) {
            this.montosDepartamento = res.data;
            this.totalMontoSuscritoDepartamento = this.montosDepartamento.reduce(
              (a, b) => parseFloat(a) + (parseFloat(b["monto"]) || 0),
              0
            );
          }
          //this.ddMontosDepartamentoLoading = false;
        })
        .catch(() => {
          this.montosDepartamento = [];
          //this.ddMontosDepartamentoLoading = false;
        });
    },

    /*
     * Obtener las ejecuciones territoriales registradas del iaff
     */
    async obtenerEjecucionesTerritoriales(iaffId) {
      this.ejecucionesTerritoriales = [];
      //this.tableLoadingEjecucionesTerritoriales = true;

      await this.$store
        .dispatch(OBTENER_EJECUCIONES_TERRITORIALES, {
          iaffId: iaffId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.ejecucionesTerritoriales = res.data;
          }

          /*  if(this.ejecucionesFisicasContrapartida.length > 0){
                    //Sumar desembolsos recibidos
                    this.avanceTotalEjecucionContrapartida = this.ejecucionesFisicasContrapartida.reduce((a,b) => a + ((b['avance']) || 0), 0);
                }*/
          //  this.tableLoadingEjecucionesTerritoriales = false;
        })
        .catch(() => {
          this.ejecucionesTerritoriales = [];
          //this.tableLoadingEjecucionesTerritoriales = false;
          //console.log(err)
        });
    },

    async obtenerEstados() {
      this.estadosProyecto = [
        {
          id: 3,
          estado: "Cierre / Liquidación",
          disabled:
            this.datosItem.totalPendienteEjecutarGeneral > 0 ? true : false
        }, //Solicitud de cierre / liquidación
        {
          id: 5,
          estado: "Finalizado",
          disabled: this.datosItem.totalPendienteEjecutarGeneral > 0 ? true : false
        }, // Solicitud de finalización
        {
          id: 7,
          estado: "Suspensión",
          disabled: false
        } // Solicitud de suspendido
      ];
      //console.log(this.datosItem.totalPendienteEjecutarGeneral)
    },

    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnRegistroLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cns\\documentos\\cambioestado\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            this.datosArchivo.pathDocumentoRespaldo = res.pathArchivo;
            this.datosArchivo.path = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.registrarPath(res.pathArchivo);
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnRegistroLoading = false;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnRegistroLoading = false;
        });
    },

    //Registrar el nuevo estado del proyecto
    async registrarPath(path) {
      //this.datosArchivo.documentosCNSId = this.tipoDocumentoSeleccionado.id;
      this.datosArchivo.proyectosCNSId = this.id;
      this.datosArchivo.observaciones = this.observaciones;

      let datosSolicitudEstado = {};

      datosSolicitudEstado.proyectosCNSId = this.datosItem.id;
      datosSolicitudEstado.estadosProyectoId = this.nuevoEstadoId.id;
      datosSolicitudEstado.pathDocumentoRespaldo = path;
      datosSolicitudEstado.estadosId = 1;
      datosSolicitudEstado.usuarioCreacion = "ad";

      await this.$store
        .dispatch(REGISTRAR_SOLICITUD_CAMBIO_ESTADO_CNS, datosSolicitudEstado)
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //this.obtenerDocumentosCargados(this.id);
            //if(this.archivo && this.archivo .size){
            //console.log("aui")
            //document.querySelector('#respaldoFile').value = '';
            //this.$refs.respaldoFile.reset();
            //this.$refs.docFile.reset();
            this.nuevoEstadoId = {};
            this.observaciones = "";
            this.archivo = [];
            document.querySelector("#docFile").value = "";
            this.$refs.docFile.reset();
            this.obtenerDatosProyecto(this.proyectoId);
            this.obtenerDatosIaff(this.iaffId);
            //this.resetForm();
            //this.$refs['respaldoFile'][0].reset();
            //}

            this.btnRegistroLoading = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroLoading = false;
        });

      this.btnRegistroLoading = false;
    },

    //Mostrar el dialogo para validar/generar el iaff
    mostrarDialogValidacion() {
      this.dialogGenerarIaff = true;
    },

    //Validar el iaff y generar el pdf correspondiente
    async validarIaff() {
      this.btnValidarIaff = true;
      this.btnGenerar = true;
      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      this.$store
        .dispatch(VALIDAR_IAFF, this.datosIaff.id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerDatosIaff(this.iaffId);
            this.descargarArchivo(res.data);
            this.obtenerDatosProyecto(this.proyectoId);
            this.pathDocumentoIaff = res.data;
            this.dialogGenerarIaff = false;
            this.$emit("obtener-iaff");
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnValidarIaff = false;
          this.btnGenerar = false;
        })
        .catch(() => {
          this.btnValidarIaff = false;
          this.btnGenerar = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro, por favor, póngase en contacto con un administrador del sistema.`
          );
        });
    },

    mostrarDialogConfirmacion() {
      this.dialogConfirmacion = true;
    },

    //Actualizar el estado del IAFF a confirmado
    async confirmarIaff() {
      this.btnConfirmacion = true;

      //this.datosEjecucionContrapartida.proyectosCNSId = this.proyectoId;

      await this.$store
        .dispatch(CONFIRMAR_IAFF, this.datosIaff.id)
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.pathDocumentoResumenIaff = res.data;
            this.obtenerDatosIaff(this.iaffId);
            this.dialogConfirmacion = false;
            this.descargarArchivo(res.data);
            this.obtenerDatosProyecto(this.proyectoId);
            this.$emit("obtener-iaff");
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnConfirmacion = false;
        })
        .catch(() => {
          this.btnConfirmacion = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      DownloadFile.download(path);
    }
  },

  created() {
    this.resetItems(this.iaffId);
  }
};
</script>
